// role access control methods for different features across webapp

import { Role } from "@prisma/client"

export enum Functionalities {
  CREATE_PROJECT,
  EDIT_PROJECT,
  DUPLICATE_PROJECT,
  ARCHIVE_PROJECT,
  EXPORT_PROJECT,
  GO_BACK_OLD_UI
}

const MinimunAcessRoleForFunctionality = new Map<Functionalities, Role>()

MinimunAcessRoleForFunctionality.set(Functionalities.CREATE_PROJECT, Role.ADMIN)
MinimunAcessRoleForFunctionality.set(Functionalities.EDIT_PROJECT, Role.ADMIN)
MinimunAcessRoleForFunctionality.set(Functionalities.DUPLICATE_PROJECT, Role.ADMIN)
MinimunAcessRoleForFunctionality.set(Functionalities.ARCHIVE_PROJECT, Role.ADMIN)
MinimunAcessRoleForFunctionality.set(Functionalities.EXPORT_PROJECT, Role.ADMIN)
MinimunAcessRoleForFunctionality.set(Functionalities.GO_BACK_OLD_UI, Role.USER)

export const requestAccessForUser = (
  functionallityToAcess: Functionalities,
  userRole?: Role
) => {

  userRole = userRole ?? 'USER' //Minimun role is USER

  const minimunRoleToAccessFunctionality = MinimunAcessRoleForFunctionality.get(functionallityToAcess)

  if (!minimunRoleToAccessFunctionality) {
    return false
  }

  return weightRole(userRole) >= weightRole(minimunRoleToAccessFunctionality)
}

const weightRole = (roleToWeight: Role): number => {
  const roleWeights = {
    [Role.USER]: 0,
    [Role.ADMIN]: 1,
    [Role.SUPER_ADMIN]: 2
  }

  return roleWeights[roleToWeight] ?? -1
}



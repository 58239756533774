import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd';
import {
  CustomRecordType,
  FilterType,
  FilterTypeEnum,
  chartKeyBlacklist,
} from '../experiment-list-layout-v2';
import capitalize from 'lodash/capitalize';
import { useFormulations } from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import { VariableType } from '@prisma/client';
const { Text } = Typography;

const formattedValue = (value: any) => {
  const num = Number(value);
  return !isNaN(num) ? Math.round(num).toString() : String(value);
};

export const Filter = ({
  tableColumns,
  onRemove,
  onChange,
  filter,
}: {
  onChange: (changedFilter: FilterType) => void;
  filter: FilterType;
  tableColumns: CustomRecordType[];
  onRemove: () => void;
}) => {
  const items = tableColumns
    .filter(tc => !chartKeyBlacklist.includes(tc.key))
    .map(tc => {
      if (tc.columnType === 'outcome') {
        return [
          { value: tc.key, label: capitalize(tc.key) },
          {
            value: `${tc.key} importance`,
            label: `${capitalize(tc.key)} priority`,
          },
        ];
      }
      return { value: tc.key, label: capitalize(tc.key) };
    })
    .flat();

  const [campaignNames, setCampaignNames] = useState<
    { label: string; value: string }[]
  >();

  const { projectFormulations } = useFormulations();
  const { currentProject } = useSession();

  useEffect(() => {
    const newCampaignNames = projectFormulations.reduce<
      { label: string; value: string }[]
    >((acc, current) => {
      if (current.campaign && current.campaign.name) {
        const campaignName = current.campaign.name;
        if (!acc.some(option => option.value === campaignName)) {
          acc.push({ label: campaignName, value: campaignName });
        }
      }
      return acc;
    }, []);
    setCampaignNames(newCampaignNames);
  }, [projectFormulations]);

  return (
    <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Select
          style={{ width: '100%' }}
          allowClear
          placeholder="Select an option..."
          options={items}
          value={filter.selectedItem}
          onChange={value =>
            onChange({ ...filter, selectedItem: value as FilterTypeEnum })
          }
          status={filter.error ? 'error' : ''}
        />
        {filter.selectedItem !== 'source' &&
          filter.selectedItem !== 'initiative' &&
          !filter.selectedItem?.endsWith('importance') && (
            <Select
              defaultValue="between"
              style={{ width: '100%' }}
              onChange={value =>
                onChange({ ...filter, filterType: value as FilterTypeEnum })
              }
              value={filter.filterType}
              options={[
                { value: FilterTypeEnum.LESS_THAN, label: 'Less Than' },
                { value: FilterTypeEnum.GREATER_THAN, label: 'Greater Than' },
                { value: FilterTypeEnum.BETWEEN, label: 'Between' },
                { value: FilterTypeEnum.EQUAL_TO, label: 'Equal To' },
              ]}
            />
          )}
        {(filter.selectedItem === 'source' ||
          filter.selectedItem === 'initiative' ||
          filter.selectedItem?.endsWith('importance')) && (
            <Select
              style={{ width: '100%' }}
              onChange={value =>
                onChange({ ...filter, filterType: value as FilterTypeEnum })
              }
              value={
                filter.filterType === 'equalTo' ? filter.filterType : undefined
              }
              options={[{ value: FilterTypeEnum.EQUAL_TO, label: 'Equal To' }]}
            />
          )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {[FilterTypeEnum.GREATER_THAN, FilterTypeEnum.LESS_THAN].includes(
            filter.filterType
          ) && (
              <InputNumber
                style={{ width: '100%' }}
                value={filter.inputValue}
                onChange={value =>
                  onChange({ ...filter, inputValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
            )}
          {filter.filterType === FilterTypeEnum.EQUAL_TO &&
            filter.selectedItem !== 'source' &&
            filter.selectedItem !== 'initiative' &&
            !filter.selectedItem?.endsWith('importance') &&
            currentProject?.ingredientList.find((i) => i.ingredient.name.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.ORDINAL &&
            currentProject?.ingredientList.find((i) => i.ingredient.name.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.CATEGORICAL &&
            currentProject?.activeModel?.outcomes.find((outcome) => outcome.targetVariable.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.ORDINAL &&
            currentProject?.activeModel?.outcomes.find((outcome) => outcome.targetVariable.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.CATEGORICAL &&
            (
              <Input
                style={{ width: '100%' }}
                value={filter.inputValue}
                onChange={e =>
                  onChange({ ...filter, inputValue: e.target.value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
            )}
          {filter.filterType === FilterTypeEnum.EQUAL_TO &&
            (filter.selectedItem === 'source' ||
              filter.selectedItem === 'initiative' ||
              filter.selectedItem?.endsWith('importance') ||
              ((currentProject?.ingredientList.find((i) => i.ingredient.name.toLowerCase() === filter.selectedItem?.toLowerCase())?.type &&
                currentProject?.ingredientList.find((i) => i.ingredient.name.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.NUMERIC) ||
                (currentProject?.activeModel?.outcomes.find((outcome) => outcome.targetVariable.toLowerCase() === filter.selectedItem?.toLowerCase())?.type &&
                  currentProject?.activeModel?.outcomes.find((outcome) => outcome.targetVariable.toLowerCase() === filter.selectedItem?.toLowerCase())?.type !== VariableType.NUMERIC)))
            && (
              <Select
                mode={filter.selectedItem?.endsWith('importance') ? undefined : "multiple"}
                style={{ width: '100%' }}
                value={filter.inputValue === 0 ? undefined : filter.inputValue}
                onChange={value =>
                  onChange({ ...filter, inputValue: value ?? undefined })
                }
                status={filter.error ? 'error' : ''}
                options={
                  filter.selectedItem === 'source'
                    ? [
                      { value: 'PREFERRED', label: 'PREFERRED' },
                      { value: 'SUGGESTED', label: 'SUGGESTED' },
                      { value: 'TESTED', label: 'TESTED' },
                      { value: 'EXISTING', label: 'EXISTING' },
                    ]
                    : filter.selectedItem === 'initiative'
                      ? campaignNames
                      : filter.selectedItem?.endsWith('importance')
                        ? [
                          { value: '3', label: 'High Priority' },
                          { value: '2', label: 'Medium Priority' },
                          { value: '1', label: 'Low Priority' },
                          { value: '0', label: 'None Priority' },
                        ]
                        : Array.from(
                          new Set(
                            projectFormulations
                              .map((f) =>
                                f.items.find((i) =>
                                  i.variable.name.toLowerCase() === filter.selectedItem?.toLowerCase()
                                )?.value
                              )
                              .filter((value) => value !== undefined)
                              .map(formattedValue)
                          )
                        ).map((value) => ({ value, label: value }))
                }
              />
            )}
          {filter.filterType === FilterTypeEnum.BETWEEN && (
            <div style={{ display: 'flex', gap: 5 }}>
              <InputNumber
                style={{ width: 100 }}
                value={filter.startValue}
                onChange={value =>
                  onChange({ ...filter, startValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
              <span style={{ marginTop: 5 }}>and</span>
              <InputNumber
                style={{ width: 100 }}
                value={filter.endValue}
                onChange={value =>
                  onChange({ ...filter, endValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
            </div>
          )}
          <Button
            type="text"
            icon={<DeleteOutlined style={{ color: '#c8c8c8' }} />}
            onClick={onRemove}
          />
        </div>
      </div>

      <Text type="danger">{filter.error}</Text>
    </div>
  );
};
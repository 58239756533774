import { useMutation, useQuery } from 'react-query';
import { IngredientGroupRepository } from '../repositories/ingredient-group.repository';
import {
  IngredientGroupData,
  IngredientGroupDataIn,
} from '@root/components/project/ingredients-group/ingredients-group.interfaces';

export const useGetIngredientsGroup = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getIngredientGroup`, data.organizationId, data.projectId],
    IngredientGroupRepository.getIngredientsGroups,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useCreateIngredientgroup = () => {
  const mutation = useMutation(
    (data: {
      projectId: string;
      organizationId: string;
      ingredientGroup: IngredientGroupDataIn;
    }) => {
      return IngredientGroupRepository.postIngredientGroup(data);
    }
  );
  return mutation;
};

export const deleteIngredientGroup = () => {
  const mutation = useMutation((data: any) => {
    return IngredientGroupRepository.deleteIngredientGroup(data);
  });
  return mutation;
};

export const useGetIngredientGroupByOrganization = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getIngredientGroupByOrganization`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
    ],
    IngredientGroupRepository.getIngredientGroupByOrganization,
    {
      enabled: !!data.organizationId,
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

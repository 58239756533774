/** @jsxImportSource @emotion/react */

import { HistoryOutlined, SendOutlined } from '@ant-design/icons';
import {
  Collapse,
  Flex,
  Input,
  Spin,
  Tag,
  Typography,
  Modal,
  Button,
} from 'antd';
import { useState } from 'react';
import '../../support/contactTuringSupport.less';
import { useSendLunaCreateProjectMessage } from '../../../network/services/luna-chat.service';
import { useSession } from '../../../_shared/context';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LoadingOutlined } from '@ant-design/icons';
import { v4 as uuid4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const defaultMessage = `
Hi! Welcome to Luna. To start creating your project, please tell us your product category and goal.
`;

interface CreatProjectCopilotProps {
  open: boolean;
  onClose: () => void;
}

export const CreateProjectCopilot = ({
  open,
  onClose,
}: CreatProjectCopilotProps) => {
  const { user } = useSession();
  const navigate = useNavigate();

  const [requestId, setRequestId] = useState<string>(uuid4());
  const [inputValue, setInputValue] = useState('');
  const [messageHistory, setMessageHistory] = useState<
    Array<{ message: string; timestamp: Date }>
  >([]);
  const sendMessage = useSendLunaCreateProjectMessage();

  const [response, setResponse] = useState(defaultMessage);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSendMessage = async (manualInputOverride?: string) => {
    const messageToSend = manualInputOverride ?? inputValue.trim();

    if (messageToSend === '' || !user) {
      return;
    }

    // Add message to history
    setMessageHistory(prev => [
      ...prev,
      { message: messageToSend, timestamp: new Date() },
    ]);

    setHasError(false);
    setLoading(true);
    setResponse('');

    sendMessage.mutate(
      {
        organizationId: user?.organizationId,
        message: messageToSend,
        userId: user.id,
        requestId,
      },
      {
        onSuccess: async (response?: any) => {
          if (!response) {
            setHasError(true);
          } else {
            const responseMessage = response.data.message;

            if (
              responseMessage.match(/Project created successfully with ID: \d+/)
            ) {
              const projectId = responseMessage.split(':')[1].trim();
              navigate(`/project/new/${projectId}`);
              onClose();
            }

            setResponse(response.data.message);
            setInputValue('');
          }
          setLoading(false);
        },
        onError: () => {
          setHasError(true);
          setLoading(false);
        },
      }
    );
  };

  const handleCreateProject = async () => {
    setLoading(true);
    await handleSendMessage('Looks good, now create the project');
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width="90%"
      title={
        <Typography.Title
          style={{ display: 'flex', alignItems: 'center', gap: 6, margin: 0 }}
          level={4}
        >
          Create a project with Luna <Tag>Beta</Tag>
        </Typography.Title>
      }
    >
      <Flex
        style={{
          width: '100%',
          height: '70vh',
        }}
      >
        {/* Left Column */}
        <Flex
          vertical
          gap={15}
          style={{
            width: '50%',
            borderRight: '1px solid #f0f0f0',
            padding: '0 20px',
            overflowY: 'auto',
          }}
        >
          <div className="chat-input-container" key={'chat-container'}>
            <Input
              placeholder="Tell us about your project"
              suffix={<SendOutlined onClick={() => handleSendMessage()} />}
              value={inputValue}
              onChange={event => setInputValue(event.currentTarget.value)}
              onPressEnter={() => handleSendMessage()}
              className="chat-input"
              disabled={loading}
            />
          </div>
          {loading && (
            <Typography.Text type="secondary">
              Luna is thinking...
            </Typography.Text>
          )}

          <Collapse
            bordered={false}
            items={[
              {
                key: 'message',
                label: 'This feature is experimental.',
              },
            ]}
          />
          {messageHistory.length > 0 && (
            <Button disabled={loading} onClick={() => handleCreateProject()}>
              Create Project
            </Button>
          )}

          {messageHistory.length > 0 && (
            <Flex vertical gap={8} style={{ marginTop: 16 }}>
              <Typography.Text strong>
                <HistoryOutlined /> Previous Messages
              </Typography.Text>
              {messageHistory.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    padding: '8px 12px',
                    background: '#f5f5f5',
                    borderRadius: '6px',
                    fontSize: '14px',
                  }}
                >
                  <Typography.Text style={{ color: '#666' }}>
                    {msg.message}
                  </Typography.Text>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#999',
                      marginTop: '4px',
                    }}
                  >
                    {msg.timestamp.toLocaleTimeString()}
                  </div>
                </div>
              ))}
            </Flex>
          )}
        </Flex>

        {/* Right Column */}
        <Flex
          vertical
          style={{
            width: '50%',
            padding: '0 20px',
            overflowY: 'auto',
          }}
        >
          <Spin
            indicator={<LoadingOutlined spin />}
            spinning={loading}
            style={{ marginTop: 20 }}
          >
            {hasError && (
              <>There was an issue completing your request. Please try again.</>
            )}
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {response}
            </ReactMarkdown>
          </Spin>
        </Flex>
      </Flex>
    </Modal>
  );
};

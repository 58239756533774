// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "wschar", "symbols": [/[ \t\n\v\f]/], "postprocess": id},
    {"name": "Main", "symbols": ["Expression"]},
    {"name": "Expression", "symbols": ["Condition"]},
    {"name": "Expression", "symbols": ["Range"]},
    {"name": "Expression", "symbols": ["Assignment"]},
    {"name": "Expression", "symbols": ["IfExpression"]},
    {"name": "Expression", "symbols": ["Count"]},
    {"name": "Expression", "symbols": ["OrdinalAssignment"]},
    {"name": "Identifier$ebnf$1", "symbols": [/[a-zA-Z0-9-_ ().%/&#]/]},
    {"name": "Identifier$ebnf$1", "symbols": ["Identifier$ebnf$1", /[a-zA-Z0-9-_ ().%/&#]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "Identifier", "symbols": ["Identifier$ebnf$1"], "postprocess": (d) => d.join("").toString().replace(/,/g, "")},
    {"name": "Operator$string$1", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "Operator", "symbols": ["Operator$string$1"]},
    {"name": "Operator$string$2", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "Operator", "symbols": ["Operator$string$2"]},
    {"name": "Operator", "symbols": [{"literal":"<"}]},
    {"name": "Operator", "symbols": [{"literal":">"}]},
    {"name": "Operator", "symbols": [{"literal":"="}]},
    {"name": "number$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "number$ebnf$1", "symbols": ["number$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "number$ebnf$2$subexpression$1$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "number$ebnf$2$subexpression$1$ebnf$1", "symbols": ["number$ebnf$2$subexpression$1$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "number$ebnf$2$subexpression$1", "symbols": [{"literal":"."}, "number$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "number$ebnf$2", "symbols": ["number$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "number$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "number", "symbols": ["number$ebnf$1", "number$ebnf$2"], "postprocess": d => parseFloat(d[0].join("") + (d[1] ? "." + d[1][1].join("") : ""))},
    {"name": "string$ebnf$1", "symbols": [/[^']/]},
    {"name": "string$ebnf$1", "symbols": ["string$ebnf$1", /[^']/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "string", "symbols": [{"literal":"'"}, "string$ebnf$1", {"literal":"'"}], "postprocess": d => d[1].join("")},
    {"name": "Condition", "symbols": [{"literal":"@"}, "Identifier", "_", "Operator", "_", "number"], "postprocess": 
        (d) => ({ type: "simple", ingredient: d[1], operator: d[3], value: parseFloat(d[5]) })
        },
    {"name": "Range", "symbols": ["number", "_", "Operator", "_", {"literal":"@"}, "Identifier", "_", "Operator", "_", "number"], "postprocess": 
        (d) => ({
            type: "range",
            lower: { value: parseFloat(d[0]), operator: d[2] },
            ingredient: d[5],
            upper: { operator: d[7], value: parseFloat(d[9]) }
        })
        },
    {"name": "Assignment$subexpression$1", "symbols": ["List"]},
    {"name": "Assignment$subexpression$1", "symbols": ["WeightedList"]},
    {"name": "Assignment", "symbols": [{"literal":"@"}, "Identifier", "_", {"literal":"="}, "_", "Assignment$subexpression$1"], "postprocess": 
        (d) => ({
            type: "assignment",
            group: d[1].trim(),
            ingredients: d[5]
        })
        },
    {"name": "List$ebnf$1", "symbols": []},
    {"name": "List$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", {"literal":"@"}, "Identifier"]},
    {"name": "List$ebnf$1", "symbols": ["List$ebnf$1", "List$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "List", "symbols": [{"literal":"@"}, "Identifier", "List$ebnf$1"], "postprocess": 
        (d) => [d[1]].concat(d[2].map(i => i[4]))
        },
    {"name": "WeightedList$ebnf$1", "symbols": []},
    {"name": "WeightedList$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "WeightedIngredient"]},
    {"name": "WeightedList$ebnf$1", "symbols": ["WeightedList$ebnf$1", "WeightedList$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "WeightedList", "symbols": ["WeightedIngredient", "WeightedList$ebnf$1"], "postprocess": 
        (d) => [d[0]].concat(d[1].map(i => i[3]))
        },
    {"name": "WeightedIngredient", "symbols": [{"literal":"@"}, "Identifier", "_", {"literal":"*"}, "_", "number"], "postprocess": 
        (d) => ({
            ingredient: d[1].trim(),
            factor: d[5]
        })
        },
    {"name": "IfExpression$string$1", "symbols": [{"literal":"I"}, {"literal":"F"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "IfExpression$subexpression$1", "symbols": [{"literal":","}, "_", "Condition"]},
    {"name": "IfExpression", "symbols": ["IfExpression$string$1", {"literal":"("}, "Condition", {"literal":","}, "_", "Condition", "IfExpression$subexpression$1", {"literal":")"}], "postprocess": 
        (d) => ({
            type: "if",
            condition: d[2],
            then: d[5],
            else: d[7] ? d[7][2] : null
        })
        },
    {"name": "Count$subexpression$1", "symbols": ["_", {"literal":","}, "_", "CountConstraints"]},
    {"name": "Count", "symbols": [{"literal":"@"}, "_", "Identifier", "Count$subexpression$1"], "postprocess": 
        (d) => ({
            type: "count",
            group: d[2],
            constraints: d[3][3],
            entire: d
        })
        },
    {"name": "CountConstraints$ebnf$1", "symbols": []},
    {"name": "CountConstraints$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "CountConstraint"]},
    {"name": "CountConstraints$ebnf$1", "symbols": ["CountConstraints$ebnf$1", "CountConstraints$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "CountConstraints", "symbols": ["CountConstraint", "CountConstraints$ebnf$1"], "postprocess": 
        (d) => Object.assign({}, d[0], ...d[1].map(i => i[3]))
        },
    {"name": "CountConstraint$subexpression$1$string$1", "symbols": [{"literal":"m"}, {"literal":"i"}, {"literal":"n"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "CountConstraint$subexpression$1", "symbols": ["CountConstraint$subexpression$1$string$1"]},
    {"name": "CountConstraint$subexpression$1$string$2", "symbols": [{"literal":"m"}, {"literal":"a"}, {"literal":"x"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "CountConstraint$subexpression$1", "symbols": ["CountConstraint$subexpression$1$string$2"]},
    {"name": "CountConstraint", "symbols": ["CountConstraint$subexpression$1", {"literal":":"}, "_", "number", "_"], "postprocess": 
        (d) => ({ [d[0]]: d[3] })
        },
    {"name": "OrdinalAssignment", "symbols": [{"literal":"@"}, "Identifier", "_", {"literal":"="}, "_", "string"], "postprocess": 
        (d) => ({
            type: "nominal",
            field: d[1].trim(),
            value: d[5]
        })
        }
]
  , ParserStart: "Main"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();

import { Button, Divider, Modal, Table, Typography } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { CustomRecordType } from '../experiment-list-layout-v2';
import { useIngredients } from '../../../_shared/hooks';
import {
  FormulationItemType,
  useformulationDetailQuery,
} from '../../../../../__generated__/globalTypes';
import { css } from '@emotion/react';
import {
  FormulationType,
  FormulationWithDesignType,
} from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import { IngredientsCard } from './formulation-details-modal-cards/ingredients-card';
import { OutcomesCard } from './formulation-details-modal-cards/outcomes-card';
import { Link } from 'react-router-dom';
import { IngredientCompositionCard } from '../experiment-detail/ingredient-composition';
import { ConstraintsCard } from './formulation-details-modal-cards/constraints-card';
import { ObjectivesCard } from './formulation-details-modal-cards/objectives-card';

export const FormulationDetailsModal = ({
  handleClose,
  selectedFormulationDetails,
}: {
  handleClose: Dispatch<React.SetStateAction<CustomRecordType | undefined>>;
  selectedFormulationDetails: CustomRecordType;
}) => {
  const { currentProject } = useSession();
  const { data: formulationWithDesign, error } = useformulationDetailQuery({
    variables: {
      formulationId: selectedFormulationDetails.formulation.id,
    },
  });
  const [formulation, setFormulation] = useState<FormulationType>(
    selectedFormulationDetails.formulation
  );

  return (
    <>
      {formulation && formulationWithDesign && (
        <Modal
          // title={formulation.key}
          width={680}
          closable={false}
          open={selectedFormulationDetails !== undefined}
          footer={
            <Button type="primary" onClick={handleClose}>
              Close
            </Button>
          }
        >
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              minHeight: '35px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: '1px 2px 7px 2px rgba(0,0,0,0.2)',
              zIndex: '99',
              margin: '10px 0px',
              fontSize: 'medium',
            }}
          >
            <span style={{ fontWeight: 600, marginRight: '8px' }}>
              Recipe Details:
            </span>{' '}
            {formulation.key}
          </div>
          <div
            style={{
              maxHeight: '60vh',
              overflow: 'auto',
              padding: '10px 0px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <IngredientsCard formulation={formulation} />
            {(formulationWithDesign.formulation?.design?.constraints ||
              formulationWithDesign.formulation?.design
                ?.costOptimizationOption !== 'DO_NOT_OPTIMIZE') && (
                <ConstraintsCard
                  design={formulationWithDesign.formulation?.design}
                />
              )}
            <OutcomesCard formulation={formulation} />
            {formulationWithDesign.formulation?.design?.objectives && (
              <ObjectivesCard
                design={formulationWithDesign.formulation?.design}
              />
            )}
            {formulationWithDesign.formulation?.ingredientCompositionTotals &&
              formulationWithDesign.formulation?.ingredientCompositionTotals
                .length > 0 && (
                <IngredientCompositionCard
                  experiment={formulationWithDesign.formulation}
                />
              )}
          </div>
        </Modal>
      )}
    </>
  );
};

/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Layout, Spin, FloatButton } from 'antd';
import { initMixpanel } from '../../_shared/tracking/mixpanel';
import { DashboardHeader } from './dashboard-header.component';
import { DashboardFooter } from './dashboard-footer.component';
import {
  appLayoutStyle,
  appHeaderStyle,
  appFooterStyle,
  appContentLayoutStyle,
} from './dashboard-layout.styles';
import { useSession } from '../../_shared/context/session-context';
import { AppRoutes } from '../app-routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CreateProjectCopilot } from '../experiments/copilot/create-project';
import ContactTuringSupport from '../support/contactTuringSupport';
import { ProjectSiderV2 } from '../project/project-sider-v2.component';
import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import Library, { DropDownOptions } from '../library/library';
import { DndContext } from '@dnd-kit/core';
import { useDragDropContext } from '../../_shared/context/drag-context';

const { Header, Footer } = Layout;

export const DashboardLayout = () => {
  const { user, currentProject, openNavBar } = useSession();
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  React.useEffect(() => {
    if (!user) return;
    //Placing this before spiner render because react was not liking the
    //Differing hooks being ran
    initMixpanel(user.email, user.id);
  }, [user]);

  if (!user) return <Spin>Loading user...</Spin>;

  const shouldShowNavBar =
    openNavBar && user.enableIceCreamBetaFeatures && currentProject;

  const {
    setDraggedItemId,
    setDropTargetId,
    resetState,
    addDroppedIngredients,
  } = useDragDropContext();

  const handleDragEnd = event => {
    const { active, over } = event;
    if (over) {
      console.log('Item dropped:', active.id, 'over:', over.id);
    }
    if (event.over?.id === 'drop-target' && event.active.data.current) {
      const activeItem = event.active.data.current;

      addDroppedIngredients({
        id: activeItem.id,
        name: activeItem.name,
        type: activeItem.type,
      });
      resetState();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DndContext
      // onDragStart={(event) => {
      //   setDraggedItemId(event.active.id as string);
      //   setDropTargetId(null);
      // }}
      // onDragEnd={handleDragEnd}
      >
        <Layout css={appLayoutStyle}>
          <Layout className="App" style={{ height: 'calc(100vh - 5px)' }}>
            <Header css={appHeaderStyle}>
              <DashboardHeader />
            </Header>
            <Library />
            <Layout css={appContentLayoutStyle}>
              {user.enableIceCreamBetaFeatures && (
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    width: 100%;
                  `}
                >
                  {shouldShowNavBar && (
                    <div
                      css={css`
                        width: 260px;
                        flex-shrink: 0;
                        overflow-y: auto;
                        border-right: 1px solid #e8e8e8;
                      `}
                    >
                      <ProjectSiderV2 />
                    </div>
                  )}
                  <div
                    css={css`
                      flex-grow: 1;
                      overflow-y: auto;
                      width: ${shouldShowNavBar
                        ? 'calc(100% - 250px)'
                        : '100%'};
                    `}
                  >
                    <AppRoutes user={user} />
                  </div>
                </div>
              )}
              {!user.enableIceCreamBetaFeatures && <AppRoutes user={user} />}
            </Layout>
            <Footer css={appFooterStyle}>
              <DashboardFooter />
            </Footer>
            <FloatButton
              icon={<PlusOutlined />}
              style={{ insetInlineEnd: 90 }}
              onClick={() => setShowCreateProjectModal(!showCreateProjectModal)}
            />
            <CreateProjectCopilot
              open={showCreateProjectModal}
              onClose={() => setShowCreateProjectModal(false)}
            />
            <ContactTuringSupport />
          </Layout>
        </Layout>
      </DndContext>
    </DndProvider>
  );
};

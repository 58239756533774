/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Form, Button, Modal, InputNumber, Tooltip, Select, Input, message, notification } from 'antd';
import {
  CheckCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/react';

import {
  useSession,
} from '../../../_shared/context';
import { Option } from 'antd/es/mentions';
import { UploadDataSet } from './upload-data/upload-data';
import { useCreateDataset } from '../../../network/services/dataset.service';
import { CreateDataSetOrgData, FormulationDataSetIn } from '@root/network/interfaces/formulationDataSet.interfaces';
import { User } from '__generated__/globalTypes';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  open: boolean;
  setOpenUploadModal: (o: boolean) => void;
  handleOnUploadComplete: () => void;
};

const rowNames = new Set<String>(['Internal ID', 'Turing ID', 'Cost']);
const outcomeNames = new Set<string>();

export const UploadDatasetModal = ({
  open,
  setOpenUploadModal,
  handleOnUploadComplete
}: ModalProps) => {
  const { currentProject, user } = useSession();

  currentProject?.activeModel?.outcomes.forEach(o =>
    outcomeNames.add(o.targetVariable)
  );
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false)
  const [formulations, setFormulations] = useState<FormulationDataSetIn>();
  const useCreateDataSet = useCreateDataset();



  const resetModal = () => {
    setUploadedSuccessfully(false);
    setOpenUploadModal(false);
    form.resetFields();
  };


  const uploadData = (formulations: FormulationDataSetIn) => {
    setFormulations(formulations)
  }

  const DefaultView = (
    <UploadDataSet disabled={false} uploadData={uploadData} />
  );

  const SuccessView = (
    <div
      css={css`
        text-align: center;
        padding-top: 20px;
      `}
    >
      <CheckCircleOutlined style={{ fontSize: '100px' }} />

      <h3
        css={css`
          margin-top: 15px;
          margin-bottom: 15px;
        `}
      >
        Data Upload Successful!
      </h3>

      <p>Your data has been uploaded and is being processed.</p>
      <Button
        type="primary"
        size={'large'}
        css={css`
          margin-top: 15px;
        `}
        onClick={resetModal}
      >
        Close
      </Button>
    </div>
  );

  const LoadingView = (
    <div>
      <LoadingOutlined
        css={css`
          font-size: 100px;
          margin-bottom: 10px;
        `}
        spin
      />
      <p>Uploading file...</p>
    </div>
  );

  const onFinish = async (values: any) => {
    const { dataset_name, dataset_product_category, dataset_description } = values;
    if (user && formulations) {
      const create: CreateDataSetOrgData = {
        organizationId: user?.organizationId,
        createdById: user?.id,
        data: JSON.parse(JSON.stringify(formulations.formulations)),
        description: dataset_description,
        formulationDataset: formulations,
        name: dataset_name,
        productCategory: dataset_product_category
      }
      setLoading(true);
      useCreateDataSet.mutate(create, {
        onSuccess: (response) => {

          if (response.status) {
            notification.success({ message: t('dataset-upload.page.uploadSuccessMessage') })
            form.resetFields();
            setFormulations(undefined);
            setOpenUploadModal(false);
            handleOnUploadComplete();
          } else
            notification.error({ message: t('dataset-upload.page.uploadErrorMessage') })
        },
        onError: (response) => {
          notification.error({ message: t('dataset-upload.page.uploadErrorMessage') })
        }, onSettled: () => {
          setLoading(false);
        }
      })
    }
  };

  return (
    <Modal
      title={
        uploadedSuccessfully ? 'File Uploaded' : 'Upload your dataset'
      }
      centered
      width={600}
      destroyOnClose
      styles={{
        body: {
          minHeight: 400,
        },
      }}
      open={open}
      onCancel={resetModal}
      footer={
        uploadedSuccessfully
          ? null
          : [<Button onClick={() => setOpenUploadModal(false)} key="back">
            Cancel
          </Button>, <Button key="confirm" className='primary-button' htmlType='submit' form='upload-form' loading={loading}>
            Create
          </Button>,

          ]
      }
    >
      {uploadedSuccessfully ? (
        SuccessView
      ) : (
        <React.Fragment>
          <Form
            form={form}
            name="basic"
            id="upload-form"
            onFinish={onFinish}
          >
            {/* TITLE */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
                marginTop: 10
              }}
            >
              Dataset Name
            </span>
            <Form.Item
              name="dataset_name"

              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input />
            </Form.Item>

            {/* FORMULATIONS */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
              }}
            >
              Description
            </span>
            <div className='formulations_container'>
              <Form.Item
                name="dataset_description"
                rules={[
                  {
                    required: true, message: 'Description is required'
                  },
                ]}
              >
                <Input />

              </Form.Item>

            </div>

            {/* ITERATIONS */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
                marginTop: 10
              }}
            >
              Product Category
            </span>

            <Form.Item
              name="dataset_product_category"
              rules={[
                {
                  required: true, message: 'Product category is required'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>

          <React.Fragment>
            {DefaultView}
          </React.Fragment>

        </React.Fragment>
      )}
    </Modal>
  );
};

const validateRowNames = (rowName: string | undefined) => {
  let inBaseRowNames = false;
  let inOutcomeNames = false;

  if (rowName) {
    inBaseRowNames = rowNames.has(rowName);
    outcomeNames?.forEach(o => {
      if (rowName.includes(o)) inOutcomeNames = true;
    });
  }

  return inBaseRowNames || inOutcomeNames;
};

const getResultsRowName = (data: string[][]) => {
  // The header for the first column can either be 'Product name', 'Product Name'or 'name
  let rowName = data['Product name' as any];

  if (data['Name' as any]) {
    rowName = data['Name' as any];
  }

  if (data['Product Name' as any]) {
    rowName = data['Product Name' as any];
  }

  return rowName?.toString();
};

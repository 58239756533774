
import React, { useEffect, useState } from "react";
// @ts-ignore
import styles from "./dataset-view.module.less";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, notification, Switch, Table } from "antd";
import { css } from "@emotion/react";
import { DataSet } from "@prisma/client";
import { CreateDataSetOrgData } from "../../../network/interfaces/formulationDataSet.interfaces";
import { useSession } from "../../../_shared/context";
import { DatasetFormulationTable } from "./dataset-formulations/edit-data/edit-data";
import { useEditDataset } from "../../../network/services/dataset.service";
import { UploadDatasetModal } from "../dataset-upload/dataset-upload-modal";
import { CreateProjectDatasetModal } from "../dataset-create-project/dataset-create-project";
export const DataSetView = ({ handleClose, dataSet }: { handleClose: () => void, dataSet: DataSet }) => {

  const [open, setOpen] = useState(false)
  const { user } = useSession();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const useEditDatasetMutation = useEditDataset();


  const onFinish = async (values: any) => {
    const { dataset_name, dataset_product_category, dataset_description } = values;
    if (user) {
      const create: CreateDataSetOrgData = {
        id: dataSet.id,
        organizationId: user?.organizationId,
        createdById: user?.id,
        data: JSON.parse(JSON.stringify('')),
        description: dataset_description,
        name: dataset_name,
        productCategory: dataset_product_category
      }

      useEditDatasetMutation.mutate(create, {
        onSuccess: (response) => {
          if (response.status) {
            notification.success({ message: 'Dataset modified successfully' })
            setEditMode(false);
          } else {
            notification.error({ message: response.message })
          }

        }
      })


    }
  };


  return (
    <div className={styles.dataset}>
      <div className={styles.controls}>
        <Button title={t('dataset-upload.page.dataset-view.page.createProjectLabel')} className={styles.button} type="primary" onClick={() => setOpen(true)}>
          {t('dataset-upload.page.dataset-view.page.createProjectLabel')}
        </Button>
        <Button onClick={() => handleClose()} key="close">
          Close
        </Button>
      </div>
      <div className={styles.datasetFull}>
        <div className={styles.datasetInfo}>
          <Form
            form={form}
            name="basic"
            id="edit-form"
            className={styles.uploadForm}
            onFinish={onFinish}
          >
            {/* TITLE */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
                marginTop: 10
              }}
            >
              Dataset Name
            </span>
            <Form.Item
              name="dataset_name"
              initialValue={dataSet.name}
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input disabled={!editMode} />
            </Form.Item>

            {/* FORMULATIONS */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
              }}
            >
              Description
            </span>
            <div className='formulations_container'>

              <Form.Item
                name="dataset_description"
                initialValue={dataSet.description}
                rules={[
                  {
                    required: true, message: 'Description is required'
                  },
                ]}
              >
                <Input disabled={!editMode} />

              </Form.Item>

            </div>

            {/* ITERATIONS */}
            <span
              style={{
                display: 'block',
                color: '#7C858C',
                paddingBottom: 5,
                width: '100%',
                marginTop: 10
              }}
            >
              Product Category
            </span>
            <Form.Item
              name="dataset_product_category"
              //@ts-ignore
              initialValue={dataSet.ProductCategory.name}
              rules={[
                {
                  required: true, message: 'Product category is required'
                },
              ]}
            >

              <Input disabled={!editMode} />
            </Form.Item>
          </Form>
          <div className={styles.editMode}>
            <div className={styles.enableEdit}>
              <span
                style={{
                  display: 'block',
                  color: 'black',
                  width: '100%',
                  marginRight: 10
                }}
              >
                Edit
              </span>
              <Switch checked={editMode} onChange={() => setEditMode(!editMode)} />
            </div>

            {editMode &&
              <Button title="edit" className={styles.button} htmlType='submit' form='edit-form' type="primary" >
                Save
              </Button>

            }
          </div>
        </div>
        <div className={styles.datasetFormulation}>
          {dataSet && user && <DatasetFormulationTable datasetId={dataSet.id} organizationId={user?.organizationId} />}
        </div>
      </div>
      <CreateProjectDatasetModal open={open} dataset={dataSet} handleOnUploadComplete={() => { }} setOpenModal={setOpen} />
    </div >
  );
};

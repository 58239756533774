/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { createRoot } from 'react-dom/client';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { AuthStateApp } from './components/app';
import SessionContextProvider from './_shared/context/session-context';
import { centerInContainer } from './_shared/style/positioning.styles';
import './_shared/style/root.less';
import './_shared/style/fonts/inter.css';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithHistory } from './components/auth/auth0-provider-with-history';
import { PostHogProvider } from 'posthog-js/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FeatureContextProvider } from './_shared/context/features-context';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { DragDropProvider } from './_shared/context/drag-context';

const rootContainer = document.getElementById('root');

//Eventually need to tie into the history object
//TODO: Get working on other non-prod envs
if (__SENTRY_DSN__?.length) {
  Sentry.init({
    dsn: __SENTRY_DSN__,
    integrations: [
      new Integrations.BrowserTracing({}),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: __ENV__,
    release: `turing-${__ENV__}@${__VERSION__}`,
    autoSessionTracking: true,
    /** TESTING Sentry Replay */
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.log(`[SENTRY] Not initiating SENTRY on ${__ENV__} `);
}

const FallbackComponent = ({
  eventId,
  error,
  resetError,
}: {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}) => {
  console.error(`Exception ID ${eventId || 'NULL'}`);
  console.log('Error', error.stack);
  console.log('Error', error.name);
  console.log('Error', error.message);
  return (
    <div
      css={css`
        ${centerInContainer}
        flex-direction: column;
      `}
    >
      <p>
        An error has occured and has been reported to Turing. Click the button
        to refresh. If the error still occurs, navigate to the homepage and
        refresh.
      </p>
      <button type="button" onClick={resetError}>
        Try again
      </button>
    </div>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(rootContainer!);
root.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary
        fallback={FallbackComponent}
        //ShowDialog may not fire for event handler errors
        showDialog
      >
        <PostHogProvider
          apiKey={__PUBLIC_POSTHOG_KEY__}
          options={{
            api_host: 'https://app.posthog.com',
          }}
        >
          <DragDropProvider>
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <SessionContextProvider>
                  <FeatureContextProvider>
                    <AuthStateApp />
                  </FeatureContextProvider>
                </SessionContextProvider>
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </DragDropProvider>
        </PostHogProvider>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  </I18nextProvider>
);

console.log(`
------------------------------------
___
 |   ._o._  _  |  _.|_  _
 ||_|| || |(_| |_(_||_)_>
            _|

Version: ${__VERSION__}
Published on: ${__PUBLISHED_DATE__}
------------------------------------
`);

import { ProjectSetupSteps } from '../../components/exploration/constants';
import { IHeaderProjectSetup } from './project-setup-interfaces';

export const ProjecSetupSteps = {};

export enum TypeOfDevelopment {
  New = 'New',
  Extension = 'Extension',
  ReFormulation = 'Re-Formulation',
}

export enum TypeOfProject {
  Exploration = 'Exploration',
  Preserve_Label_Reduce_Cost = 'Preserve Label Reduce Cost'
}

export type ProjectInfoFormData = { [key: string]: MandatoryFieldInput };

export type MandatoryFieldInput = {
  description: string;
  inputType?: 'input' | 'textarea' | 'date' | 'select';
  selectOptions?: string[];
  maxLength?: number;
  placeHolder: string;
};

export const ProjectInfoFormMandatoryField: ProjectInfoFormData = {
  projectName: {
    description:
      "Enter a unique name to identify your project. This could be anything from a product name to a project code. For example, if you're creating a project for a new skincare product, you might enter 'HydraGlow Serum' as your project name.",
    placeHolder: 'Project Name',
  },
  productName: {
    description:
      "Clearly describe your product, including its name and type. For example, 'Lightweight Sunscreen with SPF 50' or 'Revitalize Low-Sugar Energy Drink'. This helps us grasp the essence of your product at a glance.",
    placeHolder: 'Product Name',
  },
  productCategory: {
    description:
      "Specify the general category or type of your product. This helps classify your product for better organization and understanding. For instance, 'Skincare' for 'Lightweight Sunscreen with SPF 50' or 'Beverage' for 'Revitalize Low-Sugar Energy Drink'.",
    placeHolder: 'Product Category',
  },
  projectObjective: {
    description:
      "Share the main goal or purpose of your project. For instance, 'To increase overall liking by 2% while simultaneously reducing costs by 5%.'",
    inputType: 'textarea',
    placeHolder: 'Project Objective',
  },
  ProjectType: {
    placeHolder: 'Project Type',
    description: "Project type defines its goal. 'Exploration' allows experimenting with new formulations, while 'Preserve Label, Reduce Cost' optimizes costs without modifying the existing label.",
    inputType: 'select',
    selectOptions: Object.values(TypeOfProject),
  }
};

export const ProjectInfoObligatoryFields: ProjectInfoFormData = {
  productSubCategory: {
    placeHolder: 'Product Sub Category',
    description:
      "Provide a more specific classification for your product within its broader category. This helps to further refine and categorize your product. For example, 'Facial Sunscreen' for 'Lightweight Sunscreen with SPF 50' or 'Carbonated Energy Drink' for 'Revitalize Low-Sugar Energy Drink'",
  },
  typeOfDevelopment: {
    placeHolder: 'Type of Development',
    description:
      'Please choose the development stage for your project. Options: New, Extension, Re-Formulation.',
    inputType: 'select',
    selectOptions: Object.values(TypeOfDevelopment),
  },
  launchDate: {
    placeHolder: 'Launch Date',
    description:
      'Specify the planned date for introducing your product to the market. This helps in scheduling and coordinating activities related to your product launch.',
    inputType: 'date',
  },
  productBrand: {
    placeHolder: 'Product Brand',
    description:
      'Enter the brand name associated with your product. This will help us organize projects under the same brand more efficiently',
  },
  'Overall Project Description': {
    placeHolder: 'Overall Project Description',
    description:
      'Provide a brief overview of your project, including its objectives, scope, and key details. This helps stakeholders understand the purpose and scope of the project at a glance.',
    inputType: 'textarea',
  },
  'Owner Of The Project': {
    placeHolder: 'Owner Of The Project',
    description:
      'Specify the individual or team responsible for overseeing and driving the project forward. This helps ensure clear accountability and coordination throughout the project lifecycle.',
  },
};

export const ProjectSetupHeaders: {
  [key in ProjectSetupSteps]: IHeaderProjectSetup;
} = {
  [ProjectSetupSteps.LOADING]: {
    title: 'Project Info',
    description: 'Craft Your Project: Fill in the Details!',
  },
  [ProjectSetupSteps.PROJECT_INFO]: {
    title: 'Project Info',
    description: 'Craft Your Project: Fill in the Details!',
  },
  [ProjectSetupSteps.UPLOAD_DATA]: {
    title: 'Inputs & Outcomes',
    description: 'Share info about your inputs and outcomes!',
  },
  [ProjectSetupSteps.EDIT_RAW_DATA]: {
    title: 'Inputs & Outcomes',
    description: 'Share info about your inputs and outcomes!',
  },
  [ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES]: {
    title: 'Inputs & Outcomes',
    description: 'Share info about your inputs and outcomes!',
  },
  [ProjectSetupSteps.INPUT_OUTCOMES]: {
    title: 'Inputs & Outcomes',
    description: 'Share info about your inputs and outcomes!',
  },
  [ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS]: {
    title: 'Non-negotiable constraints',
    description:
      "This section is all about the must-haves and game-changers. Only the most crucial input constraints belong here, so let's make it count!",
  },
};

export const ProjectInputOutcomeTypeDescription = `
Select the type of variable: is it continuous data with a range of values (like the amount of an ingredient), discrete data with a predefined list of values (like the number of components), or ordinal data with a predefined list in a specific order (like preference rankings for formulations)?
`;

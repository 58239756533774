import React, { useEffect, useState } from 'react';
import { FloatButton, Popover, Dropdown, Menu, Space, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './library.less';
import SearchableDropdown from '../../_shared/components/searchable-dropdown/searchable-dropdown';
import {
  useGetIngredientsByOrg,
  useGetProcessingConditionsByOrg,
} from '../../network/services/ingredient.service';
import { useSession } from '../../_shared/context';
import SearchableDropdownDragAndDrop from '../../_shared/components/sercheable-dropdown-drag-and-drop/searchable-dropdown-drag-and-drop';
import { useGetOutcomesByOrg } from '../../network/services/outcome.service';
import { useGetObjectiveByOrg } from '../../network/services/objective.service';
import { useGetConstraintsByOrg } from '../../network/services/constraint.service';
import { useGetIngredientCompositionsByOrganization } from '../../network/services/ingredient-composition.service';
import { useGetIngredientGroupByOrganization } from '../../network/services/ingredient-group.service';
import { useLocation } from 'react-router-dom';
import { useDragDropContext } from '../../_shared/context/drag-context';

export enum DropDownOptions {
  Ingredients = 'Ingredients',
  Outcomes = 'Outcomes',
  ProcessingConditions = 'Processing Conditions',
  Objectives = 'Objectives',
  Constraints = 'Constraints',
  Groups = 'Groups',
  Compositions = 'Compositions',
}

enum PaginationTypes {
  Ingredients = 'Ingredients',
  Outcomes = 'Outcomes',
  Objectives = 'Objectives',
  Constraints = 'Constraints',
  ProcessingConditions = 'ProcessingConditions',
  Groups = 'Groups',
  Compositions = 'Compositions',
}
enum DataStateType {
  Ingredients = 'ingredients',
  Outcomes = 'outcomes',
  Objectives = 'objectives',
  Constraints = 'constraints',
  ProcessingConditions = 'processingConditions',
  IngredientComposition = 'ingredientComposition',
  IngredientGroup = 'ingredientGroup',
}

const Library = () => {
  const limit = 10; // Number of items per page

  const [visible, setVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedOutcome, setSelectedOutcomes] = useState<string[]>([]);
  const [selectedObjectives, setSelectedObjectives] = useState<string[]>([]);
  const [selectedConstraint, setSelectedConstraint] = useState<string[]>([]);
  const [
    selectedProcessingConditions,
    setSelectedProcessingConditions,
  ] = useState<string[]>([]);
  const [
    selectedIngredientComposition,
    setSelectedIngredientComposition,
  ] = useState<string[]>([]);
  const [selectedIngredientGroup, setSelectedIngredientGroup] = useState<
    string[]
  >([]);
  const {
    filterIngredients,
    filterProcessingConditions,
  } = useDragDropContext();

  let excludedNames: any = [];

  const { user } = useSession();
  useEffect(() => {
    refetchIngridients();
  }, [filterIngredients]);
  useEffect(() => {
    refetchProcessingConditions();
  }, [filterProcessingConditions]);

  const location = useLocation();

  const matchNew = location.pathname.match(/\/new\/([\w-]+)/);
  const matchInspect = location.pathname.match(/\/inspect\/([\w-]+)/);

  if (matchNew) {
    // console.log("matchNew", matchNew)
    // console.log("matchNew", matchNew[1]);
  }
  if (matchInspect) {
    // console.log("matchInspect", matchInspect)
    // console.log("matchInspect", matchInspect[1]);
  }

  const initialDataState = {
    ingredients: [],
    outcomes: [],
    objectives: [],
    constraints: [],
    processingConditions: [],
    ingredientComposition: [],
    ingredientGroup: [],
  };
  const [pageOutcomes, setPageOutcomes] = useState(1);
  const [localData, setLocalData] = useState(initialDataState);

  // To update a specific data type
  const updateLocalData = (type: any, items: any, shouldAppend = false) => {
    setLocalData(prevState => ({
      ...prevState,
      [type]:
        shouldAppend && prevState[type]
          ? [...prevState[type], ...items]
          : items,
    }));
  };

  //New section
  const [pagination, setPagination] = useState({
    Ingredients: { page: 1, searchTerm: '' },
    Outcomes: { searchTerm: '' },
    Objectives: { page: 1, searchTerm: '' },
    Constraints: { page: 1, searchTerm: '' },
    ProcessingConditions: { page: 1, searchTerm: '' },
    Groups: { page: 1, searchTerm: '' },
    Compositions: { page: 1, searchTerm: '' },
  });

  const handleSearchChange = (type: string, value: string) => {
    updatePagination(type, 'searchTerm', value);
    updatePagination(type, 'page', 1);
  };

  const {
    data: ingredientsData,
    isError,
    isLoading: isIngredientsLoading,
    isSuccess,
    refetch: refetchIngridients,
    isFetching: isFetchingIngredients,
  } = useGetIngredientsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Ingredients.page,
    limit,
    searchTerm: pagination.Ingredients.searchTerm,
    excludedNames: filterIngredients && filterIngredients,
  });

  const {
    data: outcomesData,
    isLoading: isOutcomesLoading,
    refetch: refetchOutcomes,
    isFeching: isFetchingOutcomes,
  } = useGetOutcomesByOrg({
    organizationId: user?.organizationId!,
    page: pageOutcomes,
    limit,
    searchTerm: pagination.Outcomes.searchTerm,
  });

  const {
    data: objectivesData,
    isLoading: isObjectivesLoading,
    refetch: refetchObjectives,
    isFetching: isFetchingObjectives,
  } = useGetObjectiveByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Objectives.page,
    limit,
    searchTerm: pagination.Objectives.searchTerm,
  });

  const {
    data: constraintData,
    isLoading: isConstraintLoading,
    refetch: refetchConstraints,
    isFetching: isFetchingConstraints,
  } = useGetConstraintsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Constraints.page,
    limit,
    searchTerm: pagination.Constraints.searchTerm,
  });
  const {
    data: processingConditionsData,
    isLoading: isProcessingConditionsLoading,
    refetch: refetchProcessingConditions,
    isFetching: isFetchingProcessingConditions,
  } = useGetProcessingConditionsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.ProcessingConditions.page,
    limit,
    searchTerm: pagination.ProcessingConditions.searchTerm,
    excludedNames: filterProcessingConditions && filterProcessingConditions,
  });

  const {
    data: ingredientCompositionData,
    isLoading: isIngredientCompositionLoading,
    refetch: refetchIngredientComposition,
    isFetching: isFetchingIngredientComposition,
  } = useGetIngredientCompositionsByOrganization({
    organizationId: user?.organizationId!,
    page: pagination.Compositions.page,
    limit,
    searchTerm: pagination.Compositions.searchTerm,
  });
  const {
    data: ingredientGroupData,
    isLoading: isIngredientGroupLoading,
    refetch: refetchIngredientGroup,
    isFetching: isFetchingIngredientGroup,
  } = useGetIngredientGroupByOrganization({
    organizationId: user?.organizationId!,
    page: pagination.Groups.page,
    limit,
    searchTerm: pagination.Groups.searchTerm,
  });

  useEffect(() => {
    if (ingredientsData?.data?.data?.length) {
      const items = mapIngredients(ingredientsData);
      if (pagination.Ingredients.page === 1) {
        updateLocalData(DataStateType.Ingredients, items);
      } else {
        updateLocalData(DataStateType.Ingredients, items, true);
      }
    } else {
      updateLocalData(DataStateType.Ingredients, []);
    }
  }, [ingredientsData]);

  useEffect(() => {
    if (outcomesData?.data?.pagination?.total) {
      const items = mapOutcomes(outcomesData);
      if (pageOutcomes === 1) {
        updateLocalData(DataStateType.Outcomes, items);
      } else {
        updateLocalData(DataStateType.Outcomes, items, true);
      }
    } else {
      updateLocalData(DataStateType.Outcomes, []);
    }
  }, [outcomesData]);

  useEffect(() => {
    if (objectivesData?.data?.data?.length) {
      const items = mapObjectives(objectivesData);
      if (pagination.Objectives.page === 1) {
        updateLocalData(DataStateType.Objectives, items);
      } else {
        updateLocalData(DataStateType.Objectives, items, true);
      }
    } else {
      updateLocalData(DataStateType.Objectives, []);
    }
  }, [objectivesData]);

  useEffect(() => {
    if (constraintData?.data?.data?.length) {
      const items = mapConstraints(constraintData);
      if (pagination.Constraints.page === 1) {
        updateLocalData(DataStateType.Constraints, items);
      } else {
        updateLocalData(DataStateType.Constraints, items, true);
      }
    } else {
      updateLocalData(DataStateType.Constraints, []);
    }
  }, [constraintData]);

  useEffect(() => {
    if (processingConditionsData?.data?.data?.length) {
      const items = mapProcessing(processingConditionsData);
      if (pagination.ProcessingConditions.page === 1) {
        updateLocalData(DataStateType.ProcessingConditions, items);
      } else {
        updateLocalData(DataStateType.ProcessingConditions, items, true);
      }
    } else {
      updateLocalData(DataStateType.ProcessingConditions, []);
    }
  }, [processingConditionsData]);

  useEffect(() => {
    if (ingredientCompositionData?.data?.data?.length) {
      const items = mapIngredientComposition(ingredientCompositionData);
      if (pagination.Compositions.page === 1) {
        updateLocalData(DataStateType.IngredientComposition, items);
      } else {
        updateLocalData(DataStateType.IngredientComposition, items, true);
      }
    } else {
      updateLocalData(DataStateType.IngredientComposition, []);
    }
  }, [ingredientCompositionData]);

  useEffect(() => {
    if (ingredientGroupData?.data?.data?.length) {
      const items = mapIngredientGroup(ingredientGroupData);
      if (pagination.Groups.page === 1) {
        updateLocalData(DataStateType.IngredientGroup, items);
      } else {
        updateLocalData(DataStateType.IngredientGroup, items, true);
      }
    } else {
      updateLocalData(DataStateType.IngredientGroup, []);
    }
  }, [ingredientGroupData]);

  const updatePagination = (type: any, key: any, value: any) => {
    if (type === PaginationTypes.Outcomes) {
      setPageOutcomes(value);
    }
    setPagination(prev => ({
      ...prev,
      [type]: { ...prev[type], [key]: value },
    }));
  };

  const fetchMoreIngredient = () =>
    fetchMore(
      PaginationTypes.Ingredients,
      isIngredientsLoading,
      ingredientsData,
      PaginationTypes.Ingredients,
      refetchIngridients
    );
  const fetchMoreOutcome = () =>
    fetchMore(
      PaginationTypes.Outcomes,
      isOutcomesLoading,
      outcomesData,
      PaginationTypes.Outcomes,
      refetchOutcomes
    );
  const fetchMoreObjectives = () =>
    fetchMore(
      PaginationTypes.Objectives,
      isObjectivesLoading,
      objectivesData,
      PaginationTypes.Objectives,
      refetchObjectives
    );
  const fetchMoreConstraints = () =>
    fetchMore(
      PaginationTypes.Constraints,
      isConstraintLoading,
      constraintData,
      PaginationTypes.Constraints,
      refetchConstraints
    );
  const fetchMoreProcessingConditions = () =>
    fetchMore(
      PaginationTypes.ProcessingConditions,
      isProcessingConditionsLoading,
      processingConditionsData,
      PaginationTypes.ProcessingConditions,
      refetchProcessingConditions
    );
  const fetchMoreIngredientComposition = () =>
    fetchMore(
      PaginationTypes.Compositions,
      isIngredientCompositionLoading,
      ingredientCompositionData,
      PaginationTypes.Compositions,
      refetchIngredientComposition
    );
  const fetchMoreIngredientGroup = () =>
    fetchMore(
      PaginationTypes.Groups,
      isIngredientGroupLoading,
      ingredientGroupData,
      PaginationTypes.Groups,
      refetchIngredientGroup
    );

  const fetchMore = (
    type: PaginationTypes,
    isLoading: any,
    data: any,
    paginationKey: any,
    functionRefetch: any
  ) => {
    if (
      isLoading ||
      !(data?.data?.pagination?.page < data?.data?.pagination?.totalPages)
    )
      return; // Prevent fetch if loading or already on last page
    if (type === PaginationTypes.Outcomes) {
      setPageOutcomes(prev => prev + 1);
    } else {
      updatePagination(type, 'page', pagination[paginationKey].page + 1);
    }
    functionRefetch();
  };

  useEffect(() => {
    console.log('pagoutcomes', pagination.Outcomes.page);
  }, [pagination.Outcomes]);

  //#region Map

  const mapIngredients = (localIngredientData: any) => {
    console.log({ localIngredientData });
    if (localIngredientData) {
      const items = localIngredientData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        type: DropDownOptions.Ingredients,
      }));
      return items;
    }
  };
  const mapProcessing = (localIngredientData: any) => {
    console.log({ localIngredientData });
    if (localIngredientData) {
      const items = localIngredientData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        type: DropDownOptions.ProcessingConditions,
      }));
      return items;
    }
  };
  const mapOutcomes = (outcomesData: any) => {
    if (outcomesData) {
      const items = outcomesData.data.data.map(item => ({
        id: item.id,
        name: item.targetVariable,
        category: item.OutcomeCategory.name,
      }));
      return items;
    }
  };
  const mapConstraints = (constraintData: any) => {
    if (constraintData) {
      const items = constraintData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  const mapObjectives = (objectivesData: any) => {
    if (objectivesData) {
      const items = objectivesData.data.data.map(item => ({
        id: item.id,
        name: item.targetVariable,
        objectiveType: item.objectiveType,
      }));
      return items;
    }
  };
  const mapIngredientComposition = (ingredientCompositionData: any) => {
    if (ingredientCompositionData) {
      const items = ingredientCompositionData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  const mapIngredientGroup = (ingredientGroupData: any) => {
    if (ingredientGroupData) {
      const items = ingredientGroupData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  //#endregion

  return (
    <Popover
      content={
        <Space>
          <SearchableDropdownDragAndDrop
            listItems={localData.ingredients}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            emptytext={`No Ingredient Found`}
            placeholder={'Ingredient'}
            title={'Ingredient'}
            key={0}
            isLoading={isFetchingIngredients}
            hasMore={
              ingredientsData?.data?.pagination?.page <
              ingredientsData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreIngredient}
            searchTerm={pagination.Ingredients.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Ingredients, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Ingredients, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchIngridients}
            type={DropDownOptions.Ingredients}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.outcomes}
            setSelectedItems={setSelectedOutcomes}
            selectedItems={selectedOutcome}
            emptytext={`No Outcomes Found`}
            placeholder={'Outcomes'}
            title={'Outcomes'}
            key={1}
            isLoading={isFetchingOutcomes}
            hasMore={
              outcomesData?.data?.pagination?.page <
              outcomesData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreOutcome}
            searchTerm={pagination.Outcomes.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Outcomes, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Outcomes, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchOutcomes}
            type={DropDownOptions.Outcomes}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.objectives}
            setSelectedItems={setSelectedObjectives}
            selectedItems={selectedObjectives}
            emptytext={`No Objectives Found`}
            placeholder={'Objectives'}
            title={'Objectives'}
            key={1}
            isLoading={isFetchingObjectives}
            hasMore={
              objectivesData?.data?.pagination?.page <
              objectivesData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreObjectives}
            searchTerm={pagination.Objectives.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Objectives, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Objectives, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchObjectives}
            type={DropDownOptions.Objectives}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.constraints}
            setSelectedItems={setSelectedConstraint}
            selectedItems={selectedConstraint}
            emptytext={`No Constraint Found`}
            placeholder={'Constraints'}
            title={'Constraints'}
            key={2}
            isLoading={isFetchingConstraints}
            hasMore={
              constraintData?.data?.pagination?.page <
              constraintData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreConstraints}
            searchTerm={pagination.Constraints.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Constraints, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Constraints, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchConstraints}
            type={DropDownOptions.Constraints}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.processingConditions}
            setSelectedItems={setSelectedProcessingConditions}
            selectedItems={selectedProcessingConditions}
            emptytext={`No Processing Conditions Found`}
            placeholder={'Pocessing Conditions'}
            title={'Processing Conditions'}
            key={3}
            isLoading={isFetchingProcessingConditions}
            hasMore={
              processingConditionsData?.data?.pagination?.page <
              processingConditionsData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreProcessingConditions}
            searchTerm={pagination.ProcessingConditions.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.ProcessingConditions, value)
            }
            setPage={value =>
              updatePagination(
                PaginationTypes.ProcessingConditions,
                'page',
                value
              )
            }
            refetchIngridientsSearchTerm={refetchProcessingConditions}
            type={DropDownOptions.ProcessingConditions}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.ingredientComposition}
            setSelectedItems={setSelectedIngredientComposition}
            selectedItems={selectedIngredientComposition}
            emptytext={`No Ingredient Composition Found`}
            placeholder={'Ingredient Composition'}
            title={'Ingredient Composition'}
            key={4}
            isLoading={isFetchingIngredientComposition}
            hasMore={
              ingredientCompositionData?.data?.pagination?.page <
              ingredientCompositionData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreIngredientComposition}
            searchTerm={pagination.Compositions.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Compositions, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Compositions, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchIngredientComposition}
            type={DropDownOptions.Compositions}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.ingredientGroup}
            setSelectedItems={setSelectedIngredientGroup}
            selectedItems={selectedIngredientGroup}
            emptytext={`No Ingredient Group Found`}
            placeholder={'Ingredient Group'}
            title={'Ingredient Group'}
            key={5}
            isLoading={isFetchingIngredientGroup}
            hasMore={
              ingredientGroupData?.data?.pagination?.page <
              ingredientGroupData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreIngredientGroup}
            searchTerm={pagination.Groups.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Groups, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Groups, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchIngredientGroup}
            type={DropDownOptions.Groups}
          />
        </Space>
      }
      trigger="click"
      placement="left"
      open={visible}
      onOpenChange={open => setVisible(open)}
    >
      <Button
        type="primary"
        style={{
          position: 'fixed',
          right: 0,
          top: 200,
          zIndex: 1000,
          opacity: 0.8,
        }}
      >
        Library
      </Button>
    </Popover>
  );
};

export default Library;

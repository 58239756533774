import { BaseClient } from '../ApiClient';
import {
  IProjectCreateOut,
  IProjectEnableGoalSim,
} from '../interfaces/project.interfaces';
import { IFormulationItemRaw, IFormulationRaw } from '../interfaces/formulationRaw.interfaces';
import { ProjectFeature } from '@prisma/client';
import { ProjectFeatures } from '__generated__/globalTypes';

const ENDPOINTS = {
  postCreateProjectUrl: (id: string) => `org/${id}/project`,
  postFinishSetuprojectUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/finish`,
  postUploadRawFormulationsUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/uploadRawData`,
  putUpdateRawFormulationItemUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/rawFormulationItem`,
  postConfirmLargeFileUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/largeFileConfirmation`,
  deleteRawFormulationsUrl: (
    id: string,
    projectId: string,
    formulationId: string
  ) => `org/${id}/project/${projectId}/delete/formulation/${formulationId}`,
  enableProjectFeature: (
    id: string,
    projectId: string,
    feature: ProjectFeature
  ) => `org/${id}/project/${projectId}/enable/feature/${feature}`,
  getProjectByIdUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}`,
  getProjectFormulationsRawUrl: (
    id: string,
    projectId: string,
    page: number,
    pageSize: number
  ) => `org/${id}/project/${projectId}/formulationsRaw/${page}/${pageSize}`,
  getProjectDuplicate: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/duplicate`,
  getProjectSummary: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/summary`,
  postClearDetails: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/clearDetails`,
  getProjectGoalSimulatorStatusUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/enableGoalSimulator`,
  getEventHistory: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/history`,
  putProjectFormulationsRawUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/formulationsRaw`,
  getProjectShared: (id: string, userId: string) =>
    `org/${id}/project/${userId}/projectShared`,
};

const postCreateProject = (data: IProjectCreateOut): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postCreateProjectUrl(data.organizationId),
    data
  );
};

const postFinishSetupProject = (data: IProjectCreateOut): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postFinishSetuprojectUrl(
      data.organizationId,
      data.id ? data.id : ''
    ),
    data
  );
};

const postUploadRawFormulations = (data: IFormulationRaw): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postUploadRawFormulationsUrl(data.orgId, data.projectId),
    data
  );
};

const putUpdateRawFormulationItem = (data: IFormulationItemRaw): Promise<any> => {
  return BaseClient.put(
    ENDPOINTS.putUpdateRawFormulationItemUrl(data.orgId, data.projectId),
    data)
}
const postConfirmLargeFile = (data: {
  orgId: string;
  projectId: string;
  fileId: string;
  confirmed: boolean;
}): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postConfirmLargeFileUrl(data.orgId, data.projectId),
    { fileId: data.fileId, confirmed: data.confirmed }
  );
};

const deleteRawFormulations = (data: IFormulationRaw): Promise<any> => {
  return BaseClient.delete(
    ENDPOINTS.deleteRawFormulationsUrl(
      data.orgId,
      data.projectId,
      data.formulationId!
    ),
    data
  );
};
const getProjectById = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectByIdUrl(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const getProjectFromulationRaw = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectFormulationsRawUrl(
      parameters.queryKey[1],
      parameters.queryKey[2],
      parameters.queryKey[3],
      parameters.queryKey[4]
    )
  );
};

const putProjectFromulationRaw = (data: IFormulationRaw): Promise<any> => {
  return BaseClient.put(
    ENDPOINTS.putProjectFormulationsRawUrl(data.orgId, data.projectId),
    {
      formialtionId: data.formulationId,
      type: data.type,
      cost: data.cost
    }
  );
};

const postDuplicateProject = (data: any): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.getProjectDuplicate(data.orgId, data.projectId),
    {
      userId: data.user.id,
      duplicateWithModel: data.duplicateWithModel,
    }
  );
};

const getProjectSummary = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectSummary(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const getProjectGoalSimulatorStatus = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectGoalSimulatorStatusUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const postProjectEnableGoalSimulator = (
  data: IProjectEnableGoalSim
): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.getProjectGoalSimulatorStatusUrl(
      data.organizationId,
      data.projectId
    ),
    { enableGoalSimulator: data.enableGoalSimulator }
  );
};

// const postClearData = (data: any): Promise<any> => {
//   return BaseClient.post(
//     ENDPOINTS.postClearDetails(data.orgId,
//       data.projectId), {})
// }
const postClearData = (data: any): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postClearDetails(data.orgId, data.projectId),
    {}
  );
};

const getEventoHistory = (data: any) => {
  console.log('EVENTHISTORU', data);
  return BaseClient.get(
    ENDPOINTS.getEventHistory(data.queryKey[1], data.queryKey[2])
  );
};

const getProjectShared = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectShared(
      parameters.queryKey[1], parameters.queryKey[2]
    ),
  );
};

const enableProjectFeature = (data: { orgId: string, projectId: string, feature: ProjectFeature }): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.enableProjectFeature(data.orgId, data.projectId, data.feature)
  )
}

export const ProjectRepository = {
  postCreateProject,
  getProjectById,
  getProjectFromulationRaw,
  postFinishSetupProject,
  postUploadRawFormulations,
  deleteRawFormulations,
  putUpdateRawFormulationItem,
  postDuplicateProject,
  getProjectSummary,
  getProjectGoalSimulatorStatus,
  postProjectEnableGoalSimulator,
  postClearData,
  getEventoHistory,
  getProjectShared,
  postConfirmLargeFile,
  putProjectFromulationRaw,
  enableProjectFeature
};

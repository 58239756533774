import { Modal } from "antd";
import React from "react";

interface MissingDataConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  outcomes: string[];
  showMissingDesiribility?: boolean
}

export const MissingDataConfirmationDialog:
  React.FC<MissingDataConfirmationDialogProps> = ({
    onConfirm,
    onCancel,
    outcomes,
    showMissingDesiribility
  }) => {

    return (
      <Modal
        title="Missing Outcome(s) Detected"
        open={true}
        onOk={onConfirm}
        onCancel={onCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>
          The system has detected that some outcomes ({outcomes.join(",")}) are missing or not provided.
        </p>
        {
          showMissingDesiribility && (
            <p>
              This will make it impossible to compute the formulation desirability due to missing outcome values.
            </p>
          )
        }
        <p style={{
          fontWeight: "bold"
        }}
        >Would you like to proceed with this action?</p>
      </Modal>
    );
  }
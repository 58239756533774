import {
  InfoCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { Button, Tooltip, Divider, Alert, Switch } from 'antd';
import { ConstraintRow } from '../new-experiment/constraint-row-component';
import { emptyConstraint } from '../../workspaces/adaptive-learning/design-utils';
import React, { useEffect, useRef, useState } from 'react';
import {
  Constraint,
  ProjectFeature,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import { IngredientSearch } from '../../../_shared/components/input/ingredient-search.component';
import { css } from '@emotion/css';
import { useDesign } from '../../../_shared/context/design-context';
import './exploration-constraint-setting.less';
import { ConstraintCostOptimizationOptions } from './constraint-cost-optimization-options';
import { useFormulations } from '../../../_shared/context/formulations-context';
import { useEnableProjectFeature } from '../../../network/services/project.service';
import { ConfirmationModal } from '../../../_shared/components/confirmationModal/confirmModal';

export const ExplorationConstraintSetting = () => {
  const {
    constraints,
    enforceStrictly,
    setEnforceStrictly,
    nteCost,
    costOptimizationOption,
    fillerIngredient,
    setFillerIngredient,
    disableGoalScenario,
    hasNewConstraints,
  } = useScenarioDetail();
  const {
    runQuickDesign,
    designState,
    resetToLatestObjectives,
    quickDesignIsRunning,
    latestDesign,
    previousDesigns,
  } = useDesign();

  const enableProjectFeature = useEnableProjectFeature();

  const { formulationsFetching } = useFormulations();

  const { currentProject, hasFeature } = useSession();
  const [showNewConstraint, setShowNewConstraint] = useState(false);
  const [UIChangeModal, setUIChangeModal] = useState<boolean>();

  const newConstraintRef = useRef<HTMLDivElement>(null);
  const handleAddNewConstraint = () => {
    setShowNewConstraint(true);
  };

  useEffect(() => {
    setShowNewConstraint(false);
  }, [constraints]);

  useEffect(() => {
    if (showNewConstraint) {
      newConstraintRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [showNewConstraint]);


  const handleUIChange = () => {
    if (currentProject)
      enableProjectFeature.mutate(
        {
          orgId: currentProject?.organizationId,
          projectId: currentProject?.id,
          feature: ProjectFeature.CONSTRAINTS_V2
        },
        {
          onSuccess: async res => {
            window.location.reload();
          }
        }
      )
  }

  const showConstraintsNudge =
    hasNewConstraints &&
    constraints.length >= 2 &&
    (previousDesigns.length === 1 ||
      (previousDesigns.length === 0 && latestDesign));

  return (<>
    <div id="constraint-settings-container" style={{ maxWidth: '100%' }}>
      {currentProject?.canEnableConstraintsV2 && <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>New UI (beta)</div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Switch
              checked={UIChangeModal}
              onChange={(checked, e) => {
                setUIChangeModal(true);
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
      }
      {hasFeature(ProjectFeature.PRICING) && costOptimizationOption && (
        <div>
          <ConstraintCostOptimizationOptions />
        </div>
      )}
      <div
        css={css`
          padding-right: 20px;
          padding-bottom: 30px;
        `}
      >
        <Tooltip
          placement="topLeft"
          mouseEnterDelay={0.3}
          title="The name of the filler ingredient"
        >
          <div style={{ marginBottom: 5, marginTop: 10 }}>
            Filler Ingredient <InfoCircleTwoTone />
          </div>
        </Tooltip>
        {currentProject?.ingredientList && (
          <IngredientSearch
            isDisabled={disableGoalScenario}
            additionalCss={css`
              margin: unset;
            `}
            className="constraint-select"
            ingredients={currentProject?.ingredientList}
            defaultValue={fillerIngredient}
            allowClear
            onClear={() => setFillerIngredient(undefined)}
            onSelect={setFillerIngredient}
          />
        )}
      </div>
      <Divider />
      {showConstraintsNudge && !formulationsFetching && !quickDesignIsRunning && (
        <Alert
          style={{ marginBottom: 15 }}
          message="Great start! Let's see what innovative formulations
                    our AI can discover with this constraint. Remember,
                    each additional constraint narrows the
                    possibilities. Why not explore some initial
                    recommendations before adding more?"
          type="warning"
        />
      )}
      <div style={{ fontWeight: 500 }}>Constraints</div>

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 5,
          fontSize: 12,
        }}
      >
        <div>
          Enforce Constraints Strictly <InfoCircleOutlined />{' '}
        </div>
        <div>
          <Switch
            size={'small'}
            checked={enforceStrictly}
            onChange={e => setEnforceStrictly(e)}
          />
        </div>
      </div> */}
      {constraints.map((c, index) => (
        <ConstraintRow key={c.id} constraint={c as Constraint} index={index} />
      ))}

      {showNewConstraint && (
        <>
          <ConstraintRow
            constraint={emptyConstraint}
            index={constraints.length === 0 ? 0 : 1}
            defaultShowBody={true}
            onCancel={e => setShowNewConstraint(false)}
          />
        </>
      )}

      <Button icon={<PlusOutlined />} onClick={handleAddNewConstraint}>
        Add Constraint
      </Button>
      <span ref={newConstraintRef}></span>

    </div>
    {UIChangeModal &&
      <ConfirmationModal
        title='Are you sure you want to turn on the new Constraints UI for this project?'
        description="Once you go to new UI, you won't be able to disable it"
        onClose={() => setUIChangeModal(false)}
        onConfirm={() => handleUIChange()}
      />
    }
  </>
  );
};

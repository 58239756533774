import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Dropdown, Input, Spin } from 'antd';
import { debounce } from 'lodash';
import { DragOverlay } from '@dnd-kit/core';
import { DropDownOptions } from '../../../components/library/library';
import './searchable-dropdown-drag-and-drop.less';
import DraggableItem from './draggable-item/draggable-item';
import { useDragDropContext } from '../../../_shared/context/drag-context';

type IProps = {
  listItems: { name: string; category?: string }[];
  setSelectedItems: any;
  selectedItems: any;
  title: string;
  placeholder: string;
  emptytext: string;
  isLoading: boolean;
  refetchIngridients: () => void;
  hasMore: boolean;
  searchTerm: string;
  setSearchTerm: (item: string) => void;
  setPage: (item: number) => void;
  refetchIngridientsSearchTerm: () => void;
  type: DropDownOptions;
};

const SearchableDropdownDragAndDrop = ({
  listItems,
  refetchIngridients,
  emptytext,
  placeholder,
  title,
  isLoading,
  searchTerm,
  setSearchTerm,
  setPage,
  refetchIngridientsSearchTerm,
  type,
}: IProps) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { draggedItemId } = useDragDropContext();

  const fetchMore = () => {
    refetchIngridients();
  };

  const debouncedSearch = useCallback(
    debounce(term => {
      setPage(1);
      refetchIngridientsSearchTerm();
    }, 500),
    []
  );

  useEffect(() => {
    if (searchTerm) debouncedSearch(searchTerm);
  }, [searchTerm]);

  const toggleItemSelection = (item: string) => {
    setSelectedItems(prev => {
      const updatedSelection = prev.includes('All')
        ? [item]
        : prev.includes(item)
        ? prev.filter(selected => selected !== item)
        : [...prev, item];

      return updatedSelection;
    });
    setSearchTerm('');
  };

  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            console.log('Fetching more data...');
            fetchMore();
          }
        },
        { threshold: 1 }
      );

      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  return (
    <Dropdown
      overlay={
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            width: 300,
          }}
        >
          <div
            style={{
              padding: '10px',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <Input
              placeholder={placeholder}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <div
            style={{
              maxHeight: 300,
              overflowY: 'auto',
              padding: '10px',
            }}
          >
            {listItems?.length ? (
              listItems.map((item, index) => (
                <DraggableItem
                  key={item.name}
                  item={item}
                  index={index}
                  lastItemRef={lastItemRef}
                  listItems={listItems}
                  activeId={null}
                />
              ))
            ) : !isLoading ? (
              <div style={{ color: 'gray' }}>{emptytext}</div>
            ) : (
              <Spin
                style={{ display: 'block', textAlign: 'center', marginTop: 10 }}
              />
            )}
            {/* <DragOverlay>
              {draggedItemId ? <DraggableItem item={listItems.find(i => i.id === draggedItemId)} index={0} listItems={[]} activeId={draggedItemId} lastItemRef={() => { }} /> : null}
            </DragOverlay> */}
          </div>
        </div>
      }
      trigger={['click']}
      overlayClassName="custom-ropdown"
    >
      <Button style={{ marginLeft: 10 }}>
        {title}{' '}
        <span style={{ color: '#1890ff', marginLeft: 10 }}>
          {selectedItems.length && selectedItems[0] !== 'All'
            ? selectedItems.length
            : ''}
        </span>
      </Button>
    </Dropdown>
  );
};

export default SearchableDropdownDragAndDrop;

import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  Dispatch,
  useState,
} from 'react';
import {
  formulationDetailQuery,
  FormulationItemType,
  formulationsListQuery,
  formulationsListQueryVariables,
  useformulationsListLazyQuery,
} from '../../../../__generated__/globalTypes';
import { captureException } from '@sentry/react';
import { BaseProject, useIngredients } from '../hooks';
import { calculateFormulationCostV2 } from '../utils/util';
import { FormulationSource } from '@prisma/client';

const DEFAULT_PAGE_SIZE = 100;

export type FormulationType = formulationsListQuery['formulationsList']['formulations'][0];
export type PageInfoType = formulationsListQuery['formulationsList']['pageInfo'];
export type FormulationWithDesignType = formulationDetailQuery['formulation'];

type FormulationsContextProps = {
  projectFormulations: FormulationType[];
  formulationsFetching: boolean;
  refreshDesignFormulations: (designId: string) => void;
  projectBenchmarkFormulation?: FormulationType;
  currentPageInfo?: PageInfoType;
  refreshFeedbackFormulations: () => void;
  refreshTestedFormulations: (callback: () => void) => void;
  setUnroundedFormulations: (formulations: FormulationType[]) => void;
  unroundedFormulations: FormulationType[];
  minTestedFormulationsCostScore: number | undefined;
  maxTestedFormulationsCostScore: number | undefined;
};

export enum FormulationReducerActionType {
  INTIALIZED = 'INTIALIZED',
  ADDED = 'ADDED',
  CHANGED = 'CHANGED',
  DELETED = 'DELETED',
}

type FromulationReducerActionForMany = Extract<
  FormulationReducerActionType,
  FormulationReducerActionType.INTIALIZED | FormulationReducerActionType.ADDED
>;

type FormulationReducerAction =
  | {
    type: FromulationReducerActionForMany;
    formulations: FormulationType[];
  }
  | {
    type: Exclude<
      FormulationReducerActionType,
      FromulationReducerActionForMany
    >;
    formulation: FormulationType;
  };

export const roundFormulations = (
  formulations: FormulationType[],
  precision?: number | null
) => {

  // const formulationWithMissingOutcomes = formulations.reduce((acc: any[], f) => {

  //   if (f.items.some(fi => fi.value === '')) {
  //     acc.push(f)
  //   }

  //   return acc
  // }, [])

  // console.log("reducerFormPres", formulationWithMissingOutcomes)

  const roundingPrecision =
    precision !== undefined && precision !== null ? precision : 3;
  formulations = formulations.filter(f => !f.campaign?.isSoftDeleted);
  return formulations.map(formulation => {
    const roundedItems = formulation.items.map(item => {

      if (item.value === '') {

        item.value = 'Missing'

      }

      const numberValue = isNaN(Number(item.value))
        ? item.value
        : Number(item.value);

      return {
        ...item,
        value:
          typeof numberValue === 'number'
            ? numberValue.toFixed(roundingPrecision)
            : item.value,
      };
    });

    return {
      ...formulation,
      items: roundedItems,
    };
  });
};

const FormulationsContext = createContext<FormulationsContextProps>({
  projectFormulations: [],
  unroundedFormulations: [],
  setUnroundedFormulations: () => { },
  formulationsFetching: true,
  refreshDesignFormulations: () => { },
  refreshFeedbackFormulations: () => { },
  refreshTestedFormulations: (callbcak: () => void) => { },
  minTestedFormulationsCostScore: undefined,
  maxTestedFormulationsCostScore: undefined,
});

const FormulationsDispatchContext = createContext(
  (() => undefined) as Dispatch<FormulationReducerAction>
);

export const FormulationsContextProvider = ({
  currentProject,
  children,
}: {
  currentProject: BaseProject;
  children?: React.ReactNode;
}) => {
  const [formulations, dispatch] = useReducer(formulationsReducer, []);
  const [
    minTestedFormulationsCostScore,
    setMinTestedFormulationsCostScore,
  ] = useState<number | undefined>(undefined);
  const [
    maxTestedFormulationsCostScore,
    setMaxTestedFormulationsCostScore,
  ] = useState<number | undefined>(undefined);
  const [unroundedFormulations, setUnroundedFormulations] = useState<
    FormulationType[]
  >([]);
  const [
    projectBenchmarkFormulation,
    setProjectBenchmarkFormulation,
  ] = useState<FormulationType>();
  const [
    fetchFormulations,
    { loading: formulationsFetching },
  ] = useformulationsListLazyQuery();
  const { ingredients } = useIngredients();

  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfoType>();

  const doFetchFormulations = (
    variables: formulationsListQueryVariables,
    prevFormulations: FormulationType[] = [],
    callback?: () => void
  ) => {
    fetchFormulations({
      fetchPolicy: 'network-only',
      variables,
      onCompleted: (res: formulationsListQuery) => {

        const formulationWithMissingOutcomes = res.formulationsList.formulations.reduce((acc: any[], f) => {

          if (f.items.some(fi => fi.value === '')) {
            acc.push(f)
          }

          return acc
        }, [])

        dispatch({
          type: FormulationReducerActionType.ADDED,
          formulations: res.formulationsList.formulations,
        });

        prevFormulations = [
          ...prevFormulations,
          ...res.formulationsList.formulations,
        ];
        // setUnroundedFormulations(curUnroundedFormulations => [
        //   ...curUnroundedFormulations,
        //   ...res.formulationsList.formulations,
        // ]);

        if (res.formulationsList.pageInfo.hasNextPage) {
          doFetchFormulations(
            {
              ...variables,
              skip: variables.skip + DEFAULT_PAGE_SIZE,
            },
            prevFormulations,
            callback
          );
        } else {
          if (callback) callback();
          setUnroundedFormulations(prevFormulations);
        }
      },
      onError: error => {
        console.log('Error: Unable to fetch formulations');
        captureException(error);
      },
    });
  };

  useEffect(() => {
    let minTestedCost = minTestedFormulationsCostScore
      ? minTestedFormulationsCostScore
      : 99999999999;
    let maxTestedCost = maxTestedFormulationsCostScore
      ? maxTestedFormulationsCostScore
      : 0;
    for (let f of formulations) {
      if (f.isMeasured || f.source === FormulationSource.EXISTING) {
        let formulationIngredientItems = f.items
          .filter(item => item.type === FormulationItemType.INPUT)
          .map(item => ({
            value: Number(item.value),
            name: item.variable.name,
          }));
        let formulationCost = Number(
          calculateFormulationCostV2(formulationIngredientItems, ingredients)
        );
        if (formulationCost < minTestedCost) {
          minTestedCost = formulationCost;
        }
        if (formulationCost > maxTestedCost) {
          maxTestedCost = formulationCost;
        }
      }
    }
    setMinTestedFormulationsCostScore(minTestedCost);
    setMaxTestedFormulationsCostScore(maxTestedCost);
  }, [formulations]);

  useEffect(() => {
    const benchmarkFormulation = formulations.find(
      formulation => formulation.isBenchmark
    );

    setProjectBenchmarkFormulation(benchmarkFormulation);
  }, [formulations]);

  const refreshDesignFormulations = (designId: string) => {
    doFetchFormulations({
      projectId: currentProject.id,
      designId,
      take: DEFAULT_PAGE_SIZE,
      skip: 0,
    });
  };

  const refreshFeedbackFormulations = () => {
    doFetchFormulations({
      projectId: currentProject.id,
      take: DEFAULT_PAGE_SIZE,
      skip: 0,
    });

    return () =>
      dispatch({
        type: FormulationReducerActionType.INTIALIZED,
        formulations: [],
      });
  };

  const refreshTestedFormulations = (callback: () => void) => {
    doFetchFormulations(
      {
        projectId: currentProject.id,
        take: DEFAULT_PAGE_SIZE,
        skip: 0,
      },
      [],
      callback
    );

    return () =>
      dispatch({
        type: FormulationReducerActionType.INTIALIZED,
        formulations: [],
      });
  };

  useEffect(() => {
    doFetchFormulations({
      projectId: currentProject.id,
      take: DEFAULT_PAGE_SIZE,
      skip: 0,
    });

    return () =>
      dispatch({
        type: FormulationReducerActionType.INTIALIZED,
        formulations: [],
      });
  }, [currentProject?.id]);

  function formulationsReducer(
    formulations: FormulationType[],
    action: FormulationReducerAction
  ) {
    switch (action.type) {
      case FormulationReducerActionType.INTIALIZED: {
        return roundFormulations(
          action.formulations,
          currentProject.valuePrecision
        );
      }
      case FormulationReducerActionType.ADDED: {
        const formulationIdsToRemove = action.formulations.map(f => f.id);
        const filteredFormulations = formulations.filter(
          f => !formulationIdsToRemove.includes(f.id)
        );

        return roundFormulations(
          [...filteredFormulations, ...action.formulations],
          currentProject.valuePrecision
        );
      }
      case FormulationReducerActionType.CHANGED: {
        const changedFormulations = formulations.map(f => {
          if (f.id === action.formulation.id) {
            return action.formulation;
          } else {
            return f;
          }
        });

        return roundFormulations(
          changedFormulations,
          currentProject.valuePrecision
        );
      }
      case FormulationReducerActionType.DELETED: {
        return formulations.filter(f => f.id !== action.formulation.id);
      }
    }
  }

  return (
    <FormulationsContext.Provider
      value={{
        projectFormulations: formulations,
        formulationsFetching,
        refreshDesignFormulations,
        projectBenchmarkFormulation,
        currentPageInfo,
        refreshFeedbackFormulations,
        setUnroundedFormulations,
        unroundedFormulations,
        refreshTestedFormulations,
        minTestedFormulationsCostScore,
        maxTestedFormulationsCostScore,
      }}
    >
      <FormulationsDispatchContext.Provider value={dispatch}>
        {children}
      </FormulationsDispatchContext.Provider>
    </FormulationsContext.Provider>
  );
};

export const useFormulations = () => useContext(FormulationsContext);
export const useFormulationsDispatch = () =>
  useContext(FormulationsDispatchContext);

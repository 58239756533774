import { RawDataContextProvider } from '../../../../_shared/context/raw-data-context';
import React from 'react';
import { ProjectRawDataAggregatedTable } from './project-raw-data-aggregated-table/project-raw-data-aggregated-table';

export const ProjectRawDataAggregate = ({
  enableNextStep,
  projectId,
  projectHasData,
  edit,
  setNoDataFlowStarted,
  saveAllInputsAndOutcomesOnEdit,
  setSaveAllInputsAndOutcomesOnEdit
}: {
  enableNextStep: (value: boolean) => void;
  projectId?: string;
  projectHasData?: boolean;
  edit?: boolean;
  setNoDataFlowStarted: (value: boolean) => void;
  saveAllInputsAndOutcomesOnEdit: boolean;
  setSaveAllInputsAndOutcomesOnEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  return (
    <ProjectRawDataAggregatedTable
      edit={edit}
      projectHasData={projectHasData}
      enableNextStep={enableNextStep}
      proj_id={projectId}
      setNoDataFlowStarted={setNoDataFlowStarted}
      saveAllInputsAndOutcomesOnEdit={saveAllInputsAndOutcomesOnEdit}
      setSaveAllInputsAndOutcomesOnEdit={setSaveAllInputsAndOutcomesOnEdit}
    />
  );
};

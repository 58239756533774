const ENDPOINTS = {
  getObjectiveByOrg: (org_id: string) => `org/${org_id}/objective`,
};

import { BaseClient } from '../ApiClient';

const getObjectiveByOrg = ({ queryKey }: any) => {
  const [, organizationId, page, limit, searchTerm] = queryKey;
  return BaseClient.get(ENDPOINTS.getObjectiveByOrg(organizationId), {
    params: { page, limit, searchTerm: searchTerm || '' },
  });
};

export const ObjectiveRepository = {
  getObjectiveByOrg,
};

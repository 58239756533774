/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Alert, Divider, Select, TableColumnsType, Tag, Tooltip } from 'antd';
import { css } from '@emotion/react';
import './project-goal-management-dashboard.less';
import { ProjectGoalCardNumber } from './goal-card-number/goal-card-number';
import { ProjectGoalFormulationRadar } from './goal-formulation-radar/goal-formulation-radar';
import {
  useGetForecastGoalSimulation,
  useGetFormulationGoal,
  useGetGoalsProgressDesirability,
  useGetGoalsProgressDesirabilityOutcome,
  useGetGoalsProjectDashboard,
} from '../../network/services/goal.service';
import { useSession } from '../../_shared/context';
import _ from 'lodash';
import { TableDesirabilityFormulation } from './table-desirability-formulation-generic/table-desirability-formulation';
import { TableDesirabilityOutcomeFormulation } from './table-desirability-outcome-formulation/table-desirability-outcome-formulation';
import { useGetFormulations } from '../../network/services/formulation.service';
import { TableDesairabilityScore } from './table-desirability-score/table-desirability-score';
import { InfoCircleOutlined } from '@ant-design/icons';
import { GoalTable } from './goal-table/goal-table';
import { useScenarioDetail } from '../../_shared/context/scenario-detail-context';
import { FormulationSelected } from './formulation-item/formulation-item';
import { FormulationType } from '../../_shared/context/formulations-context';
import { useTranslation } from 'react-i18next';

const ANALYTICAL = 'Analytical';
const CONSUMER = 'Consumer';
const SENSORY = 'Sensory';

type Item = {
  name: string;
  items: {
    subject: string;
    value: string;
  };
  cost: number;
  totalDesirability: number;
};

type FilteredItem = {
  name: string;
  items: { subject: string; value: string }[];
  maxValue: number;
  cost: number;
  totalDesirability: number;
};

export const ProjectGoalManagementDashboard = () => {
  const { t } = useTranslation();
  const { currentProject, user } = useSession();
  const { enabledGoalSimulator } = useScenarioDetail();
  const [analyticalData, setAnalyticalData] = useState<any>([]);
  const [sensoryData, setSensoryData] = useState<any>([]);
  const [consumerData, setConsumerData] = useState<any>([]);
  const [analyticalFilteredData, setAnalyticalFilteredData] = useState<any>([]);
  const [sensoryFilteredData, setSensoryFilteredData] = useState<any>([]);
  const [consumerFilteredData, setConsumerFilteredData] = useState<any>([]);
  const [legendSelectedData, setLegendSelectedData] = useState<any>([]);
  const [cards, setCards] = useState<{ name: string; number: Number }[]>([]);
  const [selectedDefaultValues, setSelectedDefaultValues] = useState([]);
  const [showFormSelected, setShowFormSelected] = useState<boolean>(false);
  const [formulationRightPanel, setFormulationRightPanel] = useState<FormulationType>();
  const [formulationSelectedId, setFormulationSelectedId] = useState<string>();


  const { data: formulation, isSuccess, refetch: getFormulation, isLoading, } = useGetFormulationGoal({
    projectId: currentProject?.id,
    organizationId: currentProject?.organizationId,
    formId: formulationSelectedId
  });

  const { data: formulationResult } = useGetFormulations(
    {
      organizationId: currentProject?.organizationId,
      projectId: currentProject?.id,
    },
    false
  );
  const { data: goalDesirability } = useGetGoalsProgressDesirability({
    organizationId: user?.organizationId,
    projectId: currentProject?.id,
  });
  const {
    data: goalOutcomeDesirability,
  } = useGetGoalsProgressDesirabilityOutcome({
    organizationId: user?.organizationId,
    projectId: currentProject?.id,
  });
  const { data: goalCards } = useGetGoalsProjectDashboard({
    organizationId: user?.organizationId,
    projectId: currentProject?.id,
  });
  const { data: goalSim } = useGetForecastGoalSimulation({
    organizationId: currentProject?.organizationId,
    projectId: currentProject?.id,
    enableGoalSim: enabledGoalSimulator.enabled
  })

  const separateOutcomeCategories = (formulations: any[]) => {
    const analyticalData: any = [];
    const consumerData: any = [];
    const sensoryData: any = [];

    formulations.forEach(formulation => {
      const key = formulation.key;

      formulation.items.forEach((item: any) => {
        const outcomeCategoryName =
          item.variable.outcomeMetadata.OutcomeCategory.name;
        const desirability = item.desirability ? item.desirability[0].desirability : 0

        if (outcomeCategoryName === ANALYTICAL) {
          analyticalData.push({
            name: key,
            items: { subject: item.variable.name, value: item.value, desirability: desirability },
            cost: formulation.totalCostScore,
            totalDesirability: formulation.totalDesirability,
          });
        }
        if (outcomeCategoryName === CONSUMER) {
          consumerData.push({
            name: key,
            items: { subject: item.variable.name, value: item.value, desirability: desirability },
            cost: formulation.totalCostScore,
            totalDesirability: formulation.totalDesirability,
          });
        }
        if (outcomeCategoryName === SENSORY) {
          sensoryData.push({
            name: key,
            items: { subject: item.variable.name, value: item.value, desirability: desirability },
            cost: formulation.totalCostScore,
            totalDesirability: formulation.totalDesirability,
          });
        }
      });
    });

    setAnalyticalData(analyticalData);
    setConsumerData(consumerData);
    setSensoryData(sensoryData);
  };

  useEffect(() => {
    if (formulationResult) {
      separateOutcomeCategories(formulationResult?.data);
      setDefaultFormulations();
    }
  }, [formulationResult]);

  useEffect(() => {
    if (goalCards && goalCards.status) {
      const rounds = {
        name: 'Rounds Completed',
        number: goalCards.data.roundsCompleted,
      };
      const experiments = {
        name: 'Suggestions generated',
        number: goalCards.data.experiments,
      };
      setCards([rounds, experiments]);
    }
  }, [goalCards]);

  const getLightColor = (index: number) => {
    const hue = (index * 137.508) % 360;
    const saturation = 70 + Math.random() * 20;
    const lightness = 40 + Math.random() * 10;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const handleSelectChange = (values: any) => {
    !values.length && setShowFormSelected(false);

    setAnalyticalFilteredData(filterByNamesWithMax(analyticalData, values));
    setSensoryFilteredData(filterByNamesWithMax(sensoryData, values));
    setConsumerFilteredData(filterByNamesWithMax(consumerData, values));
    setLegendSelectedData(values);
  };

  const filterByNamesWithMax = (
    data: Item[],
    namesToFilter: string[]
  ): FilteredItem[] => {
    const results: FilteredItem[] = [];

    namesToFilter.forEach(nameToFilter => {
      const filteredItems = data.filter(item => item.name === nameToFilter);

      if (filteredItems.length > 0) {
        const maxValue = filteredItems.reduce((max, item) => {
          const value = parseFloat(item.items.value);
          return value > max ? value : max;
        }, 0);

        results.push({
          name: nameToFilter,
          items: filteredItems.map(item => item.items),
          maxValue,
          cost: filteredItems[0]?.cost,
          totalDesirability: filteredItems[0].totalDesirability,
        });
      }
    });

    return results;
  };

  const scrollToBottom = () => {
    const containerElement = document.getElementsByClassName(
      'css-1u92mjq-DashboardLayout'
    )[0];
    if (containerElement) {
      containerElement.scrollTop = containerElement.scrollHeight;
    }
  };

  useLayoutEffect(() => {
    if (
      analyticalFilteredData.length > 0 ||
      sensoryFilteredData.length > 0 ||
      consumerFilteredData.length > 0
    ) {
      scrollToBottom();

    }
  }, [analyticalFilteredData, sensoryFilteredData, consumerFilteredData]);

  const setDefaultFormulations = () => {
    let array: any = []
    for (let i = 0; i < 5 && i < formulationResult.data.length; i++) {
      array.push(formulationResult.data[i].key)
    };
    setSelectedDefaultValues(array)
  }

  useEffect(() => {
    if (analyticalData.length || sensoryData.length || consumerData.length) {
      handleSelectChange(selectedDefaultValues)
    }
  }, [analyticalData, sensoryData, consumerData])

  const handleRightPanel = (item: any) => {
    setFormulationSelectedId(formulationResult?.data.filter((x: any) => x.key === item)[0].id)
    getFormulation()
    setShowFormSelected(true)
  }

  useEffect(() => {
    if (formulation)
      setFormulationRightPanel(formulation.data)
  }, [formulation])

  useEffect(() => {
    if (showFormSelected)
      scrollToBottom()
  }, [showFormSelected])

  const CustomLegend = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}
      >
        {legendSelectedData.map((item: any, index: number) => {
          return (
            <div style={{ marginRight: '10px', color: getLightColor(index), cursor: 'pointer' }} onClick={() => handleRightPanel(item)}>
              <span
                style={{
                  marginRight: '5px',
                  backgroundColor: getLightColor(index),
                  padding: '3px 5px',
                }}

              ></span>
              {item}
              (
              {Number(
                formulationResult.data.find(
                  (form: any) => form.key === String(item)
                )?.totalCostScore
              ).toFixed(3)}
              )
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      id="goal-management"
      css={css`
        padding: 20px 60px 15px;
      `}
    >
      <div className="container">
        <div className="experiments-row">
          {cards.map(c => {
            return <ProjectGoalCardNumber card={c} />;
          })}
        </div>
        <div className="formulation-progress-row">
          <Divider>Attribute Performance</Divider>
          {!enabledGoalSimulator.enabled && <Alert message={t('execute-solution.page.projectGoalManagerTab.messages.enableGoalSimulation')} type="warning" />}
          {enabledGoalSimulator.enabled && enabledGoalSimulator.missingFormulations && <Alert message={enabledGoalSimulator.message} type="warning" />}
          <TableDesirabilityFormulation goalDesirability={goalDesirability} goalSimulator={goalSim} perOutcome={false} title={t('execute-solution.page.projectGoalManagerTab.page.tableBestFormulationPerRoundTitle')} />
          <TableDesirabilityFormulation goalDesirability={goalOutcomeDesirability} goalSimulator={goalSim} perOutcome={true} title={t('execute-solution.page.projectGoalManagerTab.page.tableBestOutcomePerRoundTitle')} />
          {/* <TableDesirabilityOutcomeFormulation
            goalOutcomeDesirability={goalOutcomeDesirability} goalSimulator={goalSim}
          /> */}
        </div>
        <div className="desireability-cost-row">
          <Divider>{t('execute-solution.page.projectGoalManagerTab.page.outcomeStcatterChartTitle')}</Divider>
          <TableDesairabilityScore goalDesirability={goalDesirability} />
        </div>
        <div className="performance-attribute-formulation">
          <Divider>{t('execute-solution.page.projectGoalManagerTab.page.outcomeSpiderChartTitle')}</Divider>
          <div className='outcome-performance-wrapper'>
            <div className='spider-graph'>
              <Tooltip
                placement="top"
                title={
                  <div className="look-formulation">
                    Pick formulations and compare them
                  </div>
                }
              >
                <label>
                  Formulations <InfoCircleOutlined />:{' '}
                </label>
              </Tooltip>

              {selectedDefaultValues.length &&
                <Select
                  mode="multiple"
                  placeholder="Select Fromulation"
                  onChange={handleSelectChange}
                  maxTagCount={1}
                  style={{ marginRight: 3, minHeight: '20px' }}
                  options={formulationResult?.data?.map((item: any) => ({
                    value: item.key,
                    label: item.key,
                  }))}
                  defaultValue={selectedDefaultValues}
                />}
              <CustomLegend />
              <div style={{ display: 'flex' }}>
                <div className="radar-row" style={{ width: 'auto' }}>
                  {analyticalFilteredData.length > 0 && (
                    <div className="radar-row-item">
                      <span>Analytical</span>
                      <ProjectGoalFormulationRadar
                        data={analyticalFilteredData}
                        colorFunction={getLightColor}
                      />
                    </div>
                  )}
                  {sensoryFilteredData.length > 0 && (
                    <div className="radar-row-item">
                      <span>Sensory</span>
                      <ProjectGoalFormulationRadar
                        data={sensoryFilteredData}
                        colorFunction={getLightColor}
                      />
                    </div>
                  )}
                  {consumerFilteredData.length > 0 && (
                    <div className="radar-row-item">
                      <span>Consumer</span>
                      <ProjectGoalFormulationRadar
                        data={consumerFilteredData}
                        colorFunction={getLightColor}
                      />
                    </div>
                  )}
                </div>
                {(showFormSelected && formulationRightPanel) &&
                  <div className="formulation-container" style={{ width: '30%' }}>
                    <FormulationSelected
                      formulation={formulationRightPanel}
                      onHandleFormulation={setShowFormSelected}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { BaseClient } from '../ApiClient';
import { CreateDataSetIn, CreateDataSetOrgData } from '../interfaces/formulationDataSet.interfaces';
import { IProjectCreateOut } from '../interfaces/project.interfaces';

const ENDPOINTS = {
  postCreateDatasetUrl: (orgId: string) => `org/${orgId}/dataset`,
  postCreateProjectFromDatasetUrl: (orgId: string, datasetId: string) => `org/${orgId}/dataset/${datasetId}/project`,
  getDatasetPageUrl: (orgId: string, page: string, pageSize: string) => `org/${orgId}/dataset?page=${page}&pageSize=${pageSize}`,
  getDatasetUrl: (orgId: string, id?: string) => `org/${orgId}/dataset/${id ?? ''}`,
  getDatasetFormulationUrl: (orgId: string, id: string, pageSize: string, page: string) => `org/${orgId}/dataset/${id}/formulations?pageSize=${pageSize}&page=${page}`,
  putEditDatasetUrl: (orgId: string, id: string) => `org/${orgId}/dataset/${id}`,

};

const postCreateDataset = (data: CreateDataSetOrgData): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postCreateDatasetUrl(data.organizationId),
    data
  );
};

const postCreateProjectFromDataset = (data: IProjectCreateOut): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postCreateProjectFromDatasetUrl(data.organizationId, data.datasetId!),
    data
  );
};

const putEditDataset = (data: CreateDataSetOrgData): Promise<any> => {
  return BaseClient.put(
    ENDPOINTS.putEditDatasetUrl(data.organizationId, data.id!),
    data
  );
};

const getDataset = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getDatasetUrl(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const getDatasetFormulations = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getDatasetFormulationUrl(parameters.queryKey[1], parameters.queryKey[2], parameters.queryKey[3], parameters.queryKey[4])
  );
};

export const DatasetRepository = {
  postCreateDataset,
  postCreateProjectFromDataset,
  getDataset,
  getDatasetFormulations,
  putEditDataset
};

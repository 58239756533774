const ENDPOINTS = {
  postCreateOutcomeUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/outcome/`,
  patchCreateOutcomeUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/outcome/`,
  deleteCreateOutcomeUrl: (
    org_id: string,
    proj_id: string,
    outcomeId: string
  ) => `org/${org_id}/project/${proj_id}/outcome/${outcomeId}`,
  getOutcomesByOrg: (org_id: string) => `org/${org_id}/outcome`,
};

import { BaseClient } from '../ApiClient';
import {
  CreateOutcomeOut,
  OutcomeFromServer,
  OutcomeInfo,
} from '../interfaces/outcome.interfaces';

const postCreateOutcome = (data: CreateOutcomeOut) => {
  return BaseClient.post<OutcomeInfo, OutcomeFromServer>(
    ENDPOINTS.postCreateOutcomeUrl(data.organizationId, data.projectId),
    data.outcome
  );
};

const patchCreateOutcome = (data: CreateOutcomeOut) => {
  return BaseClient.patch<OutcomeInfo, OutcomeFromServer>(
    ENDPOINTS.patchCreateOutcomeUrl(data.organizationId, data.projectId),
    data.outcome
  );
};
const deleteCreateOutcome = (data: CreateOutcomeOut) => {
  return BaseClient.delete<OutcomeInfo, OutcomeFromServer>(
    ENDPOINTS.deleteCreateOutcomeUrl(
      data.organizationId,
      data.projectId,
      data.outcome.id!
    )
  );
};

const getOutcomes = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.postCreateOutcomeUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};
const getOutcomesByOrg = ({ queryKey }: any) => {
  const [, organizationId, page, limit, searchTerm] = queryKey;

  return BaseClient.get(ENDPOINTS.getOutcomesByOrg(organizationId), {
    params: { page, limit, searchTerm: searchTerm || '' },
  });
};

export const OutcomeRepository = {
  postCreateOutcome,
  patchCreateOutcome,
  deleteCreateOutcome,
  getOutcomes,
  getOutcomesByOrg,
};

import { useMutation, useQuery } from 'react-query';
import { OutcomeRepository } from '../repositories/outcome.repository';
import { CreateOutcomeOut } from '../interfaces/outcome.interfaces';
import { GetIngredientOut } from '../interfaces/ingredient.interfaces';

export const useCreateOutcome = () => {
  const mutation = useMutation((data: CreateOutcomeOut) => {
    return OutcomeRepository.postCreateOutcome(data);
  });
  return mutation;
};

export const useUpdateOutcome = () => {
  const mutation = useMutation((data: CreateOutcomeOut) => {
    return OutcomeRepository.patchCreateOutcome(data);
  });
  return mutation;
};

export const useDeleteOutcome = () => {
  const mutation = useMutation((data: CreateOutcomeOut) => {
    return OutcomeRepository.deleteCreateOutcome(data);
  });
  return mutation;
};

export const useGetOutcome = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getOutcomeQuery`, data.organizationId, data.projectId],
    OutcomeRepository.getOutcomes,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};
export const useGetOutcomesByOrg = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getOutcomesByOrg`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
    ],
    OutcomeRepository.getOutcomesByOrg,
    {
      enabled: !!data.organizationId, // Or any other condition to enable the query
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

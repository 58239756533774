import React, { useState } from 'react';
import { Button, Dropdown, Input, Radio } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

type IProps = {
  listItems: string[];
  setSelectedItems: (item: string[]) => {};
  selectedItems: any;
  title: string;
  placeholder: string;
  emptytext: string;
  icon: boolean;
};

const SearchableDropdown = ({
  listItems,
  setSelectedItems,
  selectedItems,
  emptytext,
  placeholder,
  title,
  icon = false,
}: IProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  let options: any = [];
  if (listItems) options = ['All', ...listItems];

  const filteredProducts = options.filter(item =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleItemSelection = (item: string) => {
    if (item === 'All') {
      setSelectedItems(['All']);
    } else {
      setSelectedItems(prev => {
        const updatedSelection = prev.includes('All')
          ? [item]
          : prev.includes(item)
          ? prev.filter(selected => selected !== item)
          : [...prev, item];

        return updatedSelection;
      });
    }

    setSearchTerm('');
  };

  return (
    <Dropdown
      overlay={
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            width: 300,
          }}
        >
          {/* Fixed search input */}
          <div
            style={{
              padding: '10px',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <Input
              placeholder={placeholder}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          {/* Scrollable product list */}
          <div
            style={{
              maxHeight: 300,
              overflowY: 'auto',
              padding: '10px',
            }}
          >
            {filteredProducts && filteredProducts.length > 0 ? (
              filteredProducts.map(item => (
                <div
                  key={item}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => toggleItemSelection(item)}
                >
                  <Radio
                    checked={selectedItems.includes(item)}
                    style={{ marginRight: 10 }}
                  />
                  <span>{item}</span>
                </div>
              ))
            ) : (
              <div style={{ color: 'gray' }}>{emptytext}</div>
            )}
          </div>
        </div>
      }
      trigger={['click']}
    >
      <Button style={{ marginLeft: 10 }} icon={icon && <FilterOutlined />}>
        {title}{' '}
        <span style={{ color: '#1890ff', marginLeft: 10 }}>
          {selectedItems.length && selectedItems[0] != 'All'
            ? selectedItems.length
            : ''}
        </span>
      </Button>
    </Dropdown>
  );
};

export default SearchableDropdown;

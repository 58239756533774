import { useQuery } from 'react-query';
import { ObjectiveRepository } from '../repositories/objective.respository';

export const useGetObjectiveByOrg = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getObjectiveByOrg`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
    ],
    ObjectiveRepository.getObjectiveByOrg,
    {
      enabled: !!data.organizationId, // Or any other condition to enable the query
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

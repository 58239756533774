import React, { FC, useEffect, useState } from 'react';
import './edit-data.less';
import {
  Button,
  Drawer,
  Flex,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  UploadProps,
} from 'antd';
import {
  ConstraintType,
  FormulationSource,
  Outcome,
  useformulationsListLazyQuery,
} from '../../../../../../__generated__/globalTypes';
import {
  useInputOutpuNamesContext,
  useSession,
} from '../../../../_shared/context';
import { RadioChangeEvent } from 'antd/lib';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { parse } from 'papaparse';
import {
  useDeleteFormulationRaw,
  useGetProjectFormulationsRaw,
  useUpdateProjectFromulationRaw,
  useUpdateProjectFromulationItemRaw
} from '../../../../network/services/project.service';
import {
  IBaseFormulationRaw,
  IFormulationRaw,
  IFormulationItemRaw
} from '../../../../network/interfaces/formulationRaw.interfaces';
import { DownloadFile } from '../../../../_shared/components/button/download-file';
import { getFormulationsExport } from '../../../../components/workspaces/adaptive-learning/design-utils';
import {
  roundFormulations,
  useFormulations,
} from '../../../../_shared/context/formulations-context';
import { getProjectSetupFormulationsExport } from '../../../../_shared/utils/util';
import { Option } from 'antd/lib/mentions';
import BlockUi from 'react-block-ui';
import { FormulationItemRawEditableCell } from './editable-cell';
import { ProjectType } from '@prisma/client';
import { log } from 'console';

export const emptyConstraint = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const ProjectEditDataStep = ({
  projectId,
  enableNextStep,
  setNoData,
  handleNextStep,
}: {
  projectId: string;
  enableNextStep: (value: boolean) => void;
  setNoData: (value: boolean) => void;
  handleNextStep: () => void;
}) => {
  const {
    user,
    setCurrentProject,
    currentProject,
    getSelectedIteration,
    useFetchProject,
  } = useSession();
  const [formulationRaw, setFormulationRaw] = useState([]);
  const [columnsTable, setColumnsTable] = useState<TableProps<any>['columns']>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [formulationsDataTable, setformulationsDataTable] = useState<any>([]);
  const deleteFormulationRaw = useDeleteFormulationRaw();
  const putFormulationRaw = useUpdateProjectFromulationRaw();
  const putFormulationItemRaw = useUpdateProjectFromulationItemRaw();
  const [referenceFormulationCost, setReferenceFormulationCost] = useState<number>(0)
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const {
    data,
    isLoading,
    isSuccess,
    refetch,
    isError,
  } = useGetProjectFormulationsRaw({
    projectId: projectId,
    organizationId: user?.organizationId,
    page,
    pageSize,
  });

  const {
    unroundedFormulations,
    refreshTestedFormulations,
    setUnroundedFormulations,
  } = useFormulations();


  const handelChangeType = (record: any, value: string) => {
    record.Type = value;
    const formulation = {
      orgId: user?.organizationId!,
      projectId: projectId,
      formulationId: record.id,
      type: value,
    };
    setLoading(true);
    putFormulationRaw.mutate(formulation, {
      onSuccess: async response => {
        if (value === 'Benchmark') {
          refreshTestedFormulations(callback);
        } else {
          setLoading(false);
        }
      },
      onError: async (response: any) => {
        message.error(response.data.message);
        setLoading(false);
      },
    });
  };

  const callback = () => {
    refetch();
    setLoading(false);
  };

  const handleChangeCost = (record: any, value: number) => {
    setLoading(true);
    const formulation = {
      orgId: user?.organizationId!,
      projectId: projectId,
      formulationId: record.id,
      value: record.Type,
      cost: value
    };
    putFormulationRaw.mutate(formulation, {
      onSuccess: async response => {
        refreshTestedFormulations(callback);
        setLoading(false);
      },
      onError: async (response: any) => {
        message.error(response.data.message);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (data && data.status && (isSuccess || isError)) {
      setLoading(false);
    }
  }, [isSuccess, isError, data]);

  useEffect(() => {
    if (data && data.status && unroundedFormulations) {
      const formulations = data.data.formulations;
      setFormulationRaw(formulations);
      setTotal(data.data.total);
      let benchmark = false;
      const formulation = formulations[0];

      const columns: TableProps<any>['columns'] = [
        {
          title: 'FormulationID',
          dataIndex: 'FormulationID',
          key: 'FormulationID',
        },
        {
          title: 'Type',
          dataIndex: 'Type',
          key: 'Type',
          sorter: (a, b) => a.Type.localeCompare(b.Type),
          filters: [
            { text: 'Benchmark', value: 'Benchmark' },
            { text: 'Past Formulation', value: 'Past Formulation' },
            { text: 'Competitive', value: 'Competitive' },
            { text: 'Tested', value: 'Tested' },
          ],
          onFilter: (value, record) => record.Type === value,
          render: (text, record, index) => {
            let options = [
              { value: 'Benchmark', label: 'Benchmark' },
              { value: 'Tested', label: 'Tested' },
            ];
            if (!record.Original) {
              options.push(
                { value: 'Past Formulation', label: 'Past Formulation' },
                { value: 'Competitive', label: 'Competitive' }
              );
            }
            return (
              <Flex style={{
                width: '100%'
              }}>
                <Select
                  value={text}
                  onChange={value => handelChangeType(record, value)}
                  style={{ width: '100%' }}
                  options={options}
                ></Select>
                {
                  text === 'Benchmark' && currentProject?.ProjectType === ProjectType.PRESERVE_LABEL_LOWER_COST && (
                    <Tooltip title={'Reference Formulation'}>
                      <InfoCircleOutlined style={{
                        marginLeft: '0.5rem'
                      }} />
                    </Tooltip>
                  )
                }
              </Flex>
            );
          },
        },
      ];

      if (currentProject?.ProjectType === ProjectType.PRESERVE_LABEL_LOWER_COST) {
        columns.push(
          {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (text, record) => {

              if (record.Type === 'Benchmark') {
                return (
                  <Tooltip title={'Provide a cost for the Reference Formulation'}>
                    <InputNumber
                      value={record.cost}
                      disabled={record.Type !== 'Benchmark'}
                      min={0}
                      onBlur={(e) => {
                        handleChangeCost(record, +e.target.value)
                      }}
                    />
                  </Tooltip>
                )
              } else {
                return (
                  <InputNumber
                    value={record.cost}
                    disabled={record.Type !== 'Benchmark'}
                    min={0}
                    onBlur={(e) => {
                      handleChangeCost(record, +e.target.value)
                    }}
                  />
                )
              }
            }
          })
      }

      if (formulation) {
        formulation.formulationItemsRaw.map((fi: any) => {
          columns.push({
            title: fi.name,
            dataIndex: fi.name,
            key: fi.name,
            render: (value, record) => {
              return value && <FormulationItemRawEditableCell data={{ value: record[fi.name].value, id: record[fi.name].id }} updateData={handleEditItem} />
            }
          });
        });
      }
      else {
        unroundedFormulations.filter(
          formulation => formulation.source === FormulationSource.TESTED
        )[0]?.items.forEach(i => {
          columns.push({
            title: i.variable.name,
            dataIndex: i.variable.name,
            key: i.variable.name,
            render: (value, record) => {
              return value && <FormulationItemRawEditableCell data={{ value: record[i.variable.name].value, id: record[i.variable.name].id }} updateData={handleEditItem} />
            }
          });
        })
      }


      columns.push(
        {
          title: 'Source File',
          dataIndex: 'sourceFileName',
          key: 'sourceFileName',
        },
        {
          title: 'Uploaded At',
          dataIndex: 'uploadedAt',
          key: 'uploadedAt',
        }
      );

      columns.push({
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <a
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              Delete {record.name}
            </a>
          </Space>
        ),
      });

      setColumnsTable(columns);

      const dataTable: any[] = [];
      if (formulations?.length > 0) {
        formulations.map((f: any) => {
          let row: any = {
            id: f.id,
            FormulationID: f.name,
            Type: f.type,
            cost: f.cost
          };

          f.formulationItemsRaw.map((fi: any) => {
            row[fi.name] = { value: !fi.value ? <Tag color="red">Missing</Tag> : fi.value, id: fi.id };
          });

          if (f.csvUploadFile) {
            row['sourceFileName'] = f.csvUploadFile.name;
            row['uploadedAt'] = (
              <Typography.Text ellipsis={true}>
                {new Date(f.csvUploadFile.createdAt).toLocaleDateString()} by{' '}
                {f.csvUploadFile.createdBy.firstName}{' '}
                {f.csvUploadFile.createdBy.lastName}
              </Typography.Text>
            );
          }
          if (!benchmark) benchmark = f.isBenchmark;
          dataTable.push(row);
        });
      }

      const testedFormulations = unroundedFormulations.filter(
        formulation => formulation.source === FormulationSource.TESTED
      );

      testedFormulations.map(f => {
        let row: any = {
          id: f.id,
          FormulationID: f.key,
          Type: f.isBenchmark ? 'Benchmark' : 'Tested',
          Original: true,
        };
        f.items.map(fi => {
          // row[fi.variable.name] = fi.value;
          row[fi.variable.name] = { value: fi.value, id: fi.id };
        });

        row['sourceFileName'] = 'Test Plan Upload';
        row['uploadedAt'] = (
          <Typography.Text ellipsis={true}>
            {new Date(f.createdAt).toLocaleDateString()}
          </Typography.Text>
        );
        if (!benchmark) benchmark = f.isBenchmark;
        dataTable.push(row);
      });
      setformulationsDataTable(dataTable);
    }
  }, [data, unroundedFormulations]);

  const handlePage = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    refetch();
  };
  const handleDelete = (formulationId: string) => {
    const formulationsRaw: IFormulationRaw = {
      orgId: user?.organizationId!,
      projectId: projectId,
      formulationId: formulationId,
      createdById: user?.id!,
    };
    deleteFormulationRaw.mutate(formulationsRaw, {
      onSuccess: async response => {
        refetch();
      },
      onError: async (response: any) => {
        message.error(response.data.message);
      },
    });
  };

  const handleEditItem = (id: string, value: string) => {
    const formulationsRaw: IFormulationItemRaw = {
      orgId: user?.organizationId!,
      projectId: projectId,
      id: id,
      value: value
    };
    putFormulationItemRaw.mutate(formulationsRaw, {
      onSuccess: async response => {
        refetch();
      },
      onError: async (response: any) => {
        message.error(response.data.message);
      },
    });
  };

  return (
    <Flex vertical gap={12}>
      {unroundedFormulations.length > 0 && (
        <div style={{ marginTop: 12 }}>
          <DownloadFile
            options={{
              type: 'data:attachment/text',
            }}
            content={getProjectSetupFormulationsExport(
              formulationRaw,
              unroundedFormulations.filter(
                formulation =>
                  formulation.source === FormulationSource.EXISTING ||
                  formulation.source === FormulationSource.TESTED
              ),
              {
                removeOutcomeValues: false,
                includeOutcomeBounds: false,
                outcomes: currentProject?.activeModel?.outcomes as
                  | Outcome[]
                  | undefined,
              }
            )}
            filename={`${currentProject?.key}-all-formulations.csv`}
          >
            <Button>Download All Data</Button>
          </DownloadFile>
        </div>
      )}

      <div id="project-edit-data-box">
        {loading && (
          <BlockUi
            tag="div"
            blocking={loading}
            loader={<Spin></Spin>}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100vh',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        {columnsTable && formulationsDataTable && (
          <Table
            columns={columnsTable}
            dataSource={formulationsDataTable}
            pagination={{
              total,
              onChange: handlePage,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
            }}
          />
        )}
      </div>
    </Flex>
  );
};

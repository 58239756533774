/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import './goal-formulation-radar.less';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { Tooltip } from 'antd';
interface Item {
  subject: string;
  value: string;
  desirability: string
}

interface Entry {
  name: string;
  items: Item[];
  maxValue: number;
  cost: number;
  totalDesirability: number;
}

interface ParsedItem {
  subject: string;
  [key: number]: number;
}

export const ProjectGoalFormulationRadar = ({
  data,
  colorFunction,
}: {
  data: any;
  colorFunction: any;
}) => {
  const nameIndexMap: any = [];

  const getMaxValue = (data: any) => {
    if (!data || !data.length) return null;
    return data.reduce((max: any, item: any) => {
      return item.maxValue > max ? item.maxValue : max;
    }, 0);
  };
  const maxValue = getMaxValue(data);

  const defaultData = [
    { subject: ' ', value: 0 },
    { subject: '  ', value: 0 },
    { subject: '   ', value: 0 },
  ];


  const parseData = (input: Entry[]): ParsedItem[] => {
    const result: ParsedItem[] = [];
    const subjectMap: Record<string, ParsedItem> = {};

    input.forEach((entry, index) => {
      const indexKey = index;
      const existingEntry = nameIndexMap.find(
        (item: any) => item.name === entry.name
      );
      if (!existingEntry) {
        nameIndexMap.push({
          name: entry.name,
          index: index,
          cost: entry.cost,
          totalDesirability: entry.totalDesirability,
        });
      }
      let items = [];
      items = entry.items;
      if (entry.items.length <= 2) {
        items = [...items, ...defaultData]
      }

      items.forEach(({ subject, value, desirability }: Item) => {
        if (!subjectMap[subject]) {
          subjectMap[subject] = {
            subject: subject,
          };
        }
        subjectMap[subject][indexKey] = parseFloat(desirability);
      });


    });

    Object.values(subjectMap).forEach(item => {
      result.push(item);
    });

    return result;
  };
  const CustomTick = ({ payload, x, y, textAnchor, ...rest }: any) => {
    const separationOffset = 5;

    return (
      <text
        {...rest}
        x={x}
        y={y}
        dy={y > 200 ? separationOffset : -separationOffset}
        dx={x > 200 ? separationOffset : -separationOffset}
        textAnchor={textAnchor}
        fill="#a7adae"
        fontSize={
          parseData(data).length > 20 ? 6 : 10
        }
      >
        {payload.value}
      </text>
    );
  };

  const BoldRadiusTick = ({ payload, x, y }: any) => {
    return (
      <text x={x} y={y} fontSize={12} fontWeight="bold" fill="#333" textAnchor="middle">
        {payload.value}
      </text>
    );
  };



  return (
    <div className="chart-container radar">
      <ResponsiveContainer height="100%" width="100%">
        <RadarChart
          className="radar-graph"
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={parseData(data)}
        >
          <PolarGrid stroke="#4f4e4e" strokeDasharray="1 3" />
          {nameIndexMap.map((item: any, index: number) => (
            <Radar
              key={index}
              name={item.name}
              dataKey={item.index}
              stroke={colorFunction(index)}
              strokeWidth={2}
              fill={colorFunction(index)}
              fillOpacity={0.0}
            />
          ))}
          <PolarRadiusAxis
            angle={90}
            domain={[0, 1]}
            axisLine={false} // This removes the axis line
            tick={<BoldRadiusTick />} />

          <Tooltip animationDuration={0} animationEasing="linear" />

          <PolarAngleAxis
            dataKey="subject"
            tick={<CustomTick />}
          />

        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

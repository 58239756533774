import { ConstraintInputType, Constraint } from '__generated__/globalTypes';
import { BaseClient } from '../ApiClient';
import {
  IUpsertManyConstraintsData,
  IUpsertManyConstraintsResponse,
} from '../interfaces/constraints.interface';

const ENDPOINTS = {
  postCreateConstraint: (org_id: string, proj_id: string) =>
    `org/${org_id}/constraint/${proj_id}`,
  getConstrainturl: (org_id: string, proj_id: string) =>
    `org/${org_id}/constraint/${proj_id}`,
  deleteConstraintUrl: (org_id: string, proj_id: string, idConst: string) =>
    `org/${org_id}/constraint/${proj_id}/${idConst}`,
  upsertManyConstraintsUrl: (
    org_id: string,
    proj_id: string,
    iterationId: string
  ) => `org/${org_id}/constraint/${proj_id}/batch-upsert/${iterationId}`,
  postMinCostUrl: (org_id: string, proj_id: string, iterationId: string) =>
    `org/${org_id}/constraint/${proj_id}/cost/${iterationId}`,
  getConstraintByOrg: (org_id: string) => `org/${org_id}/constraint`,
};

const postCreateConstraint = (data: any) => {
  return BaseClient.post(
    ENDPOINTS.postCreateConstraint(data.organizationId, data.projectId),
    data.constraint
  );
};

const getConstraint = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getConstrainturl(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const deleteConstraint = (data: any) => {
  return BaseClient.delete(
    ENDPOINTS.deleteConstraintUrl(
      data.organizationId,
      data.projectId,
      data.constraintId
    )
  );
};

const putUpsertManyConstraint = (data: IUpsertManyConstraintsData) => {
  return BaseClient.put<ConstraintInputType[], Constraint[]>(
    ENDPOINTS.upsertManyConstraintsUrl(
      data.organizationId,
      data.projectId,
      data.iterationId
    ),
    data.upsertManyConstraintsData
  );
};
const postMinCostForConstraints = (data: IUpsertManyConstraintsData) => {
  return BaseClient.post<any[], any[]>(
    ENDPOINTS.postMinCostUrl(
      data.organizationId,
      data.projectId,
      data.iterationId
    ),
    data.upsertManyConstraintsData
  );
};
const getConstraintsByOrg = ({ queryKey }: any) => {
  const [, organizationId, page, limit, searchTerm] = queryKey;
  return BaseClient.get(ENDPOINTS.getConstraintByOrg(organizationId), {
    params: { page, limit, searchTerm: searchTerm || '' },
  });
};

export const ConstraintRepository = {
  postCreateConstraint,
  getConstraint,
  deleteConstraint,
  putUpsertManyConstraint,
  postMinCostForConstraints,
  getConstraintsByOrg,
};

/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Form, Button, Modal, InputNumber, Tooltip, Select, Input, message, notification } from 'antd';
import {
  CheckCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/react';

import {
  useSession,
} from '../../../_shared/context';
import { useCreateDataset, useCreateProjectFromDataset } from '../../../network/services/dataset.service';
import { CreateDataSetOrgData, FormulationDataSetIn } from '@root/network/interfaces/formulationDataSet.interfaces';
import { User } from '__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { DataSet } from '@prisma/client';
import { IProjectCreateOut } from '../../../network/interfaces/project.interfaces';
import { ProjectSetupSteps } from '../../../components/exploration/constants';
import { Navigate, useNavigate } from 'react-router-dom';

type ModalProps = {
  dataset: DataSet;
  open: boolean;
  setOpenModal: (o: boolean) => void;
  handleOnUploadComplete: () => void;
};


export const CreateProjectDatasetModal = ({
  dataset,
  open,
  setOpenModal,
  handleOnUploadComplete
}: ModalProps) => {
  const { user } = useSession();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false)
  const useCreatProjectFromeDataSet = useCreateProjectFromDataset();



  const resetModal = () => {
    setUploadedSuccessfully(false);
    setOpenModal(false);
    form.resetFields();
  };


  const onFinish = async (values: any) => {
    const { project_name, project_product_name, project_product_category, project_objective } = values;
    if (user) {
      const create: IProjectCreateOut = {
        organizationId: user?.organizationId,
        createdById: user?.id,
        projectName: project_name,
        productName: project_product_name,
        productCategory: project_product_category,
        lastSetupStep: ProjectSetupSteps.EDIT_RAW_DATA,
        projectObjective: project_objective,
        datasetId: dataset.id,
        stage: 'INGEST'
      }
      setLoading(true);
      useCreatProjectFromeDataSet.mutate(create, {
        onSuccess: (response) => {

          if (response.data.status) {
            notification.success({ message: 'Project created successfully' })
            form.resetFields();
            setOpenModal(false);
            navigate(`/project/new/${response.data.data}`);
          }
        },
        onError: (response) => {
          notification.error({ message: 'Something went wrong' })
        }, onSettled: () => {
          setLoading(false);
        }
      })
    }
  };

  return (
    <Modal
      title={
        `Create a project from dataset`
      }
      centered
      width={600}
      destroyOnClose
      styles={{
        body: {
          minHeight: 400,
        },
      }}
      open={open}
      onCancel={resetModal}
      footer={[<Button onClick={() => setOpenModal(false)} key="back">
        Cancel
      </Button>, <Button key="confirm" className='primary-button' htmlType='submit' form='create-project-form' loading={loading}>
        Create
      </Button>
      ]
      }
    >

      <Form
        form={form}
        name="basic"
        id="create-project-form"
        onFinish={onFinish}
      >
        {/* TITLE */}
        <span
          style={{
            display: 'block',
            color: '#7C858C',
            paddingBottom: 5,
            width: '100%',
            marginTop: 10
          }}
        >
          Project Name
        </span>
        <Form.Item
          name="project_name"

          rules={[
            { required: true, message: 'Name is required' },
          ]}
        >
          <Input />
        </Form.Item>

        {/* FORMULATIONS */}
        <span
          style={{
            display: 'block',
            color: '#7C858C',
            paddingBottom: 5,
            width: '100%',
          }}
        >
          Product Name
        </span>
        <div className='formulations_container'>
          <Form.Item
            name="project_product_name"
            rules={[
              {
                required: true, message: 'Product Name is required'
              },
            ]}
          >
            <Input />

          </Form.Item>

        </div>

        {/* ITERATIONS */}
        <span
          style={{
            display: 'block',
            color: '#7C858C',
            paddingBottom: 5,
            width: '100%',
            marginTop: 10
          }}
        >
          Product Category
        </span>

        <Form.Item
          name="project_product_category"
          //@ts-ignore
          initialValue={dataset.ProductCategory.name}
          rules={[
            {
              required: true, message: 'Product category is required'
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        <span
          style={{
            display: 'block',
            color: '#7C858C',
            paddingBottom: 5,
            width: '100%',
            marginTop: 10
          }}
        >
          Project objective
        </span>
        <Form.Item
          name="project_objective"
          rules={[
            {
              required: true, message: 'Project objective is required'
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

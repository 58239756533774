/** @jsxImportSource @emotion/react */

import { Flex, Tag, Typography, Modal } from 'antd';
import '../../support/contactTuringSupport.less';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface CreateProjectChatHistoryProps {
  messages: {
    type: 'USER' | 'SYSTEM';
    message: string;
  }[];
  open: boolean;
  onClose: () => void;
}

export const CreateProjectChatHistory = ({
  messages,
  open,
  onClose,
}: CreateProjectChatHistoryProps) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width="90%"
      title={
        <Typography.Title
          style={{ display: 'flex', alignItems: 'center', gap: 6, margin: 0 }}
          level={4}
        >
          Project Creation Chat History <Tag>Beta</Tag>
        </Typography.Title>
      }
    >
      <Flex
        style={{
          width: '100%',
          height: '70vh',
        }}
      >
        <Flex
          vertical
          gap={15}
          style={{
            padding: '0 20px',
            overflowY: 'auto',
          }}
        >
          {messages.map((message, index) => (
            <>
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  margin: '8px 0',
                  fontSize: '14px',
                  textAlign: message.type === 'USER' ? 'right' : 'left',
                  marginLeft: message.type === 'USER' ? 'auto' : '0',
                }}
              >
                <div
                  style={{
                    width: 'fit-content',
                    marginRight: '10px',
                    fontSize: '12px',
                    color: '#999',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {message.type === 'USER' ? 'User' : 'System'}
                </div>
                <div
                  key={index}
                  style={{
                    padding: '10px 15px',
                    display: message.type === 'USER' ? 'inline' : 'block',
                    background:
                      message.type === 'SYSTEM' ? '#f5f5f5' : '#e6f7ff',
                    borderRadius: '10px',
                  }}
                >
                  {message.type === 'USER' && (
                    <Typography.Text style={{ color: '#666' }}>
                      {message.message}
                    </Typography.Text>
                  )}
                  {message.type === 'SYSTEM' && (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {message.message}
                    </ReactMarkdown>
                  )}
                </div>

                {/**<div
                    style={{
                      fontSize: '12px',
                      color: '#999',
                      marginTop: '4px',
                    }}
                  >
                    {msg.timestamp.toLocaleTimeString()}
                  </div>
                  **/}
              </div>
            </>
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
};

/** @jsxImportSource @emotion/react */

import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Menu,
  Popover,
  Space,
  Switch,
} from 'antd';
import { BankOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { userTileStyle, dropdownAvatarStyle } from './user-header-tile.styles';

import { useSession } from '../../_shared/context/session-context';
import { User } from '__generated__/globalTypes';
import { updateUser } from '../../_shared/hooks/use-user.hook';
import { useNavigate } from 'react-router-dom';
import { MentionedCommentsList } from '../experiments/comments/mentioned-comments-list';
import { useState } from 'react';
import { Functionalities, requestAccessForUser } from '../../_shared/utils/rbac';
import { ProductFeatureType } from '@prisma/client';
import { useFeatureContext } from '../../_shared/context/features-context';

export const UserHeaderTile = ({ user }: { user: User }) => {
  const { logout, setUser } = useSession();
  const [saveUser] = updateUser();
  const navigate = useNavigate();
  const { isEnableFeature } = useFeatureContext();
  const [showNotificationsPopover, setShowNotificationsPopover] = useState(
    false
  );




  const onBetaToggleChange = async (checked: boolean) => {
    let response = await saveUser({
      variables: {
        data: { enableIceCreamBetaFeatures: !checked },
        userId: user.id,
      },
    });
    setUser({ ...user, enableIceCreamBetaFeatures: checked });
    navigate('/');
  };

  const onHandleMenuClick = async ({ key }: { key: any }) => {
    switch (key) {
      case 'logout': {
        logout();
        break;
      }
      default:
        console.error('Uknown menu action');
    }
  };

  return (
    <div css={userTileStyle}>
      <Divider type="vertical" style={{ height: '100%' }} />
      <span style={{ fontSize: '14px', color: '#819195', marginRight: 5 }}>
        {user.email}
      </span>
      {user.enableIceCreamBetaFeatures && (
        <Popover
          placement="bottomLeft"
          trigger="click"
          open={showNotificationsPopover}
          onOpenChange={visible => setShowNotificationsPopover(visible)}
          content={
            <MentionedCommentsList
              handleOnClose={() => setShowNotificationsPopover(false)}
            />
          }
        >
          <Button type="link" icon={<NotificationOutlined />} />
        </Popover>
      )}
      <Dropdown
        dropdownRender={menu => (
          <Menu>
            {isEnableFeature[ProductFeatureType.DATASET_LIBRARY] && <Menu.Item key="dataset-library" onClick={() => { navigate('/dataset/library') }}>
              Dataset Library
            </Menu.Item>}
            {isEnableFeature[ProductFeatureType.TURING_UNIVERSITY] && <Menu.Item key="turing-university" onClick={() => {
              if (user?.organization.name === 'Kraft') {
                window.open('https://university2.turingsaas.com/', '_blank');
              } else {
                window.open('https://university.turingsaas.com/', '_blank');
              }
            }}>
              Turing University
            </Menu.Item>}
          </Menu>
        )}
      >

        <Button type="link" icon={<BankOutlined />} />
      </Dropdown>
      <Dropdown
        dropdownRender={menu => (
          <Menu>
            {requestAccessForUser(Functionalities.GO_BACK_OLD_UI, user?.role) && (
              <Menu.Item key="beta-toggle">
                <span
                  style={{
                    color: user?.enableIceCreamBetaFeatures
                      ? 'grey'
                      : undefined,
                    paddingRight: 5,
                  }}
                >
                  {/* Turing Beta Features */}
                  Go back to the old UI
                </span>
                <Switch
                  style={{
                    backgroundColor: user?.enableIceCreamBetaFeatures
                      ? 'grey'
                      : '#EA291F',
                  }}
                  onChange={onBetaToggleChange}
                  size="small"
                  defaultChecked={user.enableIceCreamBetaFeatures ? false : true}
                />
              </Menu.Item>
            )}
            <Menu.Item key="logout" onClick={onHandleMenuClick}>
              Sign Out
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
        css={dropdownAvatarStyle}
      >
        <Avatar shape="circle" size="large" style={{ marginLeft: '10px' }}>
          {user.firstName?.charAt(0)?.toLocaleUpperCase()}
          {user.lastName?.charAt(0)?.toLocaleUpperCase()}
        </Avatar>
      </Dropdown>
    </div>
  );
};

import { BaseClient } from '../ApiClient';
import { OrgIdProjId, OrgIdProjIdOutputId, PutRawDataOut } from '../interfaces/rawdata.interface';

const ENDPOINTS = {
  postFormulationItemToRawDataUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/create-raw`,

  getRawDataUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data`,

  putRawDataUrl: (org_id: string, proj_id: string, output_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/${output_id}`,

  postFinishRawDataUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/finish`,

  deleteRawDataUrl: (org_id: string, proj_id: string, output_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/${output_id}`,

  removeOutputRawUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/remove`,

  getReferenceFormulationUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/raw-data/reference`,
};

const postFormulationItemToRawData = (data: OrgIdProjId) => {
  return BaseClient.post(
    ENDPOINTS.postFormulationItemToRawDataUrl(data.organizationId, data.projectId)
  );
};

const getRawData = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getRawDataUrl(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const putRawData = (data: PutRawDataOut) => {
  return BaseClient.put(
    ENDPOINTS.putRawDataUrl(data.organizationId, data.projectId, data.outputId),
    data.output
  );
};

const finishRawData = (data: OrgIdProjId) => {
  return BaseClient.post(
    ENDPOINTS.postFinishRawDataUrl(data.organizationId, data.projectId)
  );
};

const deleteRawData = (data: OrgIdProjIdOutputId) => {
  return BaseClient.delete(
    ENDPOINTS.deleteRawDataUrl(
      data.organizationId,
      data.projectId,
      data.outputId
    )
  );
};

const removeOutputRaw = (data: OrgIdProjId & {
  names: string[]
}) => {
  return BaseClient.patch(
    ENDPOINTS.removeOutputRawUrl(
      data.organizationId,
      data.projectId
    ),
    data.names
  );
};

const getReferenceFormulation = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getReferenceFormulationUrl(parameters.queryKey[1], parameters.queryKey[2])
  )
}

export const RawDataRepository = {
  postFormulationItemToRawData,
  getRawData,
  putRawData,
  finishRawData,
  deleteRawData,
  removeOutputRaw,
  getReferenceFormulation
};
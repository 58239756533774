import React, { useEffect, useState } from 'react';
import { useIngredients } from '../../../_shared/hooks';
import { isNaN } from 'lodash';
import './experiment-constraints-min-cost.less'
import { useTranslation } from 'react-i18next';
export const ExperimentConstraintsMinCost = ({
  minCost
}: {
  minCost: any[]
}) => {
  const { ingredientByName } = useIngredients();
  const [cost, setCost] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    if (minCost) {
      let costNumber = Number(minCost[0]);
      if (isNaN(costNumber))
        setCost(0);
      else {
        costNumber = costNumber / 100;
        setCost(Number(costNumber.toFixed(3)));
      }


    }

  }, [minCost])
  return (
    <div
      style={{
        alignSelf: 'flex-start',
        flexDirection: 'column',
        display: 'flex',
      }}
      className="task-chat"
      id="constraint-min-cost"
    >

      <p>
        {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.title')} <b>{cost}</b>
      </p>
      <p>
        {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.page.infoTooltip')}
      </p>

    </div>
    //  <div>
    //  {(
    //     <div className='task-chat' id="constraint-min-cost">
    //       <div className='title'>
    //         {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.title')} {cost}
    //       </div>
    //       <div className='formulation'>
    //         <Flex justify="space-between">
    //           <Flex gap={5} align="center" style={{ padding: '10px 0' }}>
    //             <div
    //               className='title'
    //             >
    //               {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.page.ingredientLabel')}
    //             </div>
    //           </Flex>
    //         </Flex>

    //         <div className='formulation-generated'>
    //           {minCost[1]?.formulation?.map((item: { name: string, value: string }) => {
    //             const ingredient = ingredientByName.get(item.name);
    //             let value: number | string = Number(item.value);
    //             if (!isNaN(value))
    //               value = value.toFixed(3);
    //             else
    //               value = item.value;
    //             const ingredientValue = !isNaN(Number(value)) && !ingredient?.unit
    //               ? value + '%'
    //               : value + ingredient?.unit;
    //             return (
    //               <div
    //                 className="row">
    //                 <div className="ingredient name">
    //                   {`${item.name} : `}
    //                 </div>
    //                 <div className='value'>
    //                   {ingredientValue}
    //                 </div>
    //               </div>
    //             );
    //           })}
    //         </div>

    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

import { Button, Layout, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './project-setup-header-styles.less';
import {
  LoadingOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { NewBackArrow } from '../../../../_shared/style';
import { useLocation } from 'react-router-dom';
import { useFeatureContext } from '../../../../_shared/context/features-context';
import { ProductFeatureType } from '@prisma/client';
import { useSession } from '../../../../_shared/context';
import { useRawDataContext } from '../../../../_shared/context/raw-data-context';
import { ProjectSetupSteps } from '../../../../components/exploration/constants';

export interface IHeaderInfo {
  title: string;
  description: string | null;
  activeStep?: string;
  nextStepEnable: boolean;
  backEnable: boolean;
  finishSetup: boolean;
  edit: boolean;
  onClickNext?: (value: any) => void;
  onClickBack?: (value: any) => void;
  onClickSave?: (value: any) => void;
  onClickFinish?: (value: any) => void;
  onClickImport?: (value: any) => void;
  onClickRemoveRows?: (value: any) => void;
}

export const ProjectSetupHeader: React.FC<IHeaderInfo> = ({
  title,
  description,
  activeStep,
  nextStepEnable,
  backEnable,
  finishSetup,
  edit,
  onClickNext,
  onClickBack,
  onClickSave,
  onClickFinish,
  onClickImport,
  onClickRemoveRows,
}) => {
  const urlPath = useLocation();
  const isInspectingExistingProject = urlPath.pathname.includes('/edit/');
  const { isEnableFeature } = useFeatureContext();
  const [isLoadingNextStep, setIsLoadingNextStep] = useState<boolean>(false);
  const { currentProject } = useSession()
  const { namesToDeleteFromDataAggregated } = useRawDataContext()

  useEffect(() => {
    setIsLoadingNextStep(false);
  }, [activeStep]);

  useEffect(() => {
    if (isLoadingNextStep) {
      const timer = setTimeout(() => {
        setIsLoadingNextStep(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isLoadingNextStep]);

  return (
    <div id="project-header">
      <div id="project-setup-header-box">
        <div>
          <span className="header-title">{title}</span>
          <span className="header-description">{description}</span>
        </div>
        {edit && (
          <>
            <div className="action-button-header">
              {!finishSetup && !isInspectingExistingProject && (
                <>
                  <Tooltip
                    title={
                      activeStep === 'EDIT_RAW_INPUT_OUTCOMES' &&
                        !nextStepEnable
                        ? 'At least 1 ingredient, 1 outcome, and 1 filler ingredient are required.'
                        : undefined
                    }
                  >
                    <Button
                      type="primary"
                      className="base-button"
                      disabled={!nextStepEnable || isLoadingNextStep}
                      style={{ marginRight: '10px' }}
                      onClick={value => {
                        setIsLoadingNextStep(true);
                        onClickNext && onClickNext(value);
                      }}
                    >
                      {isLoadingNextStep && (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                          style={{
                            marginRight: '1rem',
                            color: 'white',
                          }}
                        />
                      )}
                      Next →
                    </Button>
                  </Tooltip>

                  <Button
                    type="primary"
                    className="base-button"
                    icon={<SaveOutlined />}
                    onClick={onClickSave}
                    style={{ marginRight: '10px' }}
                  >
                    Save
                  </Button>

                  {
                    currentProject?.originalProjectId && namesToDeleteFromDataAggregated.length > 0 && (
                      <Button
                        type="primary"
                        className="base-button"
                        onClick={onClickRemoveRows}
                      >
                        Remove Variables
                      </Button>
                    )
                  }
                </>
              )}

              {activeStep === 'PROJECT_INFO' &&
                !isInspectingExistingProject &&
                isEnableFeature[ProductFeatureType.IMPORT_EXPORT_PROJECT] && (
                  <Button
                    type="primary"
                    style={{ marginLeft: '10px' }}
                    className="base-button"
                    icon={<UploadOutlined />}
                    onClick={onClickImport}
                  >
                    Import project
                  </Button>
                )}

              {finishSetup && (
                <Button
                  type="primary"
                  className="base-button"
                  onClick={onClickFinish}
                >
                  Finish Project Setup
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      {edit && !isInspectingExistingProject && activeStep !== ProjectSetupSteps.PROJECT_INFO && (
        <>
          <div id="back-step">
            <div className="button-back" onClick={onClickBack}>
              <NewBackArrow />
            </div>
          </div>
        </>
      )}
    </div>
  );
};


import React, { useEffect, useState } from "react";
// @ts-ignore
import styles from "./dataset-library.module.less";
import { useTranslation } from "react-i18next";
import { DataSetUpload } from "./dataset-upload/dataset-upload";
import { User } from "__generated__/globalTypes";
import { useGetDataset } from "../../network/services/dataset.service";
import { useSession } from "../../_shared/context";
import { Button, Space, Table } from "antd";
import { DataSetView } from "./dataset-view/dataset-view";
import { DataSet } from "@prisma/client";
export const DataSetLibrary: React.FC = () => {

  const { user } = useSession();
  const { t } = useTranslation();
  const [datasetId, setDatasetId] = useState();
  const [datasetList, setDatasetList] = useState([]);
  const [dataset, setDataset] = useState<DataSet | undefined>()
  const [viewDataset, setViewDataset] = useState(false)
  const { data: dataSource, refetch, isLoading } = useGetDataset({ org_id: user?.organizationId, datasetId });
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Product Category",
      dataIndex: ["ProductCategory", "name"],
      key: "productCategory",
    },
    {
      title: "Created By",
      dataIndex: ["createdBy", "email"],
      key: "createdBy",
      render: (text: string | undefined) => text || "N/A"
    },
    {
      title: "Created At",
      dataIndex: 'createdAt',
      key: "createdAt",
      render: (value: string) => {
        return new Date(value).toLocaleDateString()
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Button className={styles.button} type="primary" onClick={() => handleView(record)}>View</Button>
        </Space>
      ),
    }
  ]

  const handleView = (record: any) => {
    if (record?.id)
      setDatasetId(record.id)
  }

  const handleCloseView = () => {
    setDatasetId(undefined);
    setDataset(undefined);
    setViewDataset(false);
  }

  useEffect(() => {
    if (datasetId) {
      refetch();
      setViewDataset(true);
    }

  }, [datasetId])

  useEffect(() => {
    if (dataSource && datasetId) {
      setDataset(dataSource.data)
    } else if (dataSource) {
      setDatasetList(dataSource.data)
    }

  }, [dataSource])
  return (
    <div className={styles.page}>
      <div className={styles.projectSetupHeaderBox}>
        <h1 className={styles.headerTitle}>
          {t('dataset-library.title')}
        </h1>
        <div className={styles.description}>
          {t('dataset-library.page.descriptionLabel')}
        </div>

      </div>
      <div className={styles.body}>
        {viewDataset ?
          dataset && <DataSetView dataSet={dataset} handleClose={handleCloseView} /> :

          <>
            <div className={styles.datasetCreate}>
              <DataSetUpload handleComplete={refetch} />
            </div>
            {<Table dataSource={datasetList} columns={columns} />}
          </>}


      </div>
    </div>
  );
};

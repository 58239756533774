import { BaseClient } from '../ApiClient';

const BASE_URL = (org_id: string, proj_id: string) =>
  `org/${org_id}/project/${proj_id}/ingredient-composition`;

const ENDPOINTS = {
  getIngredientsCompositions: (org_id: string, proj_id: string) =>
    BASE_URL(org_id, proj_id),
  getIngredientCompositionsByOrganization: (org_id: string) =>
    `org/${org_id}/ingredientComposition`,
};

const getIngredientCompositionsByOrganization = ({ queryKey }: any) => {
  const [, organizationId, page, limit, searchTerm] = queryKey;
  return BaseClient.get(
    ENDPOINTS.getIngredientCompositionsByOrganization(organizationId),
    {
      params: { page, limit, searchTerm: searchTerm || '' },
    }
  );
};

const getIngredientsCompositions = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getIngredientsCompositions(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

export const IngredientCompositionRepository = {
  getIngredientCompositionsByOrganization,
  getIngredientsCompositions
};

import { useMutation, useQuery } from 'react-query';
import { ConstraintRepository } from '../repositories/constraint.repository';
import { IUpsertManyConstraintsData } from '../interfaces/constraints.interface';

export const useCreateConstraint = () => {
  const mutation = useMutation((data: any) => {
    return ConstraintRepository.postCreateConstraint(data);
  });
  return mutation;
};

export const useGetConstraint = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getConstraint`, data.organizationId, data.projectId],
    ConstraintRepository.getConstraint,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useDeleteConstraint = () => {
  const mutation = useMutation((data: any) => {
    return ConstraintRepository.deleteConstraint(data);
  });
  return mutation;
};

export const usePutUpsertManyConstraints = () => {
  const mutation = useMutation((data: IUpsertManyConstraintsData) => {
    return ConstraintRepository.putUpsertManyConstraint(data);
  });
  return mutation;
};

export const usePostMinCostForConstraints = () => {
  const mutation = useMutation((data: IUpsertManyConstraintsData) => {
    return ConstraintRepository.postMinCostForConstraints(data);
  });
  return mutation;
};

export const useGetConstraintsByOrg = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getConstraintsByOrg`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
    ],
    ConstraintRepository.getConstraintsByOrg,
    {
      enabled: !!data.organizationId, // Or any other condition to enable the query
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

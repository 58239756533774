/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Divider, Typography, Button, Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ProductFeatureType, Role } from '@prisma/client';
import { IterationSidebar, IterationRouteParams } from '../iteration';
import { ReportsSidebar } from '../reports';
import { useSession } from '../../_shared/context/session-context';
import { css } from '@emotion/react';
import { AdminSideBarV2 } from './admin/side-bar/v2/admin-side-bar';
import { MainRoutesSidebar } from './admin/side-bar/v2/main-routes-side-bar';
import { HeaderBackIcon } from '../../_shared/style';
import { DataSetSideBarV2 } from './admin/side-bar/v2/dataset-side-bar';
import { useFeatureContext } from '../../_shared/context/features-context';

export enum ProjectSiderSectionType {
  REPORTS,
  ITERATIONS,
  ADMIN,
}

export const ProjectSiderV2 = () => {
  const {
    user,
    setSelectedIterationId,
    currentProject,
    setOpenNavBar,
  } = useSession();
  const params = useParams<IterationRouteParams>();
  const [selectedSection, setSelectedSection] = useState<
    ProjectSiderSectionType
  >(
    params.iterationId
      ? ProjectSiderSectionType.ITERATIONS
      : ProjectSiderSectionType.REPORTS
  );

  const { isEnableFeature } = useFeatureContext();
  const onSelectClicked = (section: ProjectSiderSectionType) => {
    setSelectedSection(section);
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
      `}
    >
      <Button
        type="text"
        icon={<CloseOutlined />}
        css={css`
          font-size: 16px;
          margin-left: -10px;
        `}
        onClick={() => setOpenNavBar(false)}
      />
      <Typography.Title style={{ marginTop: 15 }} level={4}>
        {user?.organization.name}
      </Typography.Title>
      <Link
        css={css`
          color: #303b40;
        `}
        to="/"
      >
        {currentProject && (
          <div
            css={css`
              height: 23px;
              width: 23px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              padding-bottom: 5px;
            `}
          >
            <HeaderBackIcon />
          </div>
        )}
        <span>View All Projects </span>
      </Link>
      <Divider style={{ marginTop: 5 }} />
      <MainRoutesSidebar
        selected={selectedSection === ProjectSiderSectionType.REPORTS}
        onSelect={() => {
          onSelectClicked(ProjectSiderSectionType.REPORTS);
          setSelectedIterationId('');
        }}
      />
      {/* {isEnableFeature[ProductFeatureType.DATASET_LIBRARY] && <DataSetSideBarV2 onSelect={() => {
        onSelectClicked(ProjectSiderSectionType.REPORTS);
        setSelectedIterationId('');
      }}></DataSetSideBarV2>} */}
      {(user?.role === Role.SUPER_ADMIN || user?.role === Role.ADMIN) && (
        <AdminSideBarV2
          onSelect={() => {
            onSelectClicked(ProjectSiderSectionType.ADMIN);
            setSelectedIterationId('');
          }}
        />
      )}
    </div>
  );
};

import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { useExploration, useSession } from '../../../_shared/context';
import { limitDecimals } from '../../../_shared/utils/util';
import { Checkbox, InputNumber, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CostOptimizationOption } from '../../../../../__generated__/globalTypes';
import { useFormulations } from '../../../_shared/context/formulations-context';
const { Text } = Typography;

interface ConstraintsCostOptions {
  optForCost: boolean;
  costNotExceed: boolean;
  minCost: boolean;
}

export const ConstraintCostOptimizationOptions: React.FC<any> = () => {
  const { currentProject } = useSession();
  const { minCostScore, maxCostScore } = useExploration();
  const {
    nteCost,
    setEnforceNteCost,
    setNteCost,
    setCostOptimizationOption,
    costOptimizationOption,
  } = useScenarioDetail();
  const [costOptimizationFromDesign, setCostOptimizationFromDesign] = useState(
    false
  );
  const [isOptimizeForCost, setIsOptimizeForCost] = useState<boolean>(
    costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE
  );
  const {
    minTestedFormulationsCostScore,
    maxTestedFormulationsCostScore,
  } = useFormulations();
  // const [optionsChecked, setOptionsChecked] = useState<ConstraintsCostOptions | undefined >({
  //   costNotExceed: costOptimizationOption === CostOptimizationOption.LIMIT || Boolean(nteCost),
  //   minCost: costOptimizationOption === CostOptimizationOption.MINIMIZE,
  //   optForCost: costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE
  // })
  const [optionsChecked, setOptionsChecked] = useState<
    ConstraintsCostOptions | undefined
  >(undefined);

  useEffect(() => {
    if (costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE) {
      setOptionsChecked({
        costNotExceed:
          costOptimizationOption === CostOptimizationOption.LIMIT ||
          Boolean(nteCost),
        minCost: costOptimizationOption === CostOptimizationOption.MINIMIZE,
        optForCost: true,
      });
      //setCostOptimizationFromDesign(true);
    }
  }, [costOptimizationOption]);

  useEffect(() => {
    if (optionsChecked) {
      if (!optionsChecked.optForCost) {
        setCostOptimizationOption(CostOptimizationOption.DO_NOT_OPTIMIZE);
        setNteCost(0);
        setEnforceNteCost(false);
        setIsOptimizeForCost(false);
      } else {
        setIsOptimizeForCost(true);
        if (!optionsChecked.costNotExceed) {
          setNteCost(0);
          if (!optionsChecked.minCost) {
            setCostOptimizationOption(CostOptimizationOption.DO_NOT_OPTIMIZE);
          } else {
            setCostOptimizationOption(CostOptimizationOption.MINIMIZE);
          }
        } else {
          if (optionsChecked.minCost) {
            setCostOptimizationOption(CostOptimizationOption.MINIMIZE);
          } else {
            setCostOptimizationOption(CostOptimizationOption.LIMIT);
          }
        }
      }
    }
  }, [optionsChecked]);

  return (
    <Space direction="vertical">
      <div style={{ fontWeight: 500 }}>Cost Optimization</div>
      <Checkbox
        checked={optionsChecked?.optForCost}
        onChange={e => {
          if (optionsChecked)
            setOptionsChecked({
              optForCost: e.target.checked,
              costNotExceed: !e.target.checked
                ? false
                : optionsChecked.costNotExceed,
              minCost: !e.target.checked ? false : optionsChecked.minCost,
            });
          else
            setOptionsChecked({
              optForCost: e.target.checked,
              costNotExceed: false,
              minCost: false,
            });
          // setIsOptimizeForCost(e.target.checked)
        }}
      >
        Optimize For Cost
      </Checkbox>
      <Text type="secondary">{`Please check the box to optimize for cost`}</Text>
      <div
        style={{
          marginLeft: '2rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Checkbox
          checked={optionsChecked?.costNotExceed}
          value={CostOptimizationOption.LIMIT}
          onChange={e => {
            if (optionsChecked)
              setOptionsChecked({
                ...optionsChecked,
                costNotExceed: e.target.checked,
              });
            // setCostOptimizationOption(e.target.value)
          }}
          disabled={!isOptimizeForCost}
        >
          Cost not to exceed
        </Checkbox>
        {optionsChecked?.costNotExceed && (
          <div
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            <div style={{ width: '100%' }}>
              <InputNumber
                addonAfter={currentProject?.monetaryUnit}
                style={{ width: '100%' }}
                value={nteCost}
                onChange={(value: number) => setNteCost(value)}
                min={0}
              />
              {typeof minTestedFormulationsCostScore === "number" &&
                typeof maxTestedFormulationsCostScore === "number" &&
                !isNaN(minTestedFormulationsCostScore) &&
                !isNaN(maxTestedFormulationsCostScore) && (
                  <Text type="secondary">{`Existing formulations in this project range from $${limitDecimals(
                    minTestedFormulationsCostScore,
                    2
                  )} to $${limitDecimals(
                    maxTestedFormulationsCostScore,
                    2
                  )} cost score. `}</Text>
                )}
            </div>
          </div>
        )}
        <Checkbox
          value={CostOptimizationOption.MINIMIZE}
          checked={optionsChecked?.minCost}
          onChange={e => {
            if (optionsChecked)
              setOptionsChecked({
                ...optionsChecked,
                minCost: e.target.checked,
              });
            // setCostOptimizationOption(e.target.value)
          }}
          disabled={!isOptimizeForCost}
        >
          Minimize cost
        </Checkbox>
      </div>
    </Space>
  );
};

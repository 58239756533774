/** @jsxImportSource @emotion/react */
import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PauseOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { ProductFeatureType, VariableType } from '@prisma/client';
import {
  Ellipsis,
  EllipsisMiddle,
  formatCostScore,
} from '../../_shared/utils/component';
import {
  Badge,
  Button,
  Empty,
  Flex,
  Popover,
  Switch,
  Table,
  Tag,
  Tooltip,
  notification,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, {
  Dispatch,
  Key,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NumericFilterValues } from './filter-dropdown/numeric-custom-filter-dropdown';
import { isWithinBounds } from '../workspaces/adaptive-learning/design-validation';
import { useDesign } from '../../_shared/context/design-context';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
  useSession,
} from '../../_shared/context';
import {
  calculateCompositionTotalsV2,
  calculateFormulationCostV2,
  confidenceIntervalsStringToArray,
  convertReliabilityPercentage,
  limitDecimals,
} from '../../_shared/utils/util';
import { useIngredients } from '../../_shared/hooks/use-ingredient.hook';
import '../../pages/execute-solutions/experiment-list-style.css';
import {
  FormulationItemMetric,
  FormulationItemMetricType,
  FormulationItemType,
  FormulationMetricType,
  FormulationSource,
  JobStatus,
  ProjectFeature,
  ProjectJobType,
  usepollProjectJobStatusMutation,
  usetriggerTestPlanModelRebuildMutation,
  useupdateOneTestPlanMutation,
} from '../../../../__generated__/globalTypes';
import { FilterValue } from 'antd/lib/table/interface';
import { useFormulations } from '../../_shared/context/formulations-context';
import { SourcePopover } from './experiment-list-table/source-popover';
import { ScenarioFilterModal } from './experiment-list-table/scenario-filter-modal';
import { ConfidenceIntervalText } from '../exploration/confidence-interval-text';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';
import { css } from '@emotion/react';
import DraggableTableRow from './experiment-list-table/draggable-table-row';
import { TestPlanNotesModal } from './test-plan/notes-modal';
import { GreenIndicator, SummaryCloseIcon } from '../../_shared/style';
import { CommentModal } from './comments/comment-modal';
import { useGetAllProjectComments } from '../../network/services/comment.service';
import { CommentThread } from '@root/network/interfaces/comment.interface';
import { SortByDropdown } from './sort-by-dropdown/sort-by-dropdown';
import { FilterType, ViewType } from './experiment-list-layout-v2';
import { MetricsDropdown } from './metric-dropdown/metric-dropdown';
import { TestPlanComponentV2 } from './test-plan/test-plan-component-v2';
import { IsolateButton } from './experiment-list-table/isolate-button';
import { SearchBar } from './search-bar/search-bar';
import { CopilotChat } from './copilot/chat';
import { FilterDropdownV2 } from './filter-dropdown-v2/filter-dropdown-v2';
import { FormulationDetailsModal } from './formulation-detail-modal/formulation-details-modal';
import { PolledDesignsType } from '../workspaces/adaptive-learning/types';
import { useFeatureContext } from '../../_shared/context/features-context';

type CustomRecordType = Record<string, any>;

const handleRangeFilter = (
  filterValue: NumericFilterValues,
  record: CustomRecordType,
  fieldToFilter: string
) =>
  isWithinBounds(
    record[fieldToFilter],
    filterValue.greaterThan,
    filterValue.lessThan
  );

const handleTextFilter = (
  filterValue: string,
  record: CustomRecordType,
  fieldToFilter: string,
  exact?: boolean
) => {
  if (fieldToFilter === 'source') {
    if (filterValue === 'PREFERRED') {
      return record.formulation.formulationFeedback.some(
        (feedback: { type: string; isActive: boolean }) =>
          feedback.type === 'POSITIVE' && feedback.isActive
      );
    }
  }

  return exact
    ? record[fieldToFilter] === filterValue || record.isBenchmark
    : record[fieldToFilter]?.startsWith(filterValue) || record.isBenchmark;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatValue = (value: string) => {
  const numberValue = Number(value);
  const formattedValue = isNaN(numberValue)
    ? value
    : limitDecimals(numberValue, 4);

  return formattedValue;
};

const getTagColor = (value: number): string => {
  if (value > 0 && value < 0.25) {
    return 'red';
  } else if (value >= 0.25 && value < 0.5) {
    return 'orange';
  } else if (value >= 0.5 && value < 0.75) {
    return 'yellow';
  } else if (value >= 0.75) {
    return 'green';
  }
  return 'default';
};

const TagAndEllipsis = ({
  targetVariable,
  latestDesign,
}: {
  targetVariable: string;
  latestDesign: PolledDesignsType | undefined;
}) => {
  const colors: Record<number, string> = {
    0: 'default',
    1: 'blue',
    2: 'green',
    3: 'red',
  };

  const icons: Record<number, React.ReactNode> = {
    0: (
      <Tooltip title="None Priority">
        <MinusOutlined style={{ marginRight: 5, color: 'gray' }} />
      </Tooltip>
    ),
    1: (
      <Tooltip title="Low Priority">
        <DoubleRightOutlined
          style={{
            transform: 'rotate(90deg)',
            marginRight: 5,
            color: 'blue',
          }}
        />
      </Tooltip>
    ),
    2: (
      <Tooltip title="Medium Priority">
        <PauseOutlined
          style={{
            transform: 'rotate(90deg)',
            marginRight: 5,
            color: 'green',
          }}
        />
      </Tooltip>
    ),
    3: (
      <Tooltip title="High Priority">
        <DoubleRightOutlined
          style={{
            transform: 'rotate(-90deg)',
            marginRight: 5,
            color: 'red',
          }}
        />
      </Tooltip>
    ),
  };

  let objectiveRelated;
  if (latestDesign)
    objectiveRelated = latestDesign.objectives.find(
      (obj: any) => obj.targetVariable === targetVariable
    );
  let importance;
  if (objectiveRelated?.importance !== undefined)
    importance = objectiveRelated?.importance;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        width: '100%',
        textAlign: 'center',
      }}
    >
      {importance !== undefined && icons[importance]}
      <Tooltip title={targetVariable}>
        <span
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            textAlign: 'center',
          }}
        >
          {targetVariable}
        </span>
      </Tooltip>
    </div>
  );
};

export enum FormulationSourceWithPreferred {
  EXISTING = FormulationSource.EXISTING,
  SUGGESTED = FormulationSource.SUGGESTED,
  TESTED = FormulationSource.TESTED,
  PREFERRED = 'PREFERRED',
}

type ResultTableProps = {
  tableKey: number;
  setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
  isolatedRows: Key[];
  setIsolatedRows: Dispatch<SetStateAction<Key[]>>;
  selectedRowKeys: Key[];
  tableData: CustomRecordType[];
  setTableData: (data: CustomRecordType[]) => void;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
  sortBy: string | undefined;
  tableFilters: Record<string, FilterValue | null>;
  setTableFilters: (data: Record<string, FilterValue | null>) => void;
  resetAllTable: () => void;
  selectedScenarios: string[];
  setSelectedScenarios: (selectedScenarios: string[]) => void;
  setSortBy: Dispatch<SetStateAction<string>>;
  sortOrder: string;
  setSortOrder: Dispatch<SetStateAction<string>>;
  viewType: ViewType;
  chartFilters: FilterType[];
  setChartFilters: (filters: FilterType[]) => void;
  filterMenuVisible: boolean;
  setFilterMenuVisible: (filterMenuVisible: boolean) => void;
  chartData: { name: string; values: Record<string, any>[] }[];
  formulationsToFilter: string[];
  setFormulationsToFilter: (formulationsToFilter: string[]) => void;
  handleIsolateTableRows: () => void;
  setShowDeleteFormulationModal: React.Dispatch<React.SetStateAction<boolean>>;
  tableDataForFilter:
  | { name: string; values: Record<string, any>[] }[]
  | undefined;
  showCopilot: boolean;
  setShowCopilot: (show: boolean) => void;
};

export const ExperimentListTableComponentV2 = ({
  tableKey,
  setSelectedRowKeys,
  selectedRowKeys,
  setTableData,
  tableData,
  tableColumns,
  setTableColumns,
  sortBy,
  isolatedRows,
  setIsolatedRows,
  tableFilters,
  setTableFilters,
  resetAllTable,
  selectedScenarios,
  setSelectedScenarios,
  setSortBy,
  sortOrder,
  setSortOrder,
  viewType,
  chartFilters,
  setChartFilters,
  filterMenuVisible,
  setFilterMenuVisible,
  chartData,
  formulationsToFilter,
  setFormulationsToFilter,
  handleIsolateTableRows,
  setShowDeleteFormulationModal,
  tableDataForFilter,
  showCopilot,
  setShowCopilot,
}: ResultTableProps) => {
  const { latestDesign } = useDesign();
  const { currentProject, selectedIterationId } = useSession();
  const pricingFeature = currentProject?.features.find(
    f => f.feature === ProjectFeature.PRICING
  );
  const { ingredients } = useIngredients();
  const {
    testPlansWithFormulations,
    setTestPlansWithFormulations,
    getTestPlansWithFormulations,
    selectedIteration,
  } = useExecuteSolutions();
  const { projectFormulations, formulationsFetching } = useFormulations();
  const [updateTestPlan, { loading }] = useupdateOneTestPlanMutation();
  const [openFilterByScenarioModal, setFilterByScenarioModal] = useState(false);
  const [selectedTestPlanToReview, setSelectedTestPlanToReview] = useState<
    TestPlanWithFormulationsType | undefined
  >();
  const [
    showModelRebuildRunningMessage,
    setShowModelRebuildRunningMessage,
  ] = useState(false);
  const [
    showModelRebuildCompleteMessage,
    setShowModelRebuildCompleteMessage,
  ] = useState(false);
  const [commentPopoverVisible, setCommentPopoverVisible] = useState(false);
  const [
    selectedFormulationToComment,
    setSelectedFormulationToComment,
  ] = useState<CustomRecordType | undefined>();
  const [filterCurrentCampaign, setFilterCurrentCampaign] = useState<boolean>(
    true
  );
  const [enableTags, setEnableTags] = useState(false);
  const [enableConfidenceIntervals, setEnableConfidenceIntervals] = useState<
    boolean
  >(false);
  const [campaignFilteredId, setCampaignFilteredId] = useState<
    String | undefined
  >(undefined);
  const [customTestPlanFilteredId, setCustomTestPlanFilteredId] = useState<
    String | undefined
  >(undefined);
  const [roundSelectedId, setRoundSelectedId] = useState<String | undefined>(
    undefined
  );
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState('');
  const [selectedFormulationDetails, setSelectedFormulationDetails] = useState<
    CustomRecordType | undefined
  >(undefined);

  const [
    triggerTestPlanModelRebuild,
    { loading: modelRebuildLoading },
  ] = usetriggerTestPlanModelRebuildMutation();
  const [pollProjectJobStatus] = usepollProjectJobStatusMutation();

  const { isEnableFeature } = useFeatureContext();

  const {
    data: allProjectComments,
    refetch: refetchAllProjectComments,
  } = useGetAllProjectComments({
    projectId: currentProject?.id!,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const activeCommentKey = queryParams.get('active_comment');

  useEffect(() => {
    setTableLoading(true);
    let tableData: Record<string, any>[] = [];

    if (selectedTestPlanToReview?.latestUpload) {
      tableData = selectedTestPlanToReview.latestUpload.uploadedFormulations.map(
        result => {
          let formulation = result.uploadedFormulation!;
          let useChild = false;

          let parentFormulationValues = new Map<string, string>();

          if (!result.matched && result.createdChildFormulation) {
            formulation = result.createdChildFormulation;
            useChild = true;

            result.uploadedFormulation?.items.forEach(item => {
              parentFormulationValues.set(
                item.variable.name,
                formatValue(item.value)
              );
            });
          }

          let formattedTableRow: Record<string, any> = {
            key: formulation.key,

            id: formulation.key,
            isGenerated: false,
            isBenchmark: false,
            experiment: formulation.key,
            measured: formulation.isMeasured ? 'Yes' : 'No',
            formulation,
            parentFormulationValues: useChild
              ? parentFormulationValues
              : undefined,
            status: result.matched ? 'Matched' : 'Unmatched',
          };

          formulation.items.forEach(f => {
            formattedTableRow[f.variable.name] = formatValue(f.value);
          });
          return formattedTableRow;
        }
      );
    } else {
      let formulations = projectFormulations;
      let benchmark;
      for (let key in Object.keys(projectFormulations)) {
        if (projectFormulations[key].isBenchmark)
          benchmark = projectFormulations[key];
      }

      if (filterCurrentCampaign) {
        if (currentProject?.currentCampaignId) {
          const formulationIds: string[] = [];
          for (const testPlan of testPlansWithFormulations) {
            if (testPlan?.campaignId === currentProject.currentCampaignId) {
              testPlan.formulations.forEach(formulation => {
                formulationIds.push(formulation.id);
              });
            }
          }
          if (formulationIds.length > 0) {
            formulations = projectFormulations.filter(formulation =>
              formulationIds.includes(formulation.id)
            );
          } else {
            formulations = projectFormulations;
          }
        }
      }

      if (campaignFilteredId) {
        const formulationIds: string[] = [];
        for (const testPlan of testPlansWithFormulations) {
          if (testPlan?.campaignId === campaignFilteredId) {
            if (roundSelectedId !== undefined) {
              for (const f of testPlan.formulations) {
                if (f.campaignRound === roundSelectedId || f.isBenchmark) {
                  formulationIds.push(f.id);
                }
              }
            } else {
              testPlan.formulations.forEach(formulation => {
                formulationIds.push(formulation.id);
              });
            }
          }
        }
        formulations = projectFormulations.filter(formulation =>
          formulationIds.includes(formulation.id)
        );
      }

      if (customTestPlanFilteredId) {
        const formulationIds: string[] = [];
        for (const testPlan of testPlansWithFormulations) {
          if (testPlan?.id === customTestPlanFilteredId) {
            testPlan.formulations.forEach(formulation => {
              formulationIds.push(formulation.id);
            });
          }
        }

        formulations = projectFormulations.filter(formulation =>
          formulationIds.includes(formulation.id)
        );
      }

      formulations = formulations.filter(
        f => !formulationsToFilter.includes(f.key!) && !f.isBenchmark
      );
      if (benchmark && formulations.length > 0) {
        formulations.push(benchmark);
      }

      tableData = formulations.map(result => {
        let formulationIngredientItems = result.items
          .filter(item => item.type === FormulationItemType.INPUT)
          .map(item => ({
            value: Number(item.value),
            name: item.variable.name,
          }));

        let expectedImprovementScore = '';
        let desirability = '';

        result.metrics.forEach(metric => {
          if (metric.type === FormulationMetricType.SCORE) {
            expectedImprovementScore = Number(metric.value).toPrecision(3);
          }

          if (metric.type === FormulationMetricType.TOTAL_DESIRABILITY) {
            desirability = Number(metric.value).toPrecision(3);
          }
        });
        let formattedTableRow: Record<string, any> = {
          key: result.key,
          initiative: result.campaign?.name,
          round: result.campaign ? Number(result.campaignRound) + 1 : undefined,
          id: result.key,
          isGenerated: false,
          isBenchmark: result.isBenchmark,
          experiment: result.key,
          source:
            FormulationSourceWithPreferred[
            result.source as keyof typeof FormulationSourceWithPreferred
            ],
          measured: result.isMeasured ? 'Yes' : 'No',
          cost: calculateFormulationCostV2(
            formulationIngredientItems,
            ingredients.filter(i => currentProject?.ingredientList.includes(i))
          ),
          expectedImprovementScore,
          desirability,
          formulation: result,
        };

        const ingredientCompositionTotals = calculateCompositionTotalsV2(
          formulationIngredientItems.filter(fi => currentProject?.ingredientList.map(i => i.ingredient.name).includes(fi.name)),
          ingredients.filter(i => currentProject?.ingredientList.includes(i)),
          currentProject?.ingredientComposition
        );
        ingredientCompositionTotals?.forEach(composition => {
          formattedTableRow[composition.compositionId] = limitDecimals(
            composition.total,
            4
          );
        });

        result.items.forEach(f => {
          const numberValue = Number(f.value);
          const formattedValue = isNaN(numberValue)
            ? f.value
            : limitDecimals(numberValue, 4);

          formattedTableRow[f.variable.name] = formattedValue;
        });

        return formattedTableRow;
      });
    }

    if (searchInput) {
      const lowercasedInput = searchInput.toLowerCase();
      tableData = tableData.filter(row => {
        if (row.isBenchmark) return true;
        return row.key.toLowerCase().includes(lowercasedInput);
      });
      if (tableData.every(row => row.isBenchmark)) tableData = [];
    }

    tableData.sort((a: Record<string, any>, b: Record<string, any>) => {
      const direction = sortOrder === 'asc' ? 1 : -1; // Determines sorting direction

      // Benchmarks are always sorted to the top
      if (a.isBenchmark && !b.isBenchmark) {
        return -1; // Benchmarks always come first
      }
      if (!a.isBenchmark && b.isBenchmark) {
        return 1;
      }

      // Special case for sorting by "experiment" (dates)
      if (sortBy === 'experiment') {
        const dateA = a.formulation.createdAt
          ? new Date(a.formulation.createdAt)
          : null;
        const dateB = b.formulation.createdAt
          ? new Date(b.formulation.createdAt)
          : null;

        if (dateA && dateB) {
          if (dateA > dateB) {
            return -1 * direction;
          }
          if (dateA < dateB) {
            return 1 * direction;
          }
        }

        // If one of the dates is null, sort it to the bottom
        if (!dateA) {
          return 1;
        }
        if (!dateB) {
          return -1;
        }
      }

      // Generated entries have priority when sorting by "experiment" or no specific sort field
      if (
        a.isGenerated &&
        !b.isGenerated &&
        (sortBy === 'experiment' || !sortBy)
      ) {
        return -1 * direction;
      }
      if (
        !a.isGenerated &&
        b.isGenerated &&
        (sortBy === 'experiment' || !sortBy)
      ) {
        return 1 * direction;
      }

      // Numeric sorting for fields with numeric values
      if (
        sortBy &&
        typeof a[sortBy] === 'number' &&
        typeof b[sortBy] === 'number'
      ) {
        return (a[sortBy] - b[sortBy]) * direction;
      }

      // Alphabetical sorting for fields with string values
      if (
        sortBy &&
        typeof a[sortBy] === 'string' &&
        typeof b[sortBy] === 'string'
      ) {
        return a[sortBy].localeCompare(b[sortBy]) * direction;
      }

      // Default: Maintain original order if no sorting criteria apply
      return 0;
    });
    setTableLoading(false);

    //workaround to get the variables project names
    const variables: string[] = (tableData[0]?.formulation.items.map((i: any) => i.variable.name) ?? []) as string[]
    setTableData(
      tableData.map(d => {
        Object.keys(d).forEach(key => {
          if (variables.includes(key) && d[key] === 'Missing') {
            d[key] = <Tag color="red">Missing</Tag>;
          }
        });
        return d;
      })
    );
  }, [
    projectFormulations,
    sortBy,
    sortOrder,
    tableKey,
    isolatedRows.length,
    selectedScenarios,
    selectedTestPlanToReview,
    tableColumns,
    customTestPlanFilteredId,
    filterCurrentCampaign,
    campaignFilteredId,
    roundSelectedId,
    formulationsToFilter,
    searchInput,
  ]);

  useEffect(() => {
    if (activeCommentKey) {
      const selectedFormulationToComment = tableData.find(
        data => data.formulation.key === activeCommentKey
      );

      if (selectedFormulationToComment) {
        setSelectedFormulationToComment(selectedFormulationToComment);
        setCommentPopoverVisible(true);

        navigate(`/project/${currentProject!.id}/experiments`);
      }
    }
  }, [activeCommentKey, tableData]);

  useEffect(() => {
    const outcomeColumns = outcomes?.map(outcome => ({
      title: () => (
        <div>
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <TagAndEllipsis
              targetVariable={outcome.targetVariable}
              latestDesign={latestDesign}
            />
          </div>
        </div>
      ),
      sourceRecord: outcome,
      className: `custom-column-class ${outcome.category?.name.toLocaleLowerCase()!}`,
      unit: outcome.unit === 'Not Applicable' ? '' : outcome.unit,
      dataIndex: outcome.targetVariable,
      key: outcome.targetVariable,
      ellipsis: true,
      category: outcome.category?.name,
      searchableText: outcome.targetVariable,
      columnType: 'outcome',
      visible:
        tableColumns.length > 0
          ? tableColumns.find(tc => tc.dataIndex === outcome.targetVariable)
            ?.visible
          : true,
      render: (value: any, record: any) => {
        const formulation = record.formulation;
        const hasConfidenceIntervals = formulation.items.some((item: any) =>
          item.metrics.some(
            (metric: any) =>
              metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
          )
        );
        const formulationItem = record.formulation.items.find(
          (f: any) => f.variable.name === outcome.targetVariable
        );
        let confidenceIntervals: FormulationItemMetric | undefined;
        let reliabilityPercentage: FormulationItemMetric | undefined;
        let desirability: FormulationItemMetric | undefined;

        if (formulationItem) {
          if (formulationItem.type === FormulationItemType.TARGET_PREDICTED) {
            if (hasConfidenceIntervals) {
              confidenceIntervals = formulationItem.metrics.find(
                (metric: any) =>
                  metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
              );
            }

            reliabilityPercentage = formulationItem.metrics.find(
              (metric: any) =>
                metric.type === FormulationItemMetricType.RELIABILITY
            );

            desirability = formulationItem.metrics.find(
              (metric: any) =>
                metric.type === FormulationItemMetricType.DESIRABILITY
            );
          }
        }

        const content = hasConfidenceIntervals ? (
          <>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              {value}
            </div>
            {hasConfidenceIntervals && enableConfidenceIntervals && (
              <ConfidenceIntervalText
                confidenceInterval={confidenceIntervalsStringToArray(
                  confidenceIntervals?.value
                )}
                reliabilityPercentage={convertReliabilityPercentage(
                  reliabilityPercentage?.value
                )}
              />
            )}
          </>
        ) : (
          <span>{value}</span>
        );

        const contentToRender = hasConfidenceIntervals ? (
          <Popover content={content}>{content}</Popover>
        ) : (
          content
        );

        if (desirability) {
          if (enableTags) {
            const tagColor = getTagColor(Number(desirability.value));
            return <Tag color={tagColor}>{contentToRender}</Tag>;
          } else {
            return <div>{contentToRender}</div>;
          }
        }

        return contentToRender;
      },
    }));

    const ingredientCompositionColumns = currentProject?.ingredientComposition.map(
      composition => ({
        title: () => (
          <div>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              <Ellipsis charCount={15}>{composition.name}</Ellipsis>
            </div>
          </div>
        ),
        sourceRecord: composition,
        unit: '%',
        onFilter: (
          filterValue: NumericFilterValues,
          record: CustomRecordType
        ) => handleRangeFilter(filterValue, record, composition.id),
        className: 'custom-column-class ingredient',
        dataIndex: composition.id,
        key: composition.id,
        searchableText: composition.name,
        ellipsis: true,
        columnType: 'composition',
        category: 'compositions',
        visible:
          tableColumns.length > 0
            ? tableColumns.find(tc => tc.dataIndex === composition.id)?.visible
            : true,
        render: (value: string, record: CustomRecordType) => {
          return (
            <span className="custom-column custom-column-item">{value}%</span>
          );
        },
      })
    );

    const ingredientColumns = ingredients
      .filter(i => i.isActive)
      .filter(i => currentProject?.ingredientList.includes(i))
      .map(i => ({
        title: () => (
          <div
            style={{
              boxSizing: 'content-box',
            }}
          >
            <div
              className="result-table-header"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                textAlign: 'center',
              }}
            >
              {i.ingredient.name}
            </div>
          </div>
        ),
        sourceRecord: i,
        dataIndex: i.ingredient.name,
        key: i.ingredient.name,
        searchableText: i.ingredient.name,
        unit: i.unit === 'Not Applicable' ? '' : i.unit,
        ellipsis: true,
        className: !selectedTestPlanToReview
          ? `custom-column-class
            ${i.filler ? 'filler' : i.processing ? 'processing' : 'ingredient'}`
          : undefined,
        category: i.category.name,
        columnType: 'ingredient',
        visible:
          tableColumns.length > 0
            ? tableColumns.find(tc => tc.dataIndex === i.ingredient.name)
              ?.visible
            : true,
        render: (value: string, record: CustomRecordType) => {
          const renderValue =
            i.type !== VariableType.NUMERIC ? (
              <span
                className="custom-column"
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                <EllipsisMiddle suffixCount={6}>
                  {value + i.unit ?? '%'}
                </EllipsisMiddle>
              </span>
            ) : (
              <span
                className="custom-column"
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                {value + i.unit}
              </span>
            );

          if (record.status === 'Unmatched' && record.parentFormulationValues) {
            const parentFormulationValue = record.parentFormulationValues.get(
              i.ingredient.name
            );

            if (
              parentFormulationValue !== undefined &&
              parentFormulationValue !== value
            ) {
              const updatedSelectedTestPlanToReview = testPlansWithFormulations.find(
                testPlan => testPlan?.id === selectedTestPlanToReview?.id
              );
              const hasNote = updatedSelectedTestPlanToReview?.latestUpload?.notes.find(
                note =>
                  note.formulationId === record.formulation.id &&
                  note.ingredientId === i.ingredient.id
              );

              if (hasNote) {
                return (
                  <Flex align="center" gap={6}>
                    {renderValue}
                    <div>
                      <CheckOutlined style={{ color: '#52C41A' }} />
                    </div>
                  </Flex>
                );
              }

              return {
                props: {
                  style: { backgroundColor: '#FEE0D5E6' },
                },
                children: (
                  <Flex align="center" style={{ color: '#EF4136' }}>
                    {renderValue}
                    <Popover
                      content={
                        <TestPlanNotesModal
                          ingredientId={i.ingredient.id}
                          ingredientName={i.ingredient.name}
                          newValue={value}
                          originalValue={parentFormulationValue}
                          testPlan={selectedTestPlanToReview!}
                          formulationId={record.formulation.id}
                          formulationKey={record.formulation.key}
                        />
                      }
                      trigger="click"
                    >
                      <Button
                        size="small"
                        type="link"
                        icon={<InfoCircleOutlined />}
                      />
                    </Popover>
                  </Flex>
                ),
              };
            }
          }

          return renderValue;
        },
      }));

    const columns = [
      {
        title: selectedTestPlanToReview?.latestUpload ? (
          <Flex vertical justify="space-between">
            <div>
              <Button
                type="link"
                size="small"
                icon={<SummaryCloseIcon />}
                onClick={() => setSelectedTestPlanToReview(undefined)}
              />
              <div
                css={css`
                  color: rgba(26, 54, 63, 0.45);
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px;
                `}
              >
                {selectedTestPlanToReview?.latestUpload?.file?.name}
              </div>
            </div>
            <div>Experiment</div>
          </Flex>
        ) : (
          'Experiment'
        ),
        dataIndex: 'experiment',
        key: 'experiment',
        className: 'custom-column-class-experiment',
        width: 300,
        visible: true,
        // filters: projectFormulations
        //   .filter(result => result.status === FormulationStatus.PINNED)
        //   .map(result => ({
        //     text: result.key,
        //     value: result.key,
        //   })),
        // filterIcon: () => null,
        // filterSearch: true,
        filteredValue:
          isolatedRows.length > 0 ? isolatedRows.map(key => key) : undefined,
        onFilter: (value: string, record: CustomRecordType) =>
          handleTextFilter(String(value), record, 'experiment', true),
        fixed: 'left',
        render: (value: string, record: CustomRecordType) => {
          const allComments: CommentThread[] = allProjectComments ?? [];

          const formulationComments = allComments

            .filter(thread => thread.formulationId === record.formulation.id)
            .flatMap(thread => thread.comments);

          const hasComments = formulationComments.length > 0;

          let tooltipTitle = 'Comment on this experiment';

          if (hasComments) {
            tooltipTitle =
              (formulationComments.length === 1
                ? `1 comment`
                : `${formulationComments.length} comments`) +
              ' on this experiment';
          }

          return (
            <Flex justify="space-between">
              {/* <ExperimentPopover formulation={record.formulation}> */}
              <div
                style={{
                  cursor: 'pointer',
                  overflow: 'hidden',
                  marginRight: '8px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => {
                  logEvent(TrackableEvent.EXECUTE_SOLUTION_VIEW_FORMULATION);
                  setSelectedFormulationDetails(record);
                }}
              >
                <Flex align="center" justify="space-between">
                  <Tooltip title={record?.formulation?.name}>
                    <div
                      style={{
                        fontWeight: 300,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {record?.formulation?.name ?? value}
                    </div>
                  </Tooltip>
                </Flex>
              </div>
              {/* </ExperimentPopover> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                }}
              >
                {record.formulation.isBenchmark && (
                  <Tooltip
                    title={
                      record.formulation.isBenchmark ? 'Benchmark' : undefined
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
                <Tooltip title={tooltipTitle}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCommentIndicatorOnClick(record)}
                  >
                    {formulationComments.length === 0 ? (
                      <CommentOutlined />
                    ) : (
                      <Badge color="blue" count={formulationComments.length} />
                    )}
                  </Flex>
                </Tooltip>
              </div>

              {record.source === FormulationSourceWithPreferred.SUGGESTED && (
                <GreenIndicator />
              )}
            </Flex>
          );
        },
      },
      ...(!selectedTestPlanToReview
        ? [
          {
            title: () => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                Source
              </div>
            ),
            dataIndex: 'source',
            key: 'source',
            width: 75,
            visible: true,
            // filters: Object.values(FormulationSourceWithPreferred).map(v => ({
            //   text: startCase(lowerCase(v)),
            //   value: v,
            // })),
            // filterIcon: <FilterOutlined />,
            // filterSearch: true,
            // onFilter: (value: string, record: CustomRecordType) =>
            //   handleTextFilter(String(value), record, 'source'),
            render: (value: string, record: CustomRecordType) =>
              record.formulation.formulationFeedback.some(
                (feedback: { type: string; isActive: any }) =>
                  feedback.type === 'POSITIVE' && feedback.isActive
              ) ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tag color="default">PREFERRED</Tag>
                </div>
              ) : record.source ===
                FormulationSourceWithPreferred.SUGGESTED ? (
                // We only want to display the popover for suggested since it's the only type with a related design job
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SourcePopover
                    formulation={record.formulation}
                    onClick={handleFilterByScenario}
                    setSelectedScenarios={setSelectedScenarios}
                    selectedScenarios={selectedScenarios}
                  >
                    <Tag color="default">{value}</Tag>
                  </SourcePopover>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tag color="default">{value}</Tag>
                </div>
              ),
          },
          ...[
            {
              title: () => <div>Desirability (0-1)</div>,
              dataIndex: 'desirability',
              key: 'desirability',
              width: 75,
              className: 'turing-desirability-title',
              visible: true,
              // sorter: (a: CustomRecordType, b: CustomRecordType) =>
              //   a.desirability - b.desirability,
              render: (value: number, record: CustomRecordType) => {
                let color = '';
                let label = '';

                if (value > 0 && value < 0.25) {
                  color = 'red';
                } else if (value >= 0.25 && value < 0.5) {
                  color = 'orange';
                } else if (value >= 0.5 && value < 0.75) {
                  color = 'yellow';
                } else if (value >= 0.75) {
                  color = 'green';
                }

                return enableTags ? (
                  <Tag color={color}>{value}</Tag>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {value}
                  </div>
                );
              },
            },
            {
              title: () => (
                <div
                  style={{ justifyContent: 'center', textAlign: 'center' }}
                >
                  Initiative
                </div>
              ),
              dataIndex: 'initiative',
              key: 'initiative',
              visible: true,
              className: 'custom-column-class',
              width: 700,
              // filters: Array.from(
              //   new Set(projectFormulations.map(f => f.campaign?.name))
              // )
              //   .filter(name => name)
              //   .map(name => ({
              //     text: name,
              //     value: name,
              //   })),
              // onFilter: (value: string, record: CustomRecordType) =>
              //   handleTextFilter(String(value), record, 'initiative'),
              // filterIcon: () => null,
              // filterSearch: true,
              render: (value: string, record: CustomRecordType) =>
                enableTags ? <Tag>{value} </Tag> : <div>{value}</div>,
            },
            {
              title: () => (
                <div
                  style={{ justifyContent: 'center', textAlign: 'center' }}
                >
                  Round
                </div>
              ),
              dataIndex: 'round',
              key: 'round',
              width: 700,
              visible: true,
              //filters: [],
              className: 'custom-column-class',
              // filterIcon: () => null,
              // filterSearch: true,
              // onFilter: (value: string, record: CustomRecordType) =>
              //   handleTextFilter(String(value), record, 'round'),
              render: (value: string, record: CustomRecordType) => (
                <div>{value}</div>
              ),
            },
          ],
        ]
        : [
          {
            title: () => 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 75,
            visible: true,
            filters: ['Matched', 'Unmatched'].map(v => ({
              text: v,
              value: v,
            })),
            filterIcon: () => null,
            onFilter: (value: string, record: CustomRecordType) =>
              handleTextFilter(String(value), record, 'status'),
            render: (value: string, record: CustomRecordType) => (
              <Tag color={value === 'Matched' ? 'green' : 'default'}>
                {value}
              </Tag>
            ),
          },
        ]),
      ...(pricingFeature && !selectedTestPlanToReview
        ? [
          {
            title: () => (
              <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                Cost
              </div>
            ),
            dataIndex: 'cost',
            key: 'cost',
            width: 75,
            className: 'custom-column-class',
            visible: true,
            // filterDropdown: CustomRangeFilterDropdown,
            // onFilter: (filterValue: NumericFilterValues, record: any) =>
            //   handleRangeFilter(filterValue, record, 'cost'),
            // filterIcon: () => null,
            // filterSearch: true,
            render: (value: number, record: CustomRecordType) => {
              return formatCostScore(
                value ?? 0,
                currentProject?.costMeasurementUnit,
                currentProject?.monetaryUnit
              );
            },
          },
        ]
        : []),
      ...(ingredientColumns ?? []),
      ...(ingredientCompositionColumns && !selectedTestPlanToReview
        ? ingredientCompositionColumns
        : []),
      ...((!selectedTestPlanToReview ? outcomeColumns : []) ?? []),
    ];

    setTableColumns(columns);
  }, [
    projectFormulations,
    isolatedRows.length,
    latestDesign,
    selectedTestPlanToReview,
    testPlansWithFormulations,
    allProjectComments,
    enableTags,
    enableConfidenceIntervals,
    tableFilters,
  ]);

  useEffect(() => {
    const hasModelRebuildLoading = testPlansWithFormulations.find(testPlan => {
      const status = testPlan?.latestUpload?.modelRebuildProjectJob?.status;
      return status === JobStatus.IN_PROGRESS || status === JobStatus.PENDING;
    });

    setShowModelRebuildRunningMessage(hasModelRebuildLoading !== undefined);
  }, [testPlansWithFormulations]);

  const handleCommentIndicatorOnClick = (record: CustomRecordType) => {
    setSelectedFormulationToComment(record);
    setCommentPopoverVisible(true);
  };

  const handleCommentModalOnClose = () => {
    setCommentPopoverVisible(false);
    refetchAllProjectComments();
  };

  const onRowSelectChange = (newSelectedRowKeys: React.Key[], records: any) => {
    setSelectedRowKeys(records.map((r: any) => r.experiment));
  };
  const selectedScenarioSet = new Set(selectedScenarios);

  const outcomes = currentProject?.activeModel?.outcomes.sort(
    (a, b) => Number(a?.displayOrder) - Number(b?.displayOrder)
  );

  const handleFilterByScenario = (designId: string) => {
    setFilterByScenarioModal(true);
    setSelectedScenarios([...selectedScenarios, designId]);
  };

  const handleUpdateTestPlanFormulations = async (
    formulationKey: string,
    testPlanId: string,
    action: 'ADD' | 'REMOVE'
  ) => {
    const testPlan = testPlansWithFormulations.find(
      testPlan => testPlan?.id === testPlanId
    );

    const currentFormulationIds =
      testPlan?.formulations.map(formulation => formulation.id) ?? [];

    let updatedFormulationIds: string[] = [...currentFormulationIds];

    const formulationKeysToModify =
      selectedRowKeys.length !== 0 && action === 'ADD'
        ? selectedRowKeys
        : [formulationKey];

    for (const formulationKey of formulationKeysToModify) {
      const formulationToModify = projectFormulations.find(
        formulation => formulation.key === formulationKey
      );

      if (!formulationToModify) {
        return;
      }

      if (action === 'ADD') {
        updatedFormulationIds.push(formulationToModify.id);
      } else {
        updatedFormulationIds = updatedFormulationIds.filter(
          formulationId => formulationId !== formulationToModify.id
        );
      }
    }

    if (testPlan) {
      const updatedTestPlan = await updateTestPlan({
        variables: {
          testPlanId: testPlan.id,
          name: testPlan.name,
          updatedFormulationIds,
        },
      });

      const testPlanIndexToUpdate = testPlansWithFormulations.findIndex(
        testPlan => testPlan?.id === testPlanId
      );

      if (
        updatedTestPlan.data?.updateOneTestPlan.formulations &&
        testPlanIndexToUpdate > -1
      ) {
        const updatedTestPlansWithFormulations = [...testPlansWithFormulations];
        updatedTestPlansWithFormulations[testPlanIndexToUpdate] =
          updatedTestPlan.data.updateOneTestPlan;

        setTestPlansWithFormulations(updatedTestPlansWithFormulations);
      }
    }
  };

  const handleOnReviewTestPlan = (
    selectedTestPlan: TestPlanWithFormulationsType
  ) => {
    setSelectedTestPlanToReview(selectedTestPlan);
  };

  const handleUpdateModel = async () => {
    if (selectedTestPlanToReview?.latestUpload) {
      try {
        await triggerTestPlanModelRebuild({
          variables: {
            testPlanUploadId: selectedTestPlanToReview.latestUpload.id,
          },
        });
        await getTestPlansWithFormulations(selectedIteration!.id);
        setShowModelRebuildRunningMessage(true);
        setSelectedTestPlanToReview(undefined);
      } catch (error: any) {
        notification.error({
          message: 'Error rebuilding model',
          description: error.message,
        });
      }
    } else {
      notification.error({
        message: 'There was an issue rebuilding the model',
      });
    }
  };

  const handleCheckJobStatus = async () => {
    const testPlanWithPendingRebuild = testPlansWithFormulations.find(
      testPlan => {
        const status = testPlan?.latestUpload?.modelRebuildProjectJob?.status;
        return status === JobStatus.IN_PROGRESS || status === JobStatus.PENDING;
      }
    );

    const latestJob =
      testPlanWithPendingRebuild?.latestUpload?.modelRebuildProjectJob;

    if (latestJob) {
      const projectJobStatusResponse = await pollProjectJobStatus({
        variables: {
          projectJobId: latestJob.id,
          projectJobType: ProjectJobType.CUSTOM_MODEL_BUILDING,
        },
      });

      if (
        projectJobStatusResponse.data?.pollProjectJobStatus.status ===
        JobStatus.SUCCESS
      ) {
        setShowModelRebuildCompleteMessage(true);
        setShowModelRebuildRunningMessage(false);
      }
    }
  };

  const onClearFilters = () => {
    setChartFilters([]);
    setFormulationsToFilter([]);
  };

  return (
    <>
      <Flex>
        <div
          style={{
            zIndex: '99',
          }}
          css={css`
            border-top: 1px solid #f4f4f4;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 10px 10px;
            border: 1px solid #e0e0e0;
            box-shadow: 0 2px 2px rgba(140, 140, 140, 0.1);
            border-radius: 20px;
            position: sticky;
            height: calc(100vh - 88px);
          `}
        >
          <TestPlanComponentV2
            handleOnFormulationDrop={handleUpdateTestPlanFormulations}
            handleOnReviewTestPlan={handleOnReviewTestPlan}
            setFilterCurrentCampaign={setFilterCurrentCampaign}
            filterCurrentCampaign={filterCurrentCampaign}
            campaignFilteredId={campaignFilteredId}
            setCampaignFilteredId={setCampaignFilteredId}
            roundSelectedId={roundSelectedId}
            setRoundSelectedId={setRoundSelectedId}
            customTestPlanFilteredId={customTestPlanFilteredId}
            setCustomTestPlanFilteredId={setCustomTestPlanFilteredId}
          />
          <div
            css={css`
              position: sticky;
              bottom: 0;
              left: 0;
              right: 0;
              background: white;
              border-top: 1px solid #e0e0e0;
              padding: 10px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
              z-index: 10;
              border-radius: 0px 0px 20px 20px;
            `}
          >
            <div>
              <SearchBar
                data={tableData.map(f => f.key)}
                onSearch={setSearchInput}
              />
            </div>
            <MetricsDropdown
              outcomes={currentProject?.activeModel?.outcomes}
              compositions={currentProject?.ingredientComposition ?? []}
              ingredients={ingredients.filter(i => currentProject?.ingredientList.includes(i))}
              tableColumns={tableColumns}
              setTableColumns={setTableColumns}
            />
            {viewType === ViewType.TABLE && (
              <SortByDropdown
                onChange={(value: string) => setSortBy(value)}
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 4,
              }}
            >
              <FilterDropdownV2
                chartFilters={chartFilters}
                setChartFilters={setChartFilters}
                filterMenuVisible={filterMenuVisible}
                setFilterMenuVisible={setFilterMenuVisible}
                tableColumns={tableColumns}
                chartData={chartData ?? []}
                formulationsToFilter={formulationsToFilter}
                setFormulationsToFilter={setFormulationsToFilter}
                onClearFilters={onClearFilters}
                tableData={tableData}
                tableDataForFilter={tableDataForFilter}
              />
              {chartFilters.length > 0 && (
                <Button danger onClick={() => onClearFilters()}>
                  Clear Filters
                </Button>
              )}
            </div>
            {selectedRowKeys.length >= 1 && (
              <>
                <IsolateButton
                  disabled={selectedRowKeys.length < 2}
                  onClick={handleIsolateTableRows}
                  numberOfRows={
                    tableFilters?.experiment?.length || selectedRowKeys?.length
                  }
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => (setSelectedRowKeys([]), setIsolatedRows([]))}
                >
                  {` ${selectedRowKeys.length} Selected`}
                </Button>
                <Button onClick={() => setShowDeleteFormulationModal(true)}>
                  Delete Selected Formulations
                </Button>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            width: showCopilot ? 'calc(100% - 450px)' : 'inherit',
          }}
        >
          <div
            style={{
              fontSize: '20px',
              fontWeight: 600,
              justifyContent: 'space-between',
              padding: '0px 125px 0px 50px',
              width: '-webkit-fill-available',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '50px',
            }}
          >
            <span
              style={{
                flex: 1,
                maxWidth: '70%',
              }}
            >
              {currentProject?.name}
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'flex-end',
              }}
            >
              {tableData?.length > 0 && (
                <>
                  {isEnableFeature[ProductFeatureType.COPILOT] && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Switch
                        checked={showCopilot}
                        onChange={() => setShowCopilot(!showCopilot)}
                        // size='small'
                        checkedChildren="Luna"
                        unCheckedChildren="Luna"
                        style={{
                          backgroundColor: showCopilot ? 'red' : undefined,
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    {/* <p style={{ fontSize: 'small', margin: 0 }}>Confidence intervals</p> */}
                    <Switch
                      checked={enableConfidenceIntervals}
                      onChange={() =>
                        setEnableConfidenceIntervals(!enableConfidenceIntervals)
                      }
                      // size='small'
                      checkedChildren="Confidence intervals"
                      unCheckedChildren="Confidence intervals"
                      style={{
                        backgroundColor: enableConfidenceIntervals
                          ? 'red'
                          : undefined,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Switch
                      checked={enableTags}
                      onChange={() => setEnableTags(!enableTags)}
                      checkedChildren="Desirability Categories"
                      unCheckedChildren="Desirability Categories"
                      style={{
                        backgroundColor: enableTags ? 'red' : undefined,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Flex
              vertical
              className={
                selectedTestPlanToReview || showModelRebuildRunningMessage
                  ? 'ES-item-container-review'
                  : 'ES-item-container'
              }
            >
              {selectedTestPlanToReview && (
                <div
                  css={css`
                    background: rgba(22, 31, 38, 0.5);
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    padding: 10px 20px;
                  `}
                >
                  <QuestionCircleOutlined style={{ color: '#fff' }} />
                  <div
                    css={css`
                      color: #fff;
                      font-family: Inter;
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    Great news! Test results are in. Click to power up your
                    project with fresh data!
                  </div>
                  <Button
                    style={{
                      color: '#FF4D4F',
                      borderColor: '#FF4D4F',
                      width: 84,
                    }}
                    onClick={() => handleUpdateModel()}
                    loading={modelRebuildLoading}
                    size="small"
                  >
                    Update
                  </Button>
                </div>
              )}
              {showModelRebuildCompleteMessage && (
                <div
                  css={css`
                    background: rgba(22, 31, 38, 0.5);
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    padding: 10px 20px;
                  `}
                >
                  <CheckOutlined style={{ color: '#90E965' }} />
                  <div
                    css={css`
                      color: #fff;
                      font-family: Inter;
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    Voila! Your project update is complete! 🎉 Take a look below
                    to see the new, highlighted data added just for you. Dive in
                    and discover the latest insights!
                  </div>
                  <Button
                    style={{ backgroundColor: '#F6FFED' }}
                    onClick={() => setShowModelRebuildCompleteMessage(false)}
                    size="small"
                  >
                    Close
                  </Button>
                </div>
              )}
              {showModelRebuildRunningMessage && (
                <div
                  css={css`
                    background: rgba(22, 31, 38, 0.5);
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    padding: 10px 20px;
                  `}
                >
                  <SyncOutlined onClick={() => handleCheckJobStatus()} />
                  <div
                    css={css`
                      color: #fff;
                      font-family: Inter;
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    Get ready for a project upgrade! We're on it, updating as we
                    speak. Keep an eye out for an email notification, and watch
                    this space for the exciting update!
                  </div>
                  <Button
                    style={{ backgroundColor: '#F6FFED' }}
                    onClick={() => setShowModelRebuildRunningMessage(false)}
                    size="small"
                  >
                    Close
                  </Button>
                </div>
              )}

              <Table
                key={tableKey}
                components={{
                  body: {
                    row: DraggableTableRow,
                  },
                }}
                loading={
                  formulationsFetching ||
                  (tableData.length <= 1 &&
                    !selectedTestPlanToReview &&
                    !searchInput &&
                    !customTestPlanFilteredId &&
                    tableLoading)
                }
                className="ice-cream-results-table"
                rowSelection={{
                  selectedRowKeys,
                  onChange: onRowSelectChange,
                }}
                columns={
                  tableColumns.filter(c => c.visible) as ColumnType<
                    Record<any, any>
                  >[]
                }
                locale={{
                  emptyText: (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '112px',
                        gap: '12px',
                        margin: '0 auto',
                      }}
                    >
                      <Empty description="No formulations match your criteria." />
                      {/* <Button onClick={resetAllTable}>Clear Filters</Button> */}
                    </div>
                  ),
                }}
                dataSource={tableData}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 30,
                }}
                rowClassName={record => {
                  return record.formulation.isBenchmark ? 'benchmark-row' : '';
                }}
                scroll={{ x: 'max-content' }}
                tableLayout="auto"
                onChange={(e, filters) => {
                  // Isolate rows makes the filter controlled outside the table. We want to move the control back to the table if they attempt to use the filter instead of the button

                  // if (filters.experiment?.length !== isolatedRows.length) {
                  //   setIsolatedRows([]);
                  //   setSelectedRowKeys([]);
                  // }

                  setTableFilters(filters);
                }}
              />
            </Flex>
          </div>
          <ScenarioFilterModal
            open={openFilterByScenarioModal}
            onClose={() => {
              setFilterByScenarioModal(false);
              setSelectedScenarios([]);
            }}
            onOk={() => {
              setFilterByScenarioModal(false);
            }}
            selectedScenarios={selectedScenarios}
            setSelectedScenarios={setSelectedScenarios}
          />
        </div>
        {showCopilot && <CopilotChat />}
        {selectedFormulationToComment && (
          <CommentModal
            formulationKey={selectedFormulationToComment.formulation.key}
            formulationId={selectedFormulationToComment.formulation.id}
            open={commentPopoverVisible}
            onClose={() => handleCommentModalOnClose()}
          />
        )}
      </Flex>
      {selectedFormulationDetails && (
        <FormulationDetailsModal
          handleClose={() => setSelectedFormulationDetails(undefined)}
          selectedFormulationDetails={selectedFormulationDetails}
        />
      )}
    </>
  );
};

import { DropDownOptions } from '../../components/library/library';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

interface DroppedItem {
  id: string;
  name: string;
  type: DropDownOptions;
}

interface DragDropContextType {
  draggedItemId: string | null;
  dropTargetId: string | null; // ID of the drop target
  droppedItems: DroppedItem[]; // List of items dropped on the target
  droppedIngredients: DroppedItem; // List of items dropped on the target
  filterIngredients: any;
  filterProcessingConditions: any;
  droppedProcessingConditions: DroppedItem;

  setDraggedItemId: (id: string | null) => void;
  setDropTargetId: (id: string | null) => void;
  addDroppedItem: (item: DroppedItem) => void;
  resetState: () => void; // Reset state (dragged item, drop target, etc.)
  removeDroppedItem: (id: string) => void;
  addDroppedIngredients: (item: DroppedItem) => void;
  addFilterIngredients: (item: any, clear?: boolean) => void;
  removeFilterIngredients: (item: any) => void;
  addFilterProcessingConditions: (item: any, clear?: boolean) => void;
  removeFilterProcessingConditions: (item: any) => void;
}

const DragDropContext = createContext<DragDropContextType | undefined>(
  undefined
);

export const DragDropProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);
  // const [droppedIngredients, setDroppedIngredients] = useState<string | null>(null);
  const [dropTargetId, setDropTargetId] = useState<string | null>(null);
  const [droppedItems, setDroppedItems] = useState<DroppedItem[]>([]);

  const [droppedIngredients, setDroppedIngredients] = useState<DroppedItem>();
  const [filterIngredients, setFilterIngredients] = useState<string[]>([]);
  const [
    droppedProcessingConditions,
    setDroppedProcessingConditions,
  ] = useState<DroppedItem>();
  const [filterProcessingConditions, setFilterProcessingConditions] = useState<
    string[]
  >([]);

  const addDroppedItem = useCallback((item: DroppedItem) => {
    setDroppedItems(prevItems => [...prevItems, item]);
  }, []);
  const removeDroppedItem = useCallback((id: string) => {
    setDroppedItems(prevItems => prevItems.filter(item => item.id !== id));
  }, []);

  const addDroppedIngredients = useCallback((item: DroppedItem) => {
    setDroppedIngredients(item);
  }, []);

  const resetState = useCallback(() => {
    setDraggedItemId(null);
    setDropTargetId(null);
  }, []);

  const addFilterIngredients = useCallback(
    (item: any, clear: boolean = false) => {
      if (clear) {
        setFilterIngredients(prevItems => [...prevItems, item]);
      } else {
        setFilterIngredients(item);
      }
    },
    []
  );

  const removeFilterIngredients = useCallback((itemToRemove: any) => {
    setFilterIngredients(prevItems =>
      prevItems.filter(item => item !== itemToRemove)
    );
  }, []);

  const addDroppedProcessingConditions = useCallback((item: DroppedItem) => {
    setDroppedProcessingConditions(item);
  }, []);

  const addFilterProcessingConditions = useCallback(
    (item: any, clear: boolean = false) => {
      if (clear) {
        setFilterProcessingConditions(prevItems => [...prevItems, item]);
      } else {
        setFilterProcessingConditions(item);
      }
    },
    []
  );

  const removeFilterProcessingConditions = useCallback((itemToRemove: any) => {
    setFilterProcessingConditions(prevItems =>
      prevItems.filter(item => item !== itemToRemove)
    );
  }, []);

  return (
    <DragDropContext.Provider
      value={{
        draggedItemId,
        dropTargetId,
        droppedItems,
        droppedIngredients,
        filterIngredients,
        filterProcessingConditions,
        setDraggedItemId,
        setDropTargetId,
        addDroppedItem,
        resetState,
        removeDroppedItem,
        addDroppedIngredients,
        addFilterIngredients,
        removeFilterIngredients,
        addFilterProcessingConditions,
        removeFilterProcessingConditions,
        addDroppedProcessingConditions,
      }}
    >
      {children}
    </DragDropContext.Provider>
  );
};

export const useDragDropContext = () => {
  const context = useContext(DragDropContext);
  if (!context) {
    throw new Error(
      'useDragDropContext must be used within a DragDropProvider'
    );
  }
  return context;
};

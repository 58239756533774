
import React, { useState } from "react";
// @ts-ignore
import styles from "./dataset-upload.module.less";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { css } from "@emotion/react";
import { UploadDatasetModal } from "./dataset-upload-modal";
export const DataSetUpload = ({ handleComplete }: { handleComplete: () => void }) => {

  const [open, setOpen] = useState(false)
  const { t } = useTranslation();


  return (
    <div className={styles.dataset}>
      <Button title={t('dataset-upload.title')} className={styles.button} type="primary" onClick={() => setOpen(true)}>
        {t('dataset-upload.title')}
      </Button>
      <UploadDatasetModal open={open} handleOnUploadComplete={handleComplete} setOpenUploadModal={setOpen}></UploadDatasetModal>
    </div >
  );
};

import React, { FC, useEffect, useState } from 'react';
import './edit-data.less';
import {
  Flex,
  Spin,
  Table,
  TableProps,
  Typography,
} from 'antd';

import {
  useSession,
} from '../../../../../_shared/context';
import { RadioChangeEvent } from 'antd/lib';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { parse } from 'papaparse';
import {
  useDeleteFormulationRaw,
  useGetProjectFormulationsRaw,
  useUpdateProjectFromulationRaw,
  useUpdateProjectFromulationItemRaw
} from '../../../../../network/services/project.service';

import {
  roundFormulations,
  useFormulations,
} from '../../../../../_shared/context/formulations-context';
import { Option } from 'antd/lib/mentions';
import BlockUi from 'react-block-ui';
import { ProjectType } from '@prisma/client';
import { log } from 'console';
import { useGetDatasetFormulations } from '../../../../../network/services/dataset.service';
import moment from 'moment';


export const DatasetFormulationTable = ({
  datasetId,
  organizationId
}: {
  datasetId: string,
  organizationId: string
}) => {
  const [formulationsDataset, setFormulationsDataset] = useState([]);
  const [columnsTable, setColumnsTable] = useState<TableProps<any>['columns']>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [formulationsDataTable, setformulationsDataTable] = useState<any>([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const {
    data,
    isLoading,
    isSuccess,
    refetch,
    isError,
  } = useGetDatasetFormulations({
    datasetId: datasetId,
    org_id: organizationId,
    pageSize,
    page
  });

  useEffect(() => {
    if (data && data.status && (isSuccess || isError)) {
      setLoading(false);
    }
  }, [isSuccess, isError, data]);

  useEffect(() => {
    if (data && data.status) {
      const formulations = data.data;
      setFormulationsDataset(formulations);
      setTotal(data.data.total);
      let benchmark = false;
      const formulation = formulations[0];

      const columns: TableProps<any>['columns'] = [
        {
          title: 'FormulationID',
          dataIndex: 'FormulationID',
          key: 'FormulationID',
        },
        {
          title: 'Type',
          dataIndex: 'Type',
          key: 'Type',
        },
      ];


      if (formulation) {
        formulation.formulationItemsDataSet?.map((fi: any) => {
          columns.push({
            title: fi.name,
            dataIndex: fi.name,
            key: fi.name,
            render: (value, record) => {
              return record[fi.name].value
            }
          });
        });
      }


      columns.push(
        {
          title: 'Uploaded At',
          dataIndex: 'updatedAt',
          key: 'updatedAt'
        }
      );

      setColumnsTable(columns);

      const dataTable: any[] = [];
      if (formulations?.length > 0) {
        formulations.map((f: any) => {
          let row: any = {
            id: f.id,
            FormulationID: f.name,
            Type: f.type,
            cost: f.cost,
            updatedAt: new Date(f.updatedAt).toLocaleDateString()
          };

          f.formulationItemsDataSet.map((fi: any) => {
            row[fi.name] = { value: fi.value, id: fi.id };
          });

          if (!benchmark) benchmark = f.isBenchmark;
          dataTable.push(row);
        });
      }


      setformulationsDataTable(dataTable);
    }
  }, [data]);

  const handlePage = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    refetch();
  };

  return (
    <Flex vertical gap={12}>

      <div id="project-edit-data-box">
        {loading && (
          <BlockUi
            tag="div"
            blocking={loading}
            loader={<Spin></Spin>}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100vh',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        {columnsTable && formulationsDataTable && (
          <Table
            columns={columnsTable}
            dataSource={formulationsDataTable}
            pagination={{
              total,
              onChange: handlePage,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
            }}
          />
        )}
      </div>
    </Flex>
  );
};

import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { useScenarioDetail } from "../../../_shared/context/scenario-detail-context";
import React, { ReactNode, useEffect, useState } from "react";
import { Card, Input, InputNumber, Modal, notification, Switch, Table, Tabs, Tooltip, Typography } from "antd";
import '../exploration-constraint-setting-v3/exploration-constraints-settings-v3.less';
import { ConstraintInputType, ConstraintReason, CostOptimizationOption, InputMaybe, usedeleteIngredientCompositionMutation } from "../../../../../__generated__/globalTypes";
import { deleteIngredientGroup, useGetIngredientsGroup } from "../../../network/services/ingredient-group.service";
import { useSession } from "../../../_shared/context";
import { useGetIngredientsComposition } from "../../../network/services/ingredient-composition.service";
import { RenderFunction } from "antd/es/_util/getRenderPropValue";

const { Text, Paragraph } = Typography;
const { confirm } = Modal;


export const ConstraintList = (
  {
    editingConstraint,
    setEditingConstraint
  }:
    {
      editingConstraint: ConstraintInputType | undefined;
      setEditingConstraint: React.Dispatch<React.SetStateAction<ConstraintInputType | undefined>>;
    }
) => {

  const {
    user,
    currentProject,
    ingredientsGroupList,
    setIngredientGroupList
  } = useSession();

  const deleteIngredientGroupMutation = deleteIngredientGroup();

  const {
    constraints,
    removeConstraint,
    costOptimizationOption,
    nteCost
  } = useScenarioDetail();

  const [deleteComposition] = usedeleteIngredientCompositionMutation();

  const {
    data: ingredientsGroup,
    isSuccess: ingredientGroupIsSuccess,
    refetch: refetchIngredientsGroups
  } = useGetIngredientsGroup({
    projectId: currentProject?.id,
    organizationId: user?.organizationId,
  });

  const {
    data: ingredientsCompositions,
    isSuccess: ingredientsCompositionsIsSuccess,
    refetch: refetchIngredientsCompositions
  } = useGetIngredientsComposition({
    projectId: currentProject?.id,
    organizationId: user?.organizationId,
  });

  const [constraintsData, setConstraintsData] = useState<
    {
      key: string;
      constraintName: { name: string | undefined, reason: any };
      user: string;
      reduction: React.JSX.Element | number;
      delete: { c: ConstraintInputType };
    }[]
  >([]);
  const [ingredientGroupData, setIngredientGroupData] = useState<
    {
      key: string;
      constraintName: { name: string | undefined; reason: any; ingredients?: any };
      user: string | React.JSX.Element;
      reduction: number | React.JSX.Element;
      delete: React.JSX.Element | { ig: any };
      creationQuery: string | undefined;
    }[]
  >([]);
  const [compositionData, setCompositionData] = useState<
    {
      key: string;
      constraintName: { name: string | undefined, reason: any; ingredients?: any };
      user: string;
      reduction: React.JSX.Element | number;
      delete: React.JSX.Element | { ig: any } | { ic: any };
    }[]
  >([]);
  const [constraintToDelete, setConstraintToDelete] = useState<ConstraintInputType | undefined>(undefined);

  const columns = [
    {
      dataIndex: 'constraintName',
      key: 'constraintName',
      render: (values: {
        ingredients: ReactNode | RenderFunction;
        constraintType: any;
        name: string;
        reason: string;
      }) => (<>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
            <Text>{values.name}</Text>

            {values.ingredients && values.reason !== 'Ingredient group' &&
              <Tooltip title={values.ingredients}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          </div>
          <Text type="secondary" style={{ fontSize: 12 }}>
            {values.reason ? values.reason[0].toUpperCase() + values.reason.substring(1).toLowerCase() : undefined}
          </Text>
          <Text type="secondary" style={{ fontSize: 12 }}>
            {values.constraintType ? values.constraintType : undefined}
          </Text>
        </div>
      </>

      ),
    },
    {
      dataIndex: 'delete',
      key: 'delete',
      render: (values: { c: ConstraintInputType, ig: string, cost: string, ic: string }) => (
        !values.cost &&
        <div style={{ textAlign: 'center', cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'flex-end' }}>
          {/* {values.c && <EditOutlined onClick={() => { setEditingConstraint(values.c), editConstraint(values.c) }} />} */}
          <div style={{ textAlign: 'center', color: '#FF4D4F', cursor: 'pointer' }}>
            <DeleteOutlined
              onClick={() => values.ig ?
                handleDeleteIngredientGroup(ingredientsGroup[values.ig][0].groupId) :
                values.ic ?
                  handleDeleteIngredientComposition(values.ic) :
                  handleDeleteConstraint(values.c)} />
          </div>
        </div>
      ),
    },
  ]

  const expandColumns = [
    {
      title: <div style={{ textAlign: 'center' }}>User</div>,
      dataIndex: 'user',
      key: 'user',
      render: (text: string) => (
        <div style={{ textAlign: 'center' }}>
          {text}
        </div>
      ),
    },
    // {
    //   title: <div style={{ textAlign: 'center' }}>Reduction</div>,
    //   dataIndex: 'reduction',
    //   key: 'reduction',
    //   render: (reduction: number) => (
    //     <div
    //       style={{
    //         textAlign: 'center',
    //         backgroundColor: '#FDEDEE',
    //         borderRadius: '4px',
    //         padding: '2px 8px',
    //         color: '#FF4D4F',
    //       }}
    //     >
    //       {reduction}%
    //     </div>
    //   ),
    // },
    {
      title: <div style={{ display: 'flex', justifyContent: 'center' }}>Description</div>,
      dataIndex: 'description',
      key: 'description',
      render: (values: string) => (
        <Text
          style={{ maxWidth: '150px', display: 'block' }}
          ellipsis={{ tooltip: values }}
        >
          {values}
        </Text>
      ),
    },

  ];

  useEffect(() => {
    let constraintList: React.SetStateAction<{
      key: string;
      constraintName: { name: string | undefined, reason: any, constraintType: string };
      user: string;
      reduction: React.JSX.Element | number;
      delete: { c: ConstraintInputType };
      description: string | undefined
    }[]> = [];
    let ingredientGroupList: React.SetStateAction<{
      key: string;
      constraintName: { name: string | undefined; reason: any; ingredients?: any };
      user: string;
      reduction: React.JSX.Element | number;
      delete: React.JSX.Element | { ig: any };
      creationQuery: string | undefined;
    }[]> = [];
    let compositionList: React.SetStateAction<{
      key: string;
      constraintName: { name: string | undefined, reason: any; ingredients?: any };
      user: string;
      reduction: React.JSX.Element | number;
      delete: React.JSX.Element | { ic: any };
    }[]> = [];

    constraints.forEach((c) => {
      constraintList.push({
        key: c.name!,
        constraintName: {
          name: c.name ?? undefined,
          reason: c.reason,
          constraintType: c.ingredientGroupId ? "Ingredient group constraint" : c.ingredientCompositionId ? "Ingredient composition constraint" : "Ingredient constraint"
        },
        user: c.createdBy ? `${c.createdBy.firstName} ${c.createdBy.lastName}` : 'Current User',
        reduction: 8,
        delete: { c: c },
        description: c.comment ?? undefined
      })
    });

    if (ingredientGroupIsSuccess)
      Object.keys(ingredientsGroup).forEach((ig) => {
        ingredientGroupList.push({
          key: `@${ingredientsGroup[ig][0].group.name}`,
          constraintName: {
            name: `${ingredientsGroup[ig][0].group.name}`,
            reason: 'Ingredient group',
            ingredients: ingredientsGroup[ig].map((ingredient: {
              percentage: number; ingredient: { name: any; };
            }) => `${ingredient.ingredient.name}: ${ingredient.percentage}`).join(', '),
          },
          reduction: 0,
          user: ingredientsGroup[ig][0].group.createdBy ? `${ingredientsGroup[ig][0].group.createdBy.firstName} ${ingredientsGroup[ig][0].group.createdBy.lastName}` : 'Current User',
          delete: { ig: ingredientsGroup[ig][0].groupId },
          creationQuery: ingredientsGroup[ig][0].group.creationQuery,
        })
      })

    if (ingredientsCompositionsIsSuccess)
      Object.keys(ingredientsCompositions).forEach((ig) => {
        if (!ingredientsCompositions[ig][0].archived)
          compositionList.push({
            key: `@${ingredientsCompositions[ig][0].name}`,
            constraintName: {
              name: `${ingredientsCompositions[ig][0].name}`,
              reason: 'Ingredient composition',
              ingredients: ingredientsCompositions[ig][0].ingredientList
                .filter((item: { value: number; ingredient: { name: any; }; }) => item.value > 0)
                .map((item: { value: number; ingredient: { name: any; }; }) => item.ingredient.name)
                .join(', '),
            },
            user: ingredientsCompositions[ig][0].createdBy ? `${ingredientsCompositions[ig][0].createdBy.firstName} ${ingredientsCompositions[ig][0].createdBy.lastName}` : 'Current User',
            reduction: 0,
            delete: { ic: ingredientsCompositions[ig][0].id }
          })
      })

    setConstraintsData(constraintList);
    setIngredientGroupData(ingredientGroupList);
    setCompositionData(compositionList);
  }, [constraints, ingredientsGroup, ingredientsCompositions, costOptimizationOption, nteCost, ingredientsCompositionsIsSuccess])

  const handleDeleteIngredientGroup = (gorup_id: string) => {
    deleteIngredientGroupMutation.mutate(
      {
        projectId: currentProject?.id ?? '',
        organizationId: user?.organizationId ?? '',
        group_id: gorup_id,
      },
      {
        onSuccess: response => {
          setIngredientGroupList(
            ingredientsGroupList.filter(e => e.id !== gorup_id)
          );
          refetchIngredientsGroups();
        },
      }
    );
  };

  const handleDeleteConstraint = (c: ConstraintInputType) => {
    setConstraintToDelete(c);
    showConfirm(c);
  }

  const handleDeleteIngredientComposition = async (id: string) => {
    try {
      await deleteComposition({
        variables: {
          compositionId: id,
          projectId: currentProject!.id,
        },
      });
      refetchIngredientsCompositions();
    } catch (e: any) {
      notification.error({
        description: 'There was an error removing your composition.',
        message: 'Error Updating Ingredient Composition',
      });
    }
  };

  const showConfirm = (c: ConstraintInputType) => {
    if (c)
      confirm({
        title: `Do you want to delete ${c.name}?`,
        icon: <ExclamationCircleFilled />,
        okButtonProps: { style: { backgroundColor: "#ef4136", borderColor: "#ef4136" } },
        cancelButtonProps: { style: { color: "#ef4136", borderColor: "#ef4136" } },
        onOk() {
          removeConstraint(c);
          setConstraintToDelete(undefined);
        },
        onCancel() {
          setConstraintToDelete(undefined);
        },
      });
  };

  const getExpandableConfig = (dataSource: any[]) => ({
    expandedRowRender: (record: { key: any }) => (
      <div style={{ paddingLeft: 0, margin: 0 }}>
        <Table
          dataSource={dataSource.filter((c) => c.key === record.key)}
          columns={expandColumns}
          pagination={false}
          className="expanded-table"
          rowClassName={() => 'custom-expanded-row'}
        />
      </div>
    ),
    rowExpandable: (record: { key: any; constraintName?: { reason?: ConstraintReason } }) =>
      !!record.constraintName?.reason && Object.values(ConstraintReason).includes(record.constraintName.reason),
  });


  const tabItems = [
    {
      key: 'constraints',
      label: 'Constraints',
      children: (
        <Table
          dataSource={constraintsData}
          columns={columns}
          pagination={false}
          showHeader={false}
          style={{ border: 'none', boxShadow: 'none', paddingLeft: 0, marginLeft: 0 }}
          rowClassName={() => 'custom-parent-row'}
          expandable={getExpandableConfig(constraintsData)}
        />
      ),
    },
    {
      key: 'ingredientGroups',
      label: 'Ingredient Groups',
      children: (
        <Table
          dataSource={ingredientGroupData}
          columns={columns}
          pagination={false}
          showHeader={false}
          style={{ border: 'none', boxShadow: 'none', paddingLeft: 0, marginLeft: 0 }}
          rowClassName={() => 'custom-parent-row'}
          expandable={{
            expandedRowRender: (record) => (
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paragraph keyboard style={{ margin: 15 }}>{record.creationQuery}</Paragraph>
              </div>
            ),
            rowExpandable: (record) => !!record.creationQuery, // Solo si tiene contenido
          }}
        />
      ),
    },
    {
      key: 'composition',
      label: 'Compositions',
      children: (
        <Table
          dataSource={compositionData}
          columns={columns}
          pagination={false}
          showHeader={false}
          style={{ border: 'none', boxShadow: 'none', paddingLeft: 0, marginLeft: 0 }}
          rowClassName={() => 'custom-parent-row'}
          expandable={getExpandableConfig(compositionData)}
        />
      ),
    },
  ];

  return <>
    <div className="custom-constraints-table">
      <Tabs items={tabItems} />
    </div>
  </>;
}
import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useDragDropContext } from '../../../../_shared/context/drag-context';

type DraggableItemProps = {
  item: any;
  index: number;
  lastItemRef: (node: HTMLDivElement | null) => void;
  listItems: any;
  activeId: string | null;
};

const DraggableItem = ({
  item,
  index,
  lastItemRef,
  listItems,
  activeId,
}: DraggableItemProps) => {
  // const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
  //   id: item.id,
  //   data: {
  //     id: item.id,
  //     name: item.name,
  //     type: item.type
  //   },
  // });

  const isActive = activeId === item.id;

  // const style = transform
  //   ? {
  //     transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  //   }
  //   : undefined;
  return (
    <div
      ref={node => {
        // setNodeRef(node);
        // Attach the last item observer to the last item
        if (index === listItems.length - 1) {
          lastItemRef(node);
        }
      }}
      // {...listeners}
      // {...attributes}
      style={{
        // ...style,
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        cursor: 'grab',
        border: isActive ? '2px solid blue' : '1px solid #ccc',
        backgroundColor: isActive ? '#e0f7ff' : '#fff',
        // opacity: isDragging ? 0.5 : 1,
        marginBottom: '5px',
      }}
    >
      <span>{item.name}</span>{' '}
      {item.category && <span className="tag">{item.category}</span>}
    </div>
  );
};

export default DraggableItem;

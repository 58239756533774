// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o5XH5o58L6PwRiA8jJQvGg\\=\\= {\n  margin: 5px;\n  flex: 1;\n}\n.o5XH5o58L6PwRiA8jJQvGg\\=\\=:not(:disabled),\n.o5XH5o58L6PwRiA8jJQvGg\\=\\=:hover {\n  background-color: #ef4136 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/client/pages/datasets/dataset-upload/dataset-upload.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,OAAA;AACF;AACE;;EAEE,oCAAA;AACJ","sourcesContent":[".button {\n  margin: 5px;\n  flex: 1;\n\n  &:not(:disabled),\n  &:hover {\n    background-color: #ef4136 !important;\n  }\n}\n@primary-color: #006EFF;@font-family: Inter;@border-radius-base: 4px;@modal-header-bg: #F6F5FA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "o5XH5o58L6PwRiA8jJQvGg=="
};
export default ___CSS_LOADER_EXPORT___;

import { BaseClient } from '../ApiClient';

const BASE_URL = (org_id: string, proj_id: string) =>
  `org/${org_id}/project/${proj_id}/ingredient-group`;

const ENDPOINTS = {
  getIngredientsGroups: (org_id: string, proj_id: string) =>
    BASE_URL(org_id, proj_id),
  postIngredientGroup: (org_id: string, proj_id: string) =>
    BASE_URL(org_id, proj_id),
  deleteIngredientGroup: (org_id: string, proj_id: string, group_id: string) =>
    `${BASE_URL(org_id, proj_id)}/${group_id}`,
  getIngredientGroupByOrganization: (org_id: string) =>
    `org/${org_id}/ingredient-group`,
};

const getIngredientsGroups = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getIngredientsGroups(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const postIngredientGroup = (data: any) => {
  return BaseClient.post(
    ENDPOINTS.postIngredientGroup(data.organizationId, data.projectId),
    data.ingredientGroup
  );
};

const deleteIngredientGroup = (data: any) => {
  return BaseClient.delete(
    ENDPOINTS.deleteIngredientGroup(
      data.organizationId,
      data.projectId,
      data.group_id
    )
  );
};
const getIngredientGroupByOrganization = ({ queryKey }: any) => {
  const [, organizationId, page, limit, searchTerm] = queryKey;
  return BaseClient.get(
    ENDPOINTS.getIngredientGroupByOrganization(organizationId),
    {
      params: { page, limit, searchTerm: searchTerm || '' },
    }
  );
};

export const IngredientGroupRepository = {
  getIngredientsGroups,
  postIngredientGroup,
  deleteIngredientGroup,
  getIngredientGroupByOrganization,
};

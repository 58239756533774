import React, { useEffect, useState } from 'react';
import { IRawDataAggregated } from '../RawDataAggregated.interfaces';
import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputNumber,
  notification,
  Popover,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import {
  DoubleRightOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PauseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { GreenCheck, RedCancel } from '../../../../../_shared/style';
import _, { min, max } from 'lodash';
import { ProjectType, RawDataVariableTypeOfVariable } from '@prisma/client';
import { useSession } from '../../.../../../../../_shared/context';
import { useLocation } from 'react-router-dom';
import './project-raw-data-aggregated.less';
const { Option } = Select;

interface RawDataAggregatedRowProps {
  data: IRawDataAggregated;
  addMode?: boolean;
  editDataListener: (edited: IRawDataAggregated) => void;
  deleteDataListener: (_id: string, variable: string) => void;
  projectHasData?: boolean;
  edit?: boolean;
  referenceFormulation?: any;
  saveAllInputsAndOutcomesOnEdit: boolean;
  setSaveAllInputsAndOutcomesOnEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProjectRawDataAggregatedRow: React.FC<RawDataAggregatedRowProps> = ({
  data,
  addMode,
  editDataListener,
  deleteDataListener,
  projectHasData,
  edit,
  referenceFormulation,
  saveAllInputsAndOutcomesOnEdit,
  setSaveAllInputsAndOutcomesOnEdit
}) => {
  const [inspectMode, setInspectMode] = useState(edit === false);
  const [editMode, setEditMode] = useState(false);
  const [newData, setNewData] = useState<IRawDataAggregated>({ ...data });
  const [errors, setErrors] = useState<
    { [key in keyof IRawDataAggregated]?: string }
  >({});

  // useEffect(() => {
  //   console.log("data", data)
  //   console.log("addMode", addMode)
  //   console.log("projectHasData", projectHasData)
  //   console.log("edit", edit)
  // }, [])
  const [initialLowerBound] = useState(data.lowerBound);
  const [initialUpperBound] = useState(data.upperBound);

  const { user, currentProject } = useSession();

  const urlPath = useLocation();

  useEffect(() => {
    if (saveAllInputsAndOutcomesOnEdit) {
      handleEdit();
      setSaveAllInputsAndOutcomesOnEdit(false);
    }
  }, [saveAllInputsAndOutcomesOnEdit])

  const handleEditMode = () => {
    if (!addMode && !editMode && !inspectMode) {
      setEditMode(true);
    }
  };

  const handleAddNew = () => {
    if (!validateRow()) return;

    editDataListener(newData);
    setEditMode(false);
  };

  const handleEdit = () => {
    if (!validateRow()) return;

    editDataListener(newData);
    setEditMode(false);
  };

  const handleDelete = () => {
    deleteDataListener(data.id, data.variable);
    setEditMode(false);
  };

  const onInputListener = (prop: keyof IRawDataAggregated) => (
    value: string | number | boolean
  ) => {
    if (prop === 'valuesDetected' && typeof value === 'string') {
      const values = value.split(',').map(v => Number(v));

      if (!Number.isNaN(values[0])) {
        const minValue = min(values);
        const maxValue = max(values);

        setNewData((newData: IRawDataAggregated) => ({
          ...newData,
          [prop]: value,
          lowerBound: minValue !== undefined ? minValue : newData.min,
          upperBound: maxValue !== undefined ? maxValue : newData.max,
        }));

        return;
      }
    }

    setNewData((newData: IRawDataAggregated) => ({
      ...newData,
      [prop]: value,
    }));
  };

  const validateRow = () => {
    const messages: string[] = [];
    const newErrors: { [key in keyof IRawDataAggregated]?: string } = {};
    const propertiesToCheck: (keyof IRawDataAggregated)[] = ['variable'];

    if (newData.typeOfVariable === '') {
      messages.push('Type of variable is required');
      newErrors.typeOfVariable = 'Required field';
    }

    if (
      newData.typeOfVariable &&
      newData.variable &&
      !/^[a-z0-9-_ ()%/&]+$/i.test(newData.variable)
    ) {
      messages.push(
        `Invalid characters found in ${newData.variable}. Please use names with only allowed characters ( a-z, 0-9, -, _ )`
      );
    }

    if (newData.dataType == 'NUMERIC') {
      Object.entries(newData).forEach(([k, v]) => {
        console.log(propertiesToCheck.includes(k as keyof IRawDataAggregated));
        if (!v && propertiesToCheck.includes(k as keyof IRawDataAggregated)) {
          messages.push(`${k} is missing`);
        }
      });

      if (newData.lowerBound > newData.upperBound) {
        messages.push('lower bound is higher than upper bound');
        newErrors.lowerBound = 'Invalid bounds';
        newErrors.upperBound = 'Invalid bounds';
      } else if (newData.lowerBound === newData.upperBound) {
        messages.push('Lower bound and upper bound must be different values');
        newErrors.lowerBound = 'Invalid bounds';
        newErrors.upperBound = 'Invalid bounds';
      } else if (newData.min > newData.max && projectHasData) {
        messages.push('min value observed is higher than max value observed');
        newErrors.min = 'Invalid min';
        newErrors.max = 'Invalid max';
      } else if (newData.lowerBound > newData.min && projectHasData) {
        messages.push(
          'Min Value out of bounds: it must be lower than min observed value'
        );
        newErrors.lowerBound = 'Invalid min';
      } else if (newData.upperBound < newData.max && projectHasData) {
        messages.push(
          'Max Value out of bounds: it must be greater than max observed value'
        );
        newErrors.upperBound = 'Invalid max';
      }
    } else {
      if (newData.dataType == 'ORDINAL') {
        if (newData.valuesDetected) {
          const arrValues = newData.valuesDetected.split(',');
          console.log(arrValues);
          arrValues[0] === arrValues[arrValues.length - 1] &&
            messages.push(
              'Lower and Upper Limit cannot be the same for ORDINAL variables'
            );
        }
      }

      if (!newData.valuesDetected) {
        messages.push('Values expected');
      } else if (newData.values) {
        // Values detected
        let valuesDetectedArray = newData.valuesDetected.split(',');
        for (let v in valuesDetectedArray) {
          v = v.trim();
        }
        // Values observed
        let values = newData.values.split(',');
        for (let value in values) {
          if (!(value.trim() in valuesDetectedArray)) {
            messages.push('All observed values must be in values');
            newErrors.values = 'Mismatch in values';
          }
        }
      }
    }

    if (referenceFormulation) {
      if (!validateRangesForAdjustedIngredient()) {
        const varValueInReference = referenceFormulation?.formulationItemsRaw.find(
          (f: any) => f.name === newData.variable
        );
        messages.push(
          `The value ${newData.adjustedRange} for ${newData.variable} (Reference value: ${varValueInReference.value}) exceeds the allowed tolerance range of ${newData.lowerBound} to ${newData.upperBound}.`
        );
        newErrors.values = 'Mismatch in tolerance';
      }
    }

    setErrors(newErrors);

    if (messages.length > 0) {
      notification.error({
        message: 'Error on editing row',
        description: (
          <>
            {messages.map(m => (
              <>
                {m}
                <br />
              </>
            ))}
          </>
        ),
      });
      return false;
    } else {
      return true;
    }
  };

  const [selectedRem, setSelectedRem] = useState<boolean>(false);

  const validateRangesForAdjustedIngredient = () => {
    if (
      newData.typeOfVariable.includes('OUTCOME') ||
      !newData.adjustedIngredient
    ) {
      return true;
    }

    if (!newData.adjustedIngredient) {
      return true;
    }

    const lowerEpsilon = newData.adjustedRange ?? 0;
    const upperEpsilon = newData.adjustedPlusRange ?? 0;

    const varValueInReference = referenceFormulation?.formulationItemsRaw.find(
      (f: any) => f.name === newData.variable
    );

    if (varValueInReference?.value - lowerEpsilon < 0) {
      return false;
    }

    return !(
      newData.upperBound < varValueInReference?.value + upperEpsilon &&
      newData.lowerBound > varValueInReference?.value - lowerEpsilon
    );
  };

  const setToleranceRangeForIngredient = (
    epsilon: number,
    epsilonType: 'UPPER' | 'BOTTOM'
  ) => {
    if (!newData.adjustedIngredient && epsilon == 0) {
      return;
    }

    const valueReference =
      referenceFormulation?.formulationItemsRaw.find(
        (f: any) => f.name === newData.variable
      )?.value ?? 0;

    if (newData.typeOfVariable.includes('INGREDIENT')) {
      if (!valueReference) {
        onInputListener('upperBound')(epsilon);
        return;
      }

      if (epsilonType === 'UPPER') {
        const maximunEpsilonPossible = 100 - valueReference;

        if (initialUpperBound + epsilon > 100) {
          onInputListener('upperBound')(100);
          onInputListener('adjustedMinusRange')(maximunEpsilonPossible - 1);
        } else {
          onInputListener('upperBound')(initialUpperBound + epsilon);
        }
      } else {
        const minimunEpsilonPossible = valueReference ?? 0;

        if (initialLowerBound - epsilon < 0) {
          onInputListener('lowerBound')(0);
          onInputListener('adjustedMinusRange')(minimunEpsilonPossible);
        } else {
          onInputListener('lowerBound')(initialLowerBound - epsilon);
        }
      }
    }

    if (newData.typeOfVariable.includes('PROCESSING')) {
      if (!valueReference) {
        onInputListener('upperBound')(epsilon);
        return;
      }

      if (epsilonType === 'UPPER') {
        onInputListener('upperBound')(initialUpperBound + epsilon);
      } else {
        onInputListener('lowerBound')(initialLowerBound - epsilon);
      }
    }
  };

  return (
    <div
      onClick={handleEditMode}
      className={`row-table ${addMode || editMode ? 'addingRow' : ''}`}
    >
      {currentProject?.originalProjectId &&
        !urlPath.pathname.includes('/inspect/') && (
          <div
            className="cost"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              // value={newData.typeOfVariable.includes("OUTCOME") ? false : newData.isSelectedForRemove}
              checked={
                newData.typeOfVariable.includes('OUTCOME')
                  ? false
                  : newData.isSelectedForRemove || selectedRem
              }
              disabled={newData.typeOfVariable.includes('OUTCOME')}
              onChange={e => {
                // setNewData((newData: IRawDataAggregated) => ({
                //   ...newData,
                //   ['isSelectedForRemove']: true,
                // }));
                setSelectedRem(!selectedRem);
                console.log(e.target.checked);
                if (!validateRow()) return;

                editDataListener({
                  ...newData,
                  isSelectedForRemove: true,
                });
                setEditMode(false);
              }}
            ></Checkbox>
          </div>
        )}
      <div
        className="category"
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
          backgroundColor: !(addMode || editMode) ? 'white' : 'f7f7f7',
          height: '100%',
        }}
      >
        {editMode || addMode || (!projectHasData && editMode) ? (
          <>
            <Input
              placeholder="Water"
              value={newData.variable}
              onChange={e => onInputListener('variable')(e.target.value)}
              className={errors.variable ? 'input-error' : ''}
            />
          </>
        ) : (
          <>{newData.variable}</>
        )}
      </div>
      {currentProject?.ProjectType ===
        ProjectType.PRESERVE_LABEL_LOWER_COST && (
          <>
            <div className="cost">
              {referenceFormulation?.formulationItemsRaw.find(
                (f: any) => f.name === newData.variable
              )?.value ?? 0}
            </div>

            <div className="name">
              {editMode || addMode || (!projectHasData && editMode) ? (
                <>
                  {currentProject?.ProjectType ===
                    ProjectType.PRESERVE_LABEL_LOWER_COST &&
                    newData.typeOfVariable && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {newData.typeOfVariable?.includes('OUTCOME') ? (
                          <>
                            {newData.goalPriority === 'MAXIMIZE' ? (
                              <div
                                onClick={() =>
                                  onInputListener('goalPriority')('MINIMIZE')
                                }
                                className="min-max-box"
                              >
                                <DoubleRightOutlined
                                  style={{
                                    transform: 'rotate(-90deg)',
                                    marginRight: 5,
                                    color: 'red',
                                  }}
                                />
                                MAXIMIZE
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  onInputListener('goalPriority')('MAXIMIZE')
                                }
                                className="min-max-box"
                              >
                                <DoubleRightOutlined
                                  style={{
                                    transform: 'rotate(90deg)',
                                    marginRight: 5,
                                    color: 'red',
                                  }}
                                />
                                MINIMIZE
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Switch
                              onChange={e => {
                                onInputListener('adjustedIngredient')(e);
                              }}
                              checked={newData.adjustedIngredient}
                              style={{
                                marginRight: '0.5rem',
                              }}
                            />
                            {newData.adjustedIngredient && (
                              <>
                                <InputNumber
                                  prefix={
                                    <div
                                      style={{
                                        fontSize: '8px',
                                      }}
                                    >
                                      <MinusOutlined />
                                    </div>
                                  }
                                  value={newData.adjustedMinusRange}
                                  onChange={e => {
                                    onInputListener('adjustedMinusRange')(e ?? 0);
                                    setToleranceRangeForIngredient(
                                      e ?? 0,
                                      'BOTTOM'
                                    );
                                  }}
                                  min={0}
                                  style={{
                                    marginLeft: '0.5rem',
                                  }}
                                  defaultValue={0}
                                />
                                -
                                <InputNumber
                                  prefix={
                                    <div
                                      style={{
                                        fontSize: '8px',
                                      }}
                                    >
                                      <PlusOutlined />
                                    </div>
                                  }
                                  value={newData.adjustedPlusRange}
                                  onChange={e => {
                                    onInputListener('adjustedPlusRange')(e ?? 0);
                                    setToleranceRangeForIngredient(
                                      e ?? 0,
                                      'UPPER'
                                    );
                                  }}
                                  min={0}
                                  style={{
                                    marginLeft: '0.5rem',
                                  }}
                                  defaultValue={0}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                </>
              ) : (
                <>
                  {currentProject?.ProjectType ===
                    ProjectType.PRESERVE_LABEL_LOWER_COST &&
                    newData.typeOfVariable && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          {newData.typeOfVariable?.includes('OUTCOME') ? (
                            <>
                              {newData.goalPriority === 'MAXIMIZE' ? (
                                <div>
                                  <DoubleRightOutlined
                                    style={{
                                      transform: 'rotate(-90deg)',
                                      marginRight: 5,
                                      color: 'red',
                                    }}
                                  />
                                  MAXIMIZE
                                </div>
                              ) : (
                                <div>
                                  <DoubleRightOutlined
                                    style={{
                                      transform: 'rotate(90deg)',
                                      marginRight: 5,
                                      color: 'red',
                                    }}
                                  />
                                  MINIMIZE
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Switch
                                onChange={e => {
                                  onInputListener('adjustedIngredient')(e);
                                }}
                                disabled={true}
                                checked={newData.adjustedIngredient}
                                style={{
                                  marginRight: '0.5rem',
                                }}
                              />
                              {!newData.adjustedIngredient && (
                                <>No Flexibility Range - Value Fixed</>
                              )}
                              {newData.adjustedIngredient && (
                                <>
                                  <InputNumber
                                    prefix={
                                      <div
                                        style={{
                                          fontSize: '8px',
                                        }}
                                      >
                                        <MinusOutlined />
                                      </div>
                                    }
                                    value={newData.adjustedMinusRange}
                                    onChange={e =>
                                      onInputListener('adjustedMinusRange')(
                                        e ?? 0
                                      )
                                    }
                                    min={0}
                                    defaultValue={0}
                                    disabled={true}
                                  />
                                  -
                                  <>
                                    <InputNumber
                                      prefix={
                                        <div
                                          style={{
                                            fontSize: '8px',
                                          }}
                                        >
                                          <PlusOutlined />
                                        </div>
                                      }
                                      value={newData.adjustedPlusRange}
                                      onChange={e =>
                                        onInputListener('adjustedPlusRange')(
                                          e ?? 0
                                        )
                                      }
                                      min={0}
                                      defaultValue={0}
                                      disabled={true}
                                    />
                                  </>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </>
        )}

      <div className="name">
        <Select
          value={newData.typeOfVariable ?? 'SELECT'}
          disabled={
            (!editMode && !addMode) || (projectHasData && newData.addedManually)
          }
          className="select-input-type"
          options={Object.keys(RawDataVariableTypeOfVariable)
            .filter(type => {
              if (projectHasData && newData.addedManually)
                return (
                  type === RawDataVariableTypeOfVariable.EXISTING_INGREDIENT
                );
              else return type !== RawDataVariableTypeOfVariable.NEW_INGREDIENT;
            })
            .map(type => ({
              value: type,
              label: type.replace('_', ' '),
              disable: false,
            }))}
          onSelect={e => {
            if (e.includes('OUTCOME')) {
              newData.priorityOutcome = 1;
            } else {
              newData.priorityOutcome = 0;
              if (
                currentProject?.ProjectType ===
                ProjectType.PRESERVE_LABEL_LOWER_COST &&
                newData.adjustedIngredient &&
                e.includes('INGREDIENT')
              ) {
                // Added for refit bounds + flexibility range if user goes from processing to
                onInputListener('lowerBound')(0);
                onInputListener('upperBound')(100);
                onInputListener('adjustedMinusRange')(0);
                onInputListener('adjustedPlusRange')(0);
              }
            }
            onInputListener('typeOfVariable')(e);
          }}
        />
      </div>

      <div className="test-condition">
        <Select
          value={newData.dataType ?? 'NUMERIC'}
          disabled={
            (!editMode &&
              !addMode &&
              newData.typeOfVariable !== 'PROCESSING') ||
            (projectHasData && newData.addedManually)
          }
          options={[
            { value: 'NUMERIC', label: 'Continuous' },
            { value: 'CATEGORICAL', label: 'Nominal' },
            { value: 'ORDINAL', label: 'Ordinal' },
          ]}
          onSelect={e => onInputListener('dataType')(e)}
        />
      </div>

      <div className="test-condition">
        {newData.dataType === 'NUMERIC' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {
              !newData.min && !newData.max ? (
                <span>
                  Missing
                </span>
              ) : (
                <>
                  <InputNumber
                    placeholder="min"
                    value={newData.min}
                    disabled={true}
                    onChange={e => onInputListener('min')(e ?? 0)}
                    suffix={
                      newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
                    }
                  />
                  -
                  <InputNumber
                    placeholder="max"
                    value={newData.max}
                    disabled={true}
                    onChange={e => onInputListener('max')(e ?? 0)}
                    suffix={
                      newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
                    }
                  />
                </>
              )
            }

          </div>
        ) : (
          <Input
            value={newData.values}
            disabled={true}
            onChange={e => onInputListener('values')(e.target.value)}
          />
        )}
      </div>

      {/* <div className="cost">
        {addMode || editMode ? (
          <InputNumber
            placeholder="1"
            value={newData.max}
            onChange={e => onInputListener('max')(e ?? 0)}
          />
        ) : (
          newData.max
        )}
      </div> */}

      <div className="unit">
        {addMode || editMode ? (
          <Input
            placeholder="%/grm"
            value={newData.unit}
            onChange={e => onInputListener('unit')(e.target.value)}
          />
        ) : (
          newData.unit
        )}
      </div>

      <div className="test-condition">
        {addMode || editMode ? (
          <Input
            placeholder="Liquid"
            value={newData.category}
            onChange={e => onInputListener('category')(e.target.value)}
          />
        ) : (
          newData.category
        )}
      </div>

      <div className="test-condition">
        {/* {newData.typeOfVariable &&
          newData.typeOfVariable.includes('INGREDIENT') && ( */}
        <Flex justify="space-between" align="center" style={{ width: '95%' }}>
          {addMode || editMode ? (
            <Input
              placeholder="Powdered emulsifier for improving texture and stability in sauces."
              value={newData.descriptiveFunctionalRole}
              onChange={e =>
                onInputListener('descriptiveFunctionalRole')(e.target.value)
              }
            />
          ) : (
            <div style={{ width: '90%' }}>
              {newData.descriptiveFunctionalRole}
            </div>
          )}
          <Popover
            trigger="hover"
            title="Descriptive Functional Role Examples"
            content={
              <Flex vertical>
                <Typography.Text>
                  <strong>Good Descriptions:</strong>
                </Typography.Text>
                <Typography.Text>
                  <strong>
                    - Powdered emulsifier for improving texture and stability in
                    sauces.
                  </strong>
                </Typography.Text>
                <Typography.Text>
                  <strong>
                    - Liquid preservative to extend shelf life and maintain
                    freshness.
                  </strong>
                </Typography.Text>
                <Typography.Text>
                  <strong>
                    - Plant-based thickener to enhance mouthfeel and viscosity.
                  </strong>
                </Typography.Text>
                <Typography.Text>
                  <strong>
                    - Blend of natural extracts for flavor enhancement and aroma
                    in beverages.
                  </strong>
                </Typography.Text>
                <Typography.Text style={{ marginTop: 12 }}>
                  <strong>Bad Descriptions:</strong>
                </Typography.Text>
                <Typography.Text>
                  <strong>- "Preservative to make product last longer"</strong>{' '}
                  (Too vague, missing physical properties and function details)
                </Typography.Text>
                <Typography.Text>
                  <strong>- "Thickening agent"</strong> (No information on how
                  it affects the product attributes or concentration)
                </Typography.Text>
                <Typography.Text>
                  <strong>- "Spice blend"</strong> (Doesn't include primary
                  function or chemical properties)
                </Typography.Text>
              </Flex>
            }
          >
            {' '}
            <InfoCircleOutlined style={{ marginLeft: 8 }} />
          </Popover>
        </Flex>
        {/* )}  */}
      </div>
      <div className="cost">
        {addMode || editMode ? (
          <InputNumber
            placeholder="0"
            value={newData.cost}
            onChange={e => onInputListener('cost')(e ?? 0)}
          />
        ) : (
          newData.cost
        )}
      </div>

      <div className="cost">
        {addMode || editMode ? (
          <Input
            placeholder="$/Kg"
            value={newData.costUnit}
            onChange={e => onInputListener('costUnit')(e.target.value)}
          />
        ) : (
          newData.costUnit
        )}
      </div>

      {/* <div className="cost">
  {
    addMode || editMode ? (
      <InputNumber
        placeholder="lower bound"
        value={newData.lowerBound}
        onChange={e => onInputListener('lowerBound')(e ?? 0)}
      />
    ) : (
    newData.lowerBound
  )
  }
      </div > */}

      <div className="test-condition">
        {newData.dataType === 'NUMERIC' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <InputNumber
              placeholder="lower bound"
              value={newData.lowerBound}
              disabled={
                (!addMode && !editMode) ||
                (projectHasData && newData.addedManually)
              }
              onChange={e => onInputListener('lowerBound')(e ?? 0)}
              className={errors.lowerBound ? 'input-error' : ''}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
            -
            <InputNumber
              placeholder="upper bound"
              value={newData.upperBound}
              disabled={
                (!addMode && !editMode) ||
                (projectHasData && newData.addedManually)
              }
              onChange={e => onInputListener('upperBound')(e ?? 0)}
              className={errors.upperBound ? 'input-error' : ''}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
          </div>
        ) : (
          <Input
            value={newData.valuesDetected}
            onChange={e => onInputListener('valuesDetected')(e.target.value)}
            className={errors.values ? 'input-error' : ''}
          />
        )}
      </div>

      <div className="test-condition">
        {newData.priorityOutcome ? (
          <>
            <Select
              value={newData.priorityOutcome}
              disabled={!(addMode || editMode)}
              onSelect={e => onInputListener('priorityOutcome')(e)}
            >
              <Option value={3}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(-90deg)',
                    marginRight: 5,
                    color: 'red',
                  }}
                />
                High
              </Option>
              <Option value={2}>
                <PauseOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'green',
                  }}
                />
                Medium
              </Option>
              <Option value={1}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'blue',
                  }}
                />
                Low
              </Option>
              <Option value={0}>
                <MinusOutlined style={{ marginRight: 5, color: 'grey' }} />
                None
              </Option>
            </Select>
          </>
        ) : (
          <>NA</>
        )}
        {addMode && (
          <Button onClick={handleAddNew} className="add-ingredient">
            + Add
          </Button>
        )}
        {editMode && (
          <div className="action-buttons-container">
            <div className="action-button" onClick={handleEdit}>
              <GreenCheck />
            </div>
            {
              //!projectHasData && (
              <div className="action-button" onClick={() => handleDelete()}>
                <RedCancel />
              </div>
              //)
            }
          </div>
        )}
      </div>
    </div>
  );
};

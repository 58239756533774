import { FormulationType } from '../../../_shared/context/formulations-context';
import { Brain, Expand, Trophy } from '../../../_shared/style';
import {
  IterationTaskTypeEnum,
  IterationTask,
  Formulation,
  Simulation,
} from '../../../../../__generated__/globalTypes';
import { Avatar, Button, Flex, Typography } from 'antd';
import React from 'react';
import { ExplorationResultTable } from '../exploration-scenario-results-table/exploration-results-table';
import { SimulationResultDisplay } from '../simulation-result';
import { ExperimentSuggestionMessage } from './experiment-suggestion-message';
import {
  IterationTaskType,
  useExploration,
} from '../../../_shared/context/exploration-context';
import './exploration-task-message-style.less';
import { useIngredients } from '../../../_shared/hooks';
import { ExperimentConstraintsMinCost } from '../exploration-task-min-cost/experiment-constraints-min-cost';
const { Paragraph } = Typography;
export const TaskMessage = ({
  task,
  numberOfResults,
  handleChangeFormulationToView,
  formulations,
  minCost
}: {
  task: IterationTaskType;
  numberOfResults?: number;
  handleChangeFormulationToView: (formulationToView: FormulationType) => void;
  formulations?: FormulationType[];
  minCost?: any[]
}) => {
  const { expandFormulations } = useExploration();
  const { ingredientByName } = useIngredients();
  const SimulationResultsMessage = (
    <div
      style={{
        marginLeft: 10,
        marginBottom: 20,
        height: '80%',
        maxWidth: 500,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          background: '#f6f6f6',
          padding: 10,
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <Paragraph style={{ marginBottom: 0 }}>
          {task.result ||
            'Here are your simulation results. The grey line shows the bounds, while the red line highlights where values are likely to fall based on your data. Red dots indicate predicted values, and the vertical grey line shows the mean value.'}
        </Paragraph>
      </div>
      <SimulationResultDisplay
        simulation={task.simulation as Simulation | undefined}
      />
    </div>
  );

  const DesignResultsMessage = (
    <div
      style={{
        marginBottom: 20,
        width: '100%',
      }}
    >
      {/* <div
        style={{
          marginLeft: 10,
          marginBottom: 5,
          background: '#f6f6f6',
          padding: 10,
          borderRadius: 5,
        }}
      >
        <Paragraph style={{ marginBottom: 0 }}>
          {task.request ||
            task.result ||
            `Here are your ${numberOfResults} scenario results. Pin the formulations you want to keep for further testing.`}
        </Paragraph>
      </div> */}

      <div
        style={{
          marginLeft: 10,
          marginBottom: 5,
          background: 'transparent',
          padding: 0,
          borderRadius: 5,
        }}
      >
        {formulations && formulations.length > 0 && (
          <Button
            icon={<Expand />}
            className="expand"
            size="small"
            type="primary"
            onClick={() => expandFormulations(formulations)}
          >
            Compare
          </Button>
        )}
        <ExplorationResultTable
          handleChangeFormulationToView={handleChangeFormulationToView}
          formulations={formulations ?? []}
        />
      </div>
      {/* <ExplorationTimelineFormulationGenreated formulation={formulation} handleChangeFormulationToView={handleChangeFormulationToView} /> */}
    </div>
  );

  return (
    <div
      style={{
        alignSelf: 'flex-start',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
        }}
      >
        <Avatar
          style={{
            width: 33,
            marginBottom: 20,
            backgroundColor: '#f6f6f6',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          icon={<Brain stroke="#000" />}
        />
      </div>

      {task.type === IterationTaskTypeEnum.SIMULATION ? (
        <>{SimulationResultsMessage}</>
      ) : task.type === IterationTaskTypeEnum.DESIGN ? (
        task.aiSuggestionMessage ? (
          <ExperimentSuggestionMessage
            task={task}
            handleChangeFormulationToView={handleChangeFormulationToView}
            formulations={formulations}
          />
        ) : (
          <>{DesignResultsMessage}</>
        )
      ) : task.type === IterationTaskTypeEnum.CONGRATULATIONS ? (
        <div className="congratulations task-chat">
          <div
            style={{
              width: 28,
              height: 28,
            }}
          >
            <Trophy />
          </div>

          {task.result ?? (
            <Paragraph style={{ marginBottom: 0 }}>
              {'Example response'}
            </Paragraph>
          )}
        </div>
      ) : task.type === IterationTaskTypeEnum.MIN_COST ? (
        minCost && minCost.length > 0 && <ExperimentConstraintsMinCost minCost={minCost} />
      ) : (
        <div className="task-chat">
          {task.result ?? (
            <Paragraph style={{ marginBottom: 0 }}>
              {'Example response'}
            </Paragraph>
          )}
        </div>
      )}
    </div>
  );
};

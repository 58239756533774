import { useIngredients } from '../../../../_shared/hooks';
import { FormulationItemType } from '../../../../../../__generated__/globalTypes';
import { FormulationType } from '../../../../_shared/context/formulations-context';
import { CustomRecordType } from '../../experiment-list-layout-v2';
import { Card, Table, Tag, Typography } from 'antd';
import React from 'react';
import { calculateFormulationCostV2 } from '../../../../_shared/utils/util';
import { useSession } from '../../../../_shared/context';

const { Title, Text } = Typography;

export const IngredientsCard = ({
  formulation,
}: {
  formulation: FormulationType;
}) => {
  const { ingredientByName, ingredients } = useIngredients();
  const { currentProject } = useSession();

  const ingredientsToShow = formulation.items
    .filter(
      item =>
        item.type !== FormulationItemType.TARGET_MEASURED &&
        item.type !== FormulationItemType.TARGET_PREDICTED
    )
    .map((item, index) => {
      const ingredient = ingredientByName.get(item.variable.name);
      return {
        key: index,
        name: item.variable.name,
        percentage:
          !isNaN(Number(item.value)) && !ingredient?.unit
            ? `${item.value}%`
            : `${item.value} ${ingredient?.unit || ''}`,
      };
    });

  const ingredientColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  return (
    <>
      <Card title="Ingredients">
        <Table
          columns={ingredientColumns}
          dataSource={ingredientsToShow}
          pagination={false}
        />
        <div style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: "20px",
          padding: "10px",
          borderRadius: "10px",
          border: "solid 1px #d2d2d2",
          backgroundColor: "#f6f6f6"
        }}>
          <Text>Cost</Text>
          <Text>
            {Number(calculateFormulationCostV2(
              formulation.items.map((item) => ({ name: item.variable.name, value: Number(item.value) })),
              ingredients.filter(i => currentProject?.ingredientList.includes(i))
            )).toFixed(2)}
          </Text>
        </div>
      </Card>
    </>
  );
};

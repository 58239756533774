import { useMutation, useQuery } from 'react-query';
import { CreateDataSetOrgData } from '../interfaces/formulationDataSet.interfaces';
import { DatasetRepository } from '../repositories/dataset.repository';
import { IProjectCreateOut } from '../interfaces/project.interfaces';

export const useCreateDataset = () => {
  const mutation = useMutation((data: CreateDataSetOrgData) => {
    return DatasetRepository.postCreateDataset(data)
  });
  return mutation;
};

export const useCreateProjectFromDataset = () => {
  const mutation = useMutation((data: IProjectCreateOut) => {
    return DatasetRepository.postCreateProjectFromDataset(data)
  });
  return mutation;
};

export const useEditDataset = () => {
  const mutation = useMutation((data: CreateDataSetOrgData) => {
    return DatasetRepository.putEditDataset(data)
  });
  return mutation;
};

export const useGetDataset = (data: {
  org_id?: string;
  datasetId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getDataset`, data.org_id, data.datasetId],
    DatasetRepository.getDataset,
    {
      enabled: !!data.org_id,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};


export const useGetDatasetFormulations = (data: {
  org_id?: string;
  datasetId?: string;
  page: number;
  pageSize: number;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getDatasetFormulation`, data.org_id, data.datasetId, data.pageSize, data.page],
    DatasetRepository.getDatasetFormulations,
    {
      enabled: !!data.org_id && !!data.datasetId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getCreateProjectChatHistory: (org_id: string, project_id: string) =>
    `org/${org_id}/project/${project_id}/luna/create-project/history`,
  postSendMessage: (org_id: string, project_id: string) =>
    `org/${org_id}/project/${project_id}/luna/chat/message`,
  postSendCreateProjectMessage: (org_id: string) =>
    `org/${org_id}/luna/create-project/message`,
};

const getCreateProjectChatHistory = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getCreateProjectChatHistory(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const postSendMessage = (data: {
  organizationId: string;
  projectId: string;
  message: string;
  userId: string;
}) => {
  return BaseClient.post(
    ENDPOINTS.postSendMessage(data.organizationId, data.projectId),
    {
      message: data.message,
      user_id: data.userId,
    }
  );
};

const postSendCreateProjectMessage = (data: {
  organizationId: string;
  message: string;
  userId: string;
  requestId: string;
}) => {
  return BaseClient.post(
    ENDPOINTS.postSendCreateProjectMessage(data.organizationId),
    {
      message: data.message,
      user_id: data.userId,
      request_id: data.requestId,
    }
  );
};

export const LunaChatRepository = {
  postSendMessage,
  postSendCreateProjectMessage,
  getCreateProjectChatHistory,
};
